import React, { Component } from 'react';
import { Map, GoogleApiWrapper ,InfoWindow, Marker,Polyline} from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%'
};

export class DriverMap extends Component {
  state = {
    showingInfoWindow: true,  // Hides or shows the InfoWindow
    activeMarker: {},          // Shows the active marker upon click
    selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
  };
  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  onMarkerClick = (props, marker, e) =>
  this.setState({
    selectedPlace: props,
    activeMarker: marker,
    showingInfoWindow: true
  });
  createMap = (lng, lat ) => {
    return { lng, lat  };
    };

// onClose = props => {
//   if (this.state.showingInfoWindow) {
//     this.setState({
//       showingInfoWindow: false,
//       activeMarker: null
//     });
//   }
// };
  render() {
    const {drivers} = this.props
    // console.log(drivers,'driver')
        const triangleCoords = [
            drivers
              ? drivers.map((item) =>
                  this.createMap(
                    item.cordinates[0],
                    item.cordinates[1],
                  )
                )
              : null,
          ];
    return (
      <div >
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={
          {
            lat: 23.81839,
            lng:  90.421285
          }
        }
      
        onClick={this.onMapClicked}
      >
         { drivers
              ? drivers.map((item) =>{

                // const refactorData = new Date(item.date)
                // console.log(refactorData.toISOString())
                var suffix = item.date.split("T")[1] >= 12 ? "PM":"AM";
                var hours = ((item.date.split("T")[1] + 11) % 12 + 1) + suffix
               
                return (<Marker
                    // title={'Ahoy banglaesh'}
                    name={[item.battery]}
                    time={[item.date.split("T")[1]]}
                    date={[item.date.split("T")[0]]}
                    position={{lat: item.cordinates[1], lng: item.cordinates[0]}}
                    onClick ={this.onMarkerClick}
                    />)
                    }
                
                    
                     )   : null }
     
     <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}>
                    <div>
                    <h5>Charge - {this.state.selectedPlace.name}%</h5>
                    <h5>Time - {this.state.selectedPlace.time}</h5>
                    <h5>Date - {this.state.selectedPlace.date}</h5>
                    </div>
                    </InfoWindow>  
                <Polyline
                    path={triangleCoords[0]}
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={2} 
                 
                />
    </Map>
    </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDslUl2FlUug8PaPRs2woRYjCi5EwqshVo'
})(DriverMap);