import { TabletFilled, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, PageHeader, Row, Upload } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createMerchant, setMerchantDetails } from "../../../actions/merchant";
import {
  renderCheckbox,
  renderFile,
  renderInput,
  renderPasswordField,
  renderSelect,
  renderTextField,
} from "../../../components/form";
import SelectBox from "../../../components/select";
import history from "../../../history";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const CreateMerChant = ({ location, createMerchant, setMerchantDetails }) => {
  const [merchanttype, setMerchantType] = useState("");
  const [Id, setIdType] = useState("");
  const [docimgf, setDocImgFont] = useState('');
  const [docimgb, setDocImgBack] = useState('');
  const [form] = Form.useForm();

  const merchant = [
    { value: "Company", label: "Company" },
    { value: "Individual", label: "Individual" },
  ];
  const doc = [
    { value: "NID", label: "NID" },
    { value: "PASSPORT", label: "Passport" },
    { value: "BC", label: "Birth Certificate" },
    { value: "NONE", label: "None" },
  ];

  const onFinish = async (values) => {
    const code = await createMerchant(values,docimgf,docimgb);

    

    if (code === 201) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const Pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Create Merchant"
        subTitle="Merchant Management"
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<TabletFilled />}
            onClick={() => history.push("/merchant-list")}
          >
            Merchant List
          </Button>,
        ]}
        
      ></PageHeader>
      {window.scrollTo(0, 0)}
      <br />
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {renderInput("name", "Full Name", [
          { required: true, message: "name is required" },
        ])}
        {renderInput("email", "Email ", [
          { required: true, message: "Email is required" },
        ])}
        {renderTextField("address", "Address ", [
          { required: true, message: "Field is required" },
        ])}
        {renderInput("mobile_number", "Mobile Number", [
          { required: true, message: "Mobile Number is required" },
        ])}
        {renderPasswordField("password", "Password ", [
          { required: true, message: "Please input your password!" },
          { pattern: /^[a-zA-Z\d]{8}$/, message: "Please valid password!" },
        ])}
        {renderPasswordField("confirm_password", "Confirm Password", [
          { required: true, message: "Please input your password!" },
          { pattern: /^[a-zA-Z\d]{8}$/, message: "Please valid password!" },
        ])}
        {/* {renderInput("country_code", "Country Code ", [
          { required: true, message: "country code is required" },
        ])} */}
        <SelectBox
          name="merchant_type"
          label="Merchant Type"
          rules={[{ required: true, message: "merchant type is required" }]}
          options={merchant}
          placeholder="Select One"
          onChange={(e) => {
            setMerchantType(e);
          }}
        />

        {merchanttype === "Company" ? (
          <>
            {renderInput("company_name", "Company Name", [
              { required: true, message: "Company name is required" },
            ])}
          </>
        ) : null}
        <SelectBox
          name="doc_type"
          label="ID Type"
          rules={[{ required: true, message: "ID type is required" }]}
          options={doc}
          placeholder="Select One"
          onChange={(e) => {
            setIdType(e);
          }}
        />

        {/* {renderSelect(
          "doc_type",
          "ID Type",
          [{ required: true }],
          "Select One",
          doc,
          "none"
        )} */}
        {Id === "NID" || Id === "PASSPORT" || Id === "BC" ? (
          <>
            {renderInput("doc_no", "ID No  ", [
              { required: true, message: "country code is required" },
            ])}

            <Form.Item label="Upload card front photo">
              <input
                type="file"
                id="avatar"
                name="doc_image_front"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  setDocImgFont(e.target.files[0]);
                }}
              ></input>
            </Form.Item>

            <Form.Item label="Upload card back photo">
              <input
                type="file"
                id="avatar"
                name="doc_image_back"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  setDocImgBack(e.target.files[0]);
                }}
              ></input>
            </Form.Item>
          </>
        ) : null}
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );

  return <Content>{Pageheader}</Content>;
};

export default connect(null, { createMerchant, setMerchantDetails })(
  CreateMerChant
);
