import { Button, Form, Modal, Divider, Descriptions, Select, PageHeader } from "antd";
import { Content } from "antd/lib/layout/layout";


import { Noticecreate } from "../../actions/message";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  renderInput,
  renderSelect,
  renderDate,
  renderTextField,
  renderPasswordField,
} from "../../components/form";
import SelectBox from "../../components/select";
import history from "../../history";
import { TabletFilled } from "@ant-design/icons";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const authenticate = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];
const users = [
  { value: "merchant", label: "Merchant" },
  { value: "driver", label: "Driver" },
  { value: "warehouse", label: "Warehouse" },
  { value: "user", label: "User" },
];

const NoticeCreates = ({
    Noticecreate,
 
}) => {
  const [warehouses, setWarehouses] = useState([]);

  const [form] = Form.useForm();

  

  const onFinish = async (values) => {
    const code = await Noticecreate(values);

    if (code === 201) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const getareasv = (label, value) => {
    return { label, value };
  };
  const createOption = (val, label) => {
    return { val, label };
  };

  let WAREHOUSES = [];
  WAREHOUSES = warehouses
    ? warehouses?.map((item) => getareasv(item.name, item.warehouse_id))
    : null;

  // let option = [];
  // option = area ? area.map((item) => getareasv(item.name, item.id)) : null;

  const FORM = (
    <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      form={form}
      autoComplete={false}
      requiredMark={false}
    >
      <Divider orientation="center"> Notification Create </Divider>

      {renderInput("title", "Title", [
        { required: true, message: "This field is required!" },
        
      ])}
      
      {renderSelect(
        "user",
        "User",
        [{ required: true }],
        "Select One",
        users,
        "none"
      )}
      
      {renderTextField("message", "Message", [
        { required: true, message: "This field is required!" },
      ])}
     


      {renderSelect(
        "active",
        "Active",
        [{ required: true }],
        "Select One",
        authenticate,
        "none"
      )}

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Notice Create"
      subTitle="Support Management"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<TabletFilled />}
          onClick={() => history.push("/notice-list")}
        >
          Notice List
        </Button>,
      ]}
    ></PageHeader>
  );

  return <Content>{Pageheader}{FORM}</Content>;
};

export default connect(null, {

  Noticecreate,
})(NoticeCreates);
