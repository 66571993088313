
import {
  Button,
 
  Divider,
  Form,

  PageHeader,
  
  
} from "antd";

import { Content } from "antd/lib/layout/layout";

import React, { useState } from "react";
import { connect } from "react-redux";
import {} from "../../../actions/area";
import { interTransferBalanace } from "../../../actions/account";
import { createShop } from "../../../actions/shop";
import {
  
  renderInput,

} from "../../../components/form";
import SelectBox from "../../../components/select";

import DateBox from "../../../components/date";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const IntraTransfer = ({

  interTransferBalanace,

}) => {
 
  const [pt, setPaymentType] = useState("");
  const [ptTo, setPaymentToType] = useState("");
  
  const [docimg, setDocImg] = useState(false);

  const [PMT, setPaymentMobileType] = useState("");
  const [PTMT, setPaymentToMobileType] = useState("");
  

  const [form] = Form.useForm();
 

 
  const payment_method = [
    { value: "Mobile Banking", label: "Mobile Banking" },
    { value: "Bank Account", label: "Bank Account" },
    { value: "Cash", label: "Cash" },
  ];

  const payment_method2 = [
    { value: "Mobile Banking", label: "Mobile Banking" },
    { value: "Cash", label: "Cash" },
  ];
  const payment_method3 = [
    { value: "Mobile Banking", label: "Mobile Banking" },
    { value: "Bank Account", label: "Bank Account" },
  ];
  const mobile_type = [
    { value: "bKash", label: "bKash" },
    { value: "Rocket", label: "Rocket" },
    { value: "Nagad", label: "Nagad" },
  ];
  const mobile_type3 = [
    { value: "bKash", label: "bKash" },
    { value: "Rocket", label: "Rocket" },
  ];
  const mobile_type1 = [
    { value: "Rocket", label: "Rocket" },
    { value: "Nagad", label: "Nagad" },
  ];
  const mobile_type2 = [
    { value: "bKash", label: "bKash" },

    { value: "Nagad", label: "Nagad" },
  ];


  

  const onFinish = async (values) => {
    const code = await interTransferBalanace(values, docimg);

    if (code === 201) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const Pageheader = (
    <>
      {/* {window.scrollTo(0, 0)} */}
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Inter Transfer "
        subTitle="Finance Management"
        extra={[]}
      ></PageHeader>
      <br />
    </>
  );
  const FROM = (
    <>
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Divider style={{ color: "#3ab569" }}>Inter Transfer Balance</Divider>

        <DateBox name="date" label="Transfer Date" required={true}></DateBox>

        {renderInput("amount", "Amount to Transfer", [
          { required: true, message: "field is required" },
        ])}
        {renderInput("note", "Note", [
          { required: true, message: "Field is required" },
        ])}
        {/* =================From Which Account=========== */}
        <Divider>From Which Account</Divider>
        <SelectBox
          name="from_payment_method"
          label="Payment Method"
          rules={[{ required: true, message: "payment type is required" }]}
          options={payment_method}
          placeholder="Select One"
          onChange={(e) => {
            setPaymentType(e);
          }}
        />
        {pt === "Mobile Banking" ? (
          <>
            <SelectBox
              name="from_mobile_bank"
              label="Payment Method"
              rules={[{ required: true, message: "payment type is required" }]}
              options={mobile_type}
              placeholder="Select One"
              onChange={(e) => {
                setPaymentMobileType(e);
              }}
            />
          </>
        ) : null}

        <br></br>
        {/* =================To Which Account=========== */}
        <Divider>To Which Account</Divider>
        {pt === "Mobile Banking" ? (
          <SelectBox
            name="to_payment_method"
            label="Payment Method"
            rules={[{ required: true, message: "payment type is required" }]}
            options={payment_method}
            placeholder="Select One"
            onChange={(e) => {
              setPaymentToType(e);
            }}
          />
        ) : pt === "Bank Account" ? (
          <SelectBox
            name="to_payment_method"
            label="Payment Method"
            rules={[{ required: true, message: "payment type is required" }]}
            options={payment_method2}
            placeholder="Select One"
            onChange={(e) => {
              setPaymentToType(e);
            }}
          />
        ) : pt === "Cash" ? (
          <SelectBox
            name="to_payment_method"
            label="Payment Method"
            rules={[{ required: true, message: "payment type is required" }]}
            options={payment_method3}
            placeholder="Select One"
            onChange={(e) => {
              setPaymentToType(e);
            }}
          />
        ) : null}
       

        {ptTo === "Mobile Banking" ? (
          PMT === "" || PMT ===null?
          <SelectBox
          name="to_mobile_bank"
          label="Mobile Banking Name*"
          rules={[{ required: true, message: "Field type is required" }]}
          options={mobile_type}
          placeholder="Select One"
          onChange={(e) => {
            setPaymentToMobileType(e);
            
          }}
        />:
          PMT === "bKash" ? (
            <SelectBox
              name="to_mobile_bank"
              label="Mobile Banking Name*"
              rules={[{ required: true, message: "Field type is required" }]}
              options={mobile_type1}
              placeholder="Select One"
              onChange={(e) => {
                setPaymentToMobileType(e);
              }}
            />
          ) : PMT === "Rocket" ? (
            <SelectBox
              name="to_mobile_bank"
              label="Mobile Banking Name*"
              rules={[{ required: true, message: "Field type is required" }]}
              options={mobile_type2}
              placeholder="Select One"
              onChange={(e) => {
                setPaymentToMobileType(e);
              }}
            />
          ) : PMT === "Nagad" ? (
            <SelectBox
              name="to_mobile_bank"
              label="Mobile Banking Name*"
              rules={[{ required: true, message: "Field type is required" }]}
              options={mobile_type3}
              placeholder="Select One"
              onChange={(e) => {
                setPaymentToMobileType(e);
              }}
            />
          ) : null
        ) : null}

        <Form.Item label="Image of Documnet">
          <input
            type="file"
            id="avatar"
            name="doc_img"
            accept="image/png, image/jpeg"
            onChange={(e) => {
              setDocImg(e.target.files[0]);
            }}
          ></input>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );

  return (
    <Content>
      {Pageheader}

      {FROM}
    </Content>
  );
};

export default connect(null, { interTransferBalanace, createShop })(
  IntraTransfer
);
