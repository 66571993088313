import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import {
  ForCreateLoan,
  getLoanList,
  getBahonLoanDetails,ForPayLoan
} from "../../../actions/account";
import {
  Table,
  Input,
  Button,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
  Row,
  Col,
  Card,
  Divider,
  Tooltip,
  Pagination,
  Select,
  DatePicker,
} from "antd";
import { Option } from "antd/lib/mentions";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import CreateLoanPage from "./CreateLoanPage";
import PayLoan from "./PayLoan";
import ReceiveLoan from "./ReceiveLoan";
const { RangePicker } = DatePicker;
const LoanManagement = ({
  ForCreateLoan,
  getLoanList,
  getBahonLoanDetails,ForPayLoan
}) => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [CreateLoan, setCreateLoan] = useState(false);
  const [payloanvisible, setpayloanvisible] = useState(false);
  const [receiveloanvisible, setreciveloanvisible] = useState(false);
  const [transactionid, setTransactionID] = useState(null);
  const [state, setState] = useState(null);
  const [loanid, setLoanId] = useState(null);
  const [ds, setDs] = useState(null);
  const [de, setDe] = useState(null);
  const [loanstate, setLoanState] = useState(null);
  const [page, setPage] = useState(1);
  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getLoanList();
    const res1 = await getBahonLoanDetails();

    setData(res);
    setData1(res1);
  }, []);
  const handleApiSearch = async () => {
    const res = await getLoanList(
      page,
      transactionid,
      loanstate,
      state,
      loanid,
      ds,
      de
    );

    setData(res);
  };
  const handleVisible = async (id) => {
    history.push(`/loan-details?loan_id=${id}`);
  };

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const createLoan = () => {
    setCreateLoan(true);
  };
  const handleRefresh=async()=>{
    const res = await getLoanList();
    setData(res);
  }
  const handlePageChange = async (e) => {
    const res = await getLoanList(
      e,
      transactionid,
      loanstate,
      state,
      loanid,
      ds,
      de
    );
    setPage(e);
    setData(res);
  };
  const handleDate = (date) => {
    if (date) {
      let ds = new Date(date[0]._d);
      let de = new Date(date[1]._d);
      // setDs(ds.toLocaleDateString());
      // setDe(de.toLocaleDateString());
      setDs(ds.toISOString().split("T")[0]);
      setDe(de.toISOString().split("T")[0]);
    } else {
      setDs(null);
      setDe(null);
      return;
    }
  };
  const columns = [
    {
      title: "Loan ID",
      dataIndex: "loan_id",
      key: "loan_id",
      width: "25%",
      ...searchColumns("loan_id"),
    },
    {
      title: "Loan State",
      dataIndex: "loan_state",
      key: "loan_state",
      width: "20%",
      ...searchColumns("loan_state"),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      width: "20%",
      ...searchColumns("total_amount"),
    },
    {
      title: " Paid Amount",
      dataIndex: "paid_amount",
      key: "paid_amount",
      width: "15%",
      ...searchColumns("paid_amount"),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: "15%",
      ...searchColumns("state"),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: "18%",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "15%",
    },
  ];
  const createData = (
    loan_id,
    loan_state,
    total_amount,
    paid_amount,
    state,
    created_at,
    action
  ) => {
    return {
      loan_id,
      loan_state,
      total_amount,
      paid_amount,
      state,
      created_at,

      action,
    };
  };

  const rows = [
    data
      ? data?.list?.map((TRANSACTION, id) =>
          createData(
            TRANSACTION?.loan_id,
            TRANSACTION?.loan_state,
            TRANSACTION?.total_amount,
            TRANSACTION?.paid_amount,

            TRANSACTION?.state,
            moment.utc(TRANSACTION.created_at).format("YYYY-MM-DD hh:mm A"),
            <Button
              size="small"
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => handleVisible(TRANSACTION?.loan_id)}
            >
              View
            </Button>
          )
        )
      : "",
  ];
  const Pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Loan Management"
        subTitle="Finance Management"
        extra={[
          <>
            <Button
              key="1"
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => createLoan()}
            >
              Create Loan
            </Button>
            ,
            <Tooltip placement="topLeft">
              <strong
                style={{
                  color: "white",
                  backgroundColor: "#3AB569",
                  padding: "5px",
                }}
              >
                Total {data?.count} data found
              </strong>
            </Tooltip>
            , &nbsp;
          </>,
        ]}
      ></PageHeader>
      <CreateLoanPage
        title="Create Loan"
        visible={CreateLoan}
        cancel={() => setCreateLoan(false)}
        ok={() => setCreateLoan(false)}
        api={ForCreateLoan}
        

         refresh={handleRefresh}
      />
    </>
  );
  const balance_info = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#ffff",
          color: "white",
        }}
      >
        <Card
          hoverable
          style={{
            width: 240,
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "#ffff",
            boxShadow: '-2px -2px 20px -5px rgba(0,143,100,0.75)',
            
          }}
        >
          <h1>Payable Amount {data1?.taken} TK</h1>
          
        </Card>

        <Card
          hoverable
          style={{
            width: 240,
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "#ffff",
            boxShadow: '-2px -2px 20px -5px rgba(0,143,100,0.75)',
          }}
        >
          <h1> Receivable Amount {data1?.given} TK</h1>
          
        </Card>
      </div>
      <PayLoan
        title="Pay Loan"
        visible={payloanvisible}
        cancel={() => setpayloanvisible(false)}
        ok={() => setpayloanvisible(false)}
        api={ForPayLoan}
        refresh={handleRefresh}
      />
      <ReceiveLoan
      title="Receive Loan"
      visible={receiveloanvisible}
      cancel={() => setreciveloanvisible(false)}
      ok={() => setreciveloanvisible(false)}
      api={ForPayLoan}
      refresh={handleRefresh}
      />
    </>
  );

  const LoanList = (
    <>
      <Row>
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Transaction ID"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setTransactionID(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="loan ID"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setLoanId(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Select
            showSearch
            style={{ width: "99%" }}
            placeholder="Loan State"
            name="loan_state"
            optionFilterProp="children"
            onChange={(e) => setLoanState(e)}
            filterOption={(input, option) =>
              option.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
            }
          >
            <Option value="">All</Option>
            <Option value="pending">Pending</Option>
            <Option value="partially paid">Partially Paid</Option>
            <Option value="paid">Paid</Option>
          </Select>
        </Col>
        <br />
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Select
            showSearch
            style={{ width: "99%" }}
            placeholder="Loan State"
            name="state"
            optionFilterProp="children"
            onChange={(e) => setState(e)}
            filterOption={(input, option) =>
              option.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
            }
          >
            <Option value="">All</Option>
            <Option value="given">Given</Option>
            <Option value="taken">Taken</Option>
          </Select>
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={6}>
          <RangePicker
            onChange={(e) => {
              handleDate(e);
            }}
            format="YYYY/MM/DD"
            style={{ width: "80%" }}
          />
        </Col>
        <Tooltip title="search" onClick={handleApiSearch}>
          <Button type="primary" shape="circle" icon={<SearchOutlined />} />
        </Tooltip>
        &nbsp; &nbsp;
      </Row>
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
        pagination={false}
      />
      <Pagination
        size="small"
        current={page}
        total={data?.count}
        pageSize="20"
        onChange={handlePageChange}
        pageSizeOptions={[20]}
      />
    </>
  );

  return (
    <Content>
      {Pageheader}
      <br></br>
      {balance_info}
      <br></br>
      {LoanList}
    </Content>
  );
};

export default connect(null, {
  ForCreateLoan,
  getLoanList,
  getBahonLoanDetails,ForPayLoan
})(LoanManagement);
