import { TabletFilled } from "@ant-design/icons";
import { Button, Col, Divider, Form, PageHeader, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createRole } from "../../../actions/role";
import { renderCheckbox, renderInput } from "../../../components/form";
import history from "../../../history";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const CreateRole = ({ location, createRole }) => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [visibleRole, setVisibleRole] = useState(false);

  useEffect(async () => {
    window.scrollTo(0, 0)
    const token = new URLSearchParams(location.search).get("token");
    setData(token);
  }, []);

  const onClose = () => {
    setVisibleRole(false);
  };
  const onFinish = async (values) => {
    const code = await createRole(values);
    if (code === 201) {
      form.resetFields();
      setVisibleRole(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const Pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Create Role"
        subTitle="Admin Management"
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<TabletFilled />}
            onClick={() => history.push("/rolelist")}
          >
            Role List
          </Button>,
        ]}
      ></PageHeader>
      <br />
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {renderInput("role", "Role Name", [
          { required: true, message: "Role name is required" },
        ])}
        <Divider orientation="center">Assign role's access</Divider>
        <Row>
          <Col flex="1 1 200px">
            {renderCheckbox("user_management", "User Management", [
              { required: false },
            ])}
            {renderCheckbox("driver_management", "Driver Management", [
              { required: false },
            ])}
            {renderCheckbox("warehouse_management", "WareHouse Management", [
              { required: false },
            ])}
            {renderCheckbox("order_management", "Order Management", [
              { required: false },
            ])}
          </Col>
          <Col flex="1 1 200px">
            {renderCheckbox("merchant_management", "Marchant Management", [
              { required: false },
            ])}
            {renderCheckbox("finance_management", "Finance Management", [
              { required: false },
            ])}
            {renderCheckbox("message_management", "Support Management", [
              { required: false },
            ])}
            {renderCheckbox(
              "area_pricing_management",
              "Area Pricing Management",
              [{ required: false }]
            )}
          </Col>
        </Row>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );

  return <Content>{Pageheader}</Content>;
};

export default connect(null, { createRole })(CreateRole);
