import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  GET_AREAS,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";

export const getPayableDriver = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "finance-management/get-payable-driver"
    );
    dispatch({ type: LOADED });
    return response.data.drivers;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getPayableShop = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "finance-management/payable-shop"
    );
    dispatch({ type: LOADED });
    return response.data.shops;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getReceivableShop = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "finance-management/receivable-shop"
    );
    dispatch({ type: LOADED });
    return response.data.shops;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
export const getBahonDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/finance-management/bahon-detail`
    );
    dispatch({ type: LOADED });

    return response.data.bahon;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getTotaltransactions = (ds, de,st) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `finance-management/bahon-transaction?startDate=${ds?ds:''}&endDate=${de?de:''}&transaction_id=${st?st:''}`
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getTransactionDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/finance-management/transaction-detail?transaction_id=${id}`
    );
    dispatch({ type: LOADED });

    return response.data;
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const getFailedTransactions = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "finance-management/bahon-failed-transaction"
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getUnPaidVAT = (ds, de) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `finance-management/unpaid-vat?ds=${ds ? ds : ""}&de=${de ? de : ""}`
    );
    dispatch({ type: LOADED });
    return response.data.vat[0];
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const VatPayBalance = (formValues, img) => async (dispatch) => {
  if (img) {
    if (formValues.payment_method === "Mobile Banking") {
      dispatch({ type: LOADING });
      const data = new FormData();

      data.append("date", formValues.date.toISOString());
      data.append("ds", formValues.ds.toISOString());
      data.append("de", formValues.de.toISOString());
     
      data.append("note", formValues.note);
      data.append("payment_method", formValues.payment_method);
      data.append("mobile_bank", formValues.mobile_bank);
      data.append("mobile_banking_account", formValues.mobile_banking_account);
      data.append("tx_id", formValues.tx_id);
      data.append("doc_img", img);

      try {
        const response = await api(localStorage.getItem("token")).post(
          "finance-management/vat/pay",
          data
        );

        if (response.status === 201) {
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "Operation Successfull..!",
          });
          dispatch({ type: LOADED });
          history.push("/unpaid-vat");
        } else {
          dispatch({ type: LOADED });
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
    }
    if (formValues.payment_method === "Cash") {
      dispatch({ type: LOADING });
      const data = new FormData();

      data.append("date", formValues.date.toISOString());
      data.append("ds", formValues.ds.toISOString());
      data.append("de", formValues.de.toISOString());
     
      data.append("note", formValues.note);
      data.append("payment_method", formValues.payment_method);
      data.append("doc_img", img);

      try {
        const response = await api(localStorage.getItem("token")).post(
          "finance-management/vat/pay",
          data
        );

        if (response.status === 201) {
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "Operation Successfull..!",
          });
          dispatch({ type: LOADED });
          history.push("/unpaid-vat");
        } else {
          dispatch({ type: LOADED });
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
    }
    if (formValues.payment_method === "Bank Account") {
      if (formValues.bank_payment_option === "Check Voucher") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("date", formValues.date.toISOString());
        data.append("ds", formValues.ds.toISOString());
        data.append("de", formValues.de.toISOString());
        
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("bank_payment_option", formValues.bank_payment_option);
        data.append("bank_name", formValues.bank_name);
        data.append("branch_name", formValues.branch_name);
        data.append("check_holder", formValues.check_holder);
        data.append("issue_name", formValues.issue_name);
        data.append("check_number", formValues.check_number);
        data.append("doc_img", img);
        // data.append('doc_img', img);

        try {
          const response = await api(localStorage.getItem("token")).post(
            "finance-management/vat/pay",
            data
          );

          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/unpaid-vat");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });

            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      } else {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("date", formValues.date.toISOString());
        data.append("ds", formValues.ds.toISOString());
        data.append("de", formValues.de.toISOString());
        
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("bank_payment_option", formValues.bank_payment_option);
        data.append("bank_name", formValues.bank_name);
        data.append("branch_name", formValues.branch_name);
        data.append("doc_img", img);

        try {
          const response = await api(localStorage.getItem("token")).post(
            "finance-management/vat/pay",
            data
          );

          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/unpaid-vat");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
            localStorage.clear();
            history.push("/login");
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
    }
  } else {
    if (formValues.payment_method === "Mobile Banking") {
      dispatch({ type: LOADING });
      const data = new FormData();

      data.append("date", formValues.date.toISOString());
      data.append("ds", formValues.ds.toISOString());
      data.append("de", formValues.de.toISOString());
      
      data.append("note", formValues.note);
      data.append("payment_method", formValues.payment_method);
      data.append("mobile_bank", formValues.mobile_bank);
      data.append("mobile_banking_account", formValues.mobile_banking_account);
      data.append("tx_id", formValues.tx_id);

      try {
        const response = await api(localStorage.getItem("token")).post(
          "finance-management/vat/pay",
          data
        );

        if (response.status === 201) {
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "Operation Successfull..!",
          });
          dispatch({ type: LOADED });
          history.push("/unpaid-vat");
        } else {
          dispatch({ type: LOADED });
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
    }
    if (formValues.payment_method === "Cash") {
      dispatch({ type: LOADING });
      const data = new FormData();

      data.append("date", formValues.date.toISOString());
      data.append("ds", formValues.ds.toISOString());
      data.append("de", formValues.de.toISOString());
    
      data.append("note", formValues.note);
      data.append("payment_method", formValues.payment_method);

      try {
        const response = await api(localStorage.getItem("token")).post(
          "finance-management/vat/pay",
          data
        );

        if (response.status === 201) {
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "Operation Successfull..!",
          });
          dispatch({ type: LOADED });
          history.push("/unpaid-vat");
        } else {
          dispatch({ type: LOADED });
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
    }
    if (formValues.payment_method === "Bank Account") {
      if (formValues.bank_payment_option === "Check Voucher") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("date", formValues.date.toISOString());
        data.append("ds", formValues.ds.toISOString());
        data.append("de", formValues.de.toISOString());
        
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("bank_payment_option", formValues.bank_payment_option);
        data.append("bank_name", formValues.bank_name);
        data.append("branch_name", formValues.branch_name);
        data.append("check_holder", formValues.check_holder);
        data.append("issue_name", formValues.issue_name);
        data.append("check_number", formValues.check_number);

        // data.append('doc_img', img);

        try {
          const response = await api(localStorage.getItem("token")).post(
            "finance-management/vat/pay",
            data
          );

          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/unpaid-vat");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      } else {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("date", formValues.date.toISOString());
        data.append("ds", formValues.ds.toISOString());
        data.append("de", formValues.de.toISOString());
      
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("bank_payment_option", formValues.bank_payment_option);
        data.append("bank_name", formValues.bank_name);
        data.append("branch_name", formValues.branch_name);

        try {
          const response = await api(localStorage.getItem("token")).post(
            "finance-management/vat/pay",
            data
          );

          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/unpaid-vat");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
    }
  }
};
// ================Withdraw balance============
export const withdrawBalance = (formValues, img) => async (dispatch) => {
  if (img) {
    if(formValues.status==='new'){
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("new_status", formValues.new_status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/withdraw-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            // history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("new_status", formValues.new_status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/withdraw-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("new_status", formValues.new_status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
          data.append("doc_img", img);
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/withdraw-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("new_status", formValues.new_status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("doc_img", img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/withdraw-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
    }else{
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/withdraw-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
       
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/withdraw-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
          data.append("doc_img", img);
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/withdraw-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("doc_img", img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/withdraw-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }

    }
    
  } else {
    if(formValues.status==='new'){
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("new_status", formValues.new_status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/withdraw-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("new_status", formValues.new_status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/withdraw-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("new_status", formValues.new_status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
  
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/withdraw-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("new_status", formValues.new_status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/withdraw-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
      
    }
    else{
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/withdraw-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/withdraw-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
  
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/withdraw-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/withdraw-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }

    }


    
  }
};
// ================Deposite balance============
export const putDepositeBalance = (formValues, img) => async (dispatch) => {
  if (img) {
    if(formValues.status==='new'){
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("new_status", formValues.new_status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/deposit-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            // history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("new_status", formValues.new_status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/deposit-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("new_status", formValues.new_status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
          data.append("doc_img", img);
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/deposit-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("new_status", formValues.new_status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("doc_img", img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/deposit-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
    }else{
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/deposit-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/deposit-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
          data.append("doc_img", img);
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/deposit-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("doc_img", img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/deposit-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
    }
    
  } else {
    if(formValues.status==='new'){
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("new_status", formValues.new_status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/deposit-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("new_status", formValues.new_status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/deposit-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("new_status", formValues.new_status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
  
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/deposit-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("new_status", formValues.new_status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/deposit-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
    }else{
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/deposit-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("status", formValues.status);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/deposit-balance",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            history.push("/total-transaction");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
  
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/deposit-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("status", formValues.status);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/deposit-balance",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
              history.push("/total-transaction");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
    }
    
  }
};

export const interTransferBalanace = (data, img) => async (dispatch) => {
  dispatch({ type: LOADING });
  const cData = new FormData();
  cData.append("date", data.date.toISOString());
  cData.append("amount", data.amount);
  cData.append("note", data.note);
  cData.append("from_payment_method", data.from_payment_method);
  cData.append("to_payment_method", data.to_payment_method);
  if (img) {
    cData.append("doc_img", img);
  }
  if (
    data.from_payment_method === "Mobile Banking" &&
    data.to_payment_method === "Mobile Banking"
  ) {
    cData.append("from_mobile_bank", data.from_mobile_bank);
    cData.append("to_mobile_bank", data.to_mobile_bank);
  }
  if (
    data.from_payment_method === "Mobile Banking" &&
    data.to_payment_method === "Bank Account"
  ) {
    cData.append("from_mobile_bank", data.from_mobile_bank);
  }
  if (
    data.from_payment_method === "Mobile Banking" &&
    data.to_payment_method === "Cash"
  ) {
    cData.append("from_mobile_bank", data.from_mobile_bank);
  }
  if (
    data.from_payment_method === "Cash" &&
    data.to_payment_method === "Mobile Banking"
  ) {
    cData.append("to_mobile_bank", data.to_mobile_bank);
  }
  if (
    data.from_payment_method === "Bank Account" &&
    data.to_payment_method === "Mobile Banking"
  ) {
    cData.append("to_mobile_bank", data.to_mobile_bank);
  }

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/finance-management/intra-transfer",
      cData
    );
    if (response.status === 201) {
      dispatch({ type: SUCCESS_MESSAGE, payload: "Operation Successfull..!" });
      dispatch({ type: LOADED });
      history.push("/total-transaction");
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const FinanceStatus = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `finance-management/finance/status`
    );
  
    dispatch({ type: LOADED });
    return response?.data;
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};
export const searchDriver = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `finance-management/get-driver?driver=${id}`
    );
    dispatch({ type: LOADED });
    return response.data.driver;
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const payToDriver = (formValues, id, img) => async (dispatch) => {
  if (img) {
    if (formValues.payment_method === "Mobile Banking") {
      dispatch({ type: LOADING });
      const data = new FormData();

      data.append("driver", id);
      data.append("date", formValues.date.toISOString());
      data.append("amount", formValues.amount);
      data.append("note", formValues.note);
      data.append("payment_method", formValues.payment_method);
      data.append("mobile_bank", formValues.mobile_bank);
      data.append("mobile_banking_account", formValues.mobile_banking_account);
      data.append("tx_id", formValues.tx_id);
      data.append("doc_img", img);

      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/finance-management/payable-driver",
          data
        );

        if (response.status === 201) {
          dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
          dispatch({ type: LOADED });
          history.push("/payable-driver");
        } else {
          dispatch({ type: LOADED });
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
    }
    if (formValues.payment_method === "Cash") {
      dispatch({ type: LOADING });
      const data = new FormData();

      data.append("driver", id);
      data.append("date", formValues.date.toISOString());
      data.append("amount", formValues.amount);
      data.append("note", formValues.note);
      data.append("payment_method", formValues.payment_method);
      data.append("doc_img", img);

      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/finance-management/payable-driver",
          data
        );

        if (response.status === 201) {
          dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
          dispatch({ type: LOADED });
          history.push("/payable-driver");
        } else {
          dispatch({ type: LOADED });
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
    }
    if (formValues.payment_method === "Bank Account") {
      if (formValues.bank_payment_option === "Check Voucher") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("driver", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("bank_payment_option", formValues.bank_payment_option);
        data.append("bank_name", formValues.bank_name);
        data.append("branch_name", formValues.branch_name);
        data.append("check_holder", formValues.check_holder);
        data.append("issue_name", formValues.issue_name);
        data.append("check_number", formValues.check_number);
        data.append("doc_img", img);
        // data.append('doc_img', img);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/payable-driver",
            data
          );

          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
            dispatch({ type: LOADED });
            history.push("/payable-driver");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      } else {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("driver", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("bank_payment_option", formValues.bank_payment_option);
        data.append("bank_name", formValues.bank_name);
        data.append("branch_name", formValues.branch_name);
        data.append("doc_img", img);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/payable-driver",
            data
          );

          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
            dispatch({ type: LOADED });
            history.push("/payable-driver");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
    }
  } else {
    if (formValues.payment_method === "Mobile Banking") {
      dispatch({ type: LOADING });
      const data = new FormData();

      data.append("driver", id);
      data.append("date", formValues.date.toISOString());
      data.append("amount", formValues.amount);
      data.append("note", formValues.note);
      data.append("payment_method", formValues.payment_method);
      data.append("mobile_bank", formValues.mobile_bank);
      data.append("mobile_banking_account", formValues.mobile_banking_account);
      data.append("tx_id", formValues.tx_id);

      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/finance-management/payable-driver",
          data
        );

        if (response.status === 201) {
          dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
          dispatch({ type: LOADED });
          history.push("/payable-driver");
        } else {
          dispatch({ type: LOADED });
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
    }
    if (formValues.payment_method === "Cash") {
      dispatch({ type: LOADING });
      const data = new FormData();

      data.append("driver", id);
      data.append("date", formValues.date.toISOString());
      data.append("amount", formValues.amount);
      data.append("note", formValues.note);
      data.append("payment_method", formValues.payment_method);

      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/finance-management/payable-driver",
          data
        );

        if (response.status === 201) {
          dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
          dispatch({ type: LOADED });
          history.push("/payable-driver");
        } else {
          dispatch({ type: LOADED });
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
    }
    if (formValues.payment_method === "Bank Account") {
      if (formValues.bank_payment_option === "Check Voucher") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("driver", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("bank_payment_option", formValues.bank_payment_option);
        data.append("bank_name", formValues.bank_name);
        data.append("branch_name", formValues.branch_name);
        data.append("check_holder", formValues.check_holder);
        data.append("issue_name", formValues.issue_name);
        data.append("check_number", formValues.check_number);

        // data.append('doc_img', img);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/payable-driver",
            data
          );

          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
            dispatch({ type: LOADED });
            history.push("/payable-driver");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      } else {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("driver", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("bank_payment_option", formValues.bank_payment_option);
        data.append("bank_name", formValues.bank_name);
        data.append("branch_name", formValues.branch_name);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/payable-driver",
            data
          );

          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
            dispatch({ type: LOADED });
            history.push("/payable-driver");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
    }
  }
};
export const getShopAccountDetails = (mobile, id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/finance-management/shop-detail?merchant=${mobile}&shop_id=${id}`
    );
    dispatch({ type: LOADED });
    return response.data.details[0];
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const receiveFromShop =
  (formValues, id, merchant, img) => async (dispatch) => {
    if (img) {
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("merchant", merchant);
        data.append("shop_id", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append(
          "mobile_banking_account",
          formValues.mobile_banking_account
        );
        data.append("tx_id", formValues.tx_id);
        data.append("doc_img", img);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/receivable-shop-payment",
            data
          );

          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Succeessfully Received",
            });
            dispatch({ type: LOADED });
            history.push("/receivable-shop");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("merchant", merchant);
        data.append("shop_id", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("doc_img", img);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/receivable-shop-payment",
            data
          );

          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Succeessfully Received",
            });
            dispatch({ type: LOADED });
            history.push("/receivable-shop");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();

          data.append("merchant", merchant);
          data.append("shop_id", id);
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
          data.append("doc_img", img);
          // data.append('doc_img', img);

          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/receivable-shop-payment",
              data
            );

            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Succeessfully Received",
              });
              dispatch({ type: LOADED });
              history.push("/receivable-shop");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();

          data.append("merchant", merchant);
          data.append("shop_id", id);
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("doc_img", img);

          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/receivable-shop-payment",
              data
            );

            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Succeessfully Received",
              });
              dispatch({ type: LOADED });
              history.push("/receivable-shop");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
    } else {
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("merchant", merchant);
        data.append("shop_id", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append(
          "mobile_banking_account",
          formValues.mobile_banking_account
        );
        data.append("tx_id", formValues.tx_id);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/receivable-shop-payment",
            data
          );

          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Succeessfully Received",
            });
            dispatch({ type: LOADED });
            history.push("/receivable-shop");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("merchant", merchant);
        data.append("shop_id", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/receivable-shop-payment",
            data
          );

          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Succeessfully Received",
            });
            dispatch({ type: LOADED });
            history.push("/receivable-shop");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();

          data.append("merchant", merchant);
          data.append("shop_id", id);
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);

          // data.append('doc_img', img);

          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/receivable-shop-payment",
              data
            );

            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Succeessfully Received",
              });
              dispatch({ type: LOADED });
              history.push("/receivable-shop");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();

          data.append("merchant", merchant);
          data.append("shop_id", id);
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);

          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/receivable-shop-payment",
              data
            );

            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Succeessfully Received",
              });
              dispatch({ type: LOADED });
              history.push("/receivable-shop");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
    }
  };
// ================== Shop pay=====
export const payToShop =
  (formValues, id, merchant, img, status) => async (dispatch) => {
    if (img) {
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("merchant", merchant);
        data.append("shop_id", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("tx_id", formValues.tx_id);
        data.append("doc_img", img);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/payable-shop-payment",
            data
          );

          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
            dispatch({ type: LOADED });
            history.push("/payable-shop");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("merchant", merchant);
        data.append("shop_id", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("doc_img", img);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/payable-shop-payment",
            data
          );

          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
            dispatch({ type: LOADED });
            history.push("/payable-shop");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();

          data.append("merchant", merchant);
          data.append("shop_id", id);
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
          data.append("doc_img", img);
          // data.append('doc_img', img);

          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/payable-shop-payment",
              data
            );

            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Succeessfully Paid",
              });
              dispatch({ type: LOADED });
              history.push("/payable-shop");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();

          data.append("merchant", merchant);
          data.append("shop_id", id);
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("doc_img", img);

          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/payable-shop-payment",
              data
            );

            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Succeessfully Paid",
              });
              dispatch({ type: LOADED });
              history.push("/payable-shop");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
    } else {
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("merchant", merchant);
        data.append("shop_id", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("tx_id", formValues.tx_id);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/payable-shop-payment",
            data
          );

          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
            dispatch({ type: LOADED });
            history.push("/payable-shop");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();

        data.append("merchant", merchant);
        data.append("shop_id", id);
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);

        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/payable-shop-payment",
            data
          );

          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: "Succeessfully Paid" });
            dispatch({ type: LOADED });
            history.push("/payable-shop");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response.data.message,
            });
            localStorage.clear();
            history.push("/login");
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();

          data.append("merchant", merchant);
          data.append("shop_id", id);
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);

          // data.append('doc_img', img);

          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/payable-shop-payment",
              data
            );

            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Succeessfully Paid",
              });
              dispatch({ type: LOADED });
              history.push("/payable-shop");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();

          data.append("merchant", merchant);
          data.append("shop_id", id);
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);

          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/payable-shop-payment",
              data
            );

            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Succeessfully Paid",
              });
              dispatch({ type: LOADED });
              history.push("/payable-shop");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
    }
  };
  export const TransactionreportDownloader = (startDate,endDate) => async (dispatch) => {
    dispatch({ type: LOADING });
    
    try{
        const response = await api(localStorage.getItem("token")).get(`finance-management/bahon/transaction/report?ds=${startDate?startDate:''}&de=${endDate?endDate:''}`,
          {
            headers: {
              'authorization': `Bearer ${localStorage.getItem("token")}`
              },
              method: 'GET',
              responseType: 'blob',
            }
          );
          
      if(response.status === 200 ) {
        // FileDown
        dispatch({ type: LOADED });
        const url = window.URL.createObjectURL(new Blob([response.data]),);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `TransactionReport.xlsx`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    }
    catch( err ){
        if(err?.response?.status === 451)
          {
            history.push('/pending-verification')
          }
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  }

  export const getAlWarehouseDetails = (id) => async (dispatch) => {
    
    dispatch({ type: LOADING });
  try {
    
    const response = await api(localStorage.getItem("token")).get(`finance-management/get-warehouse?warehouse_id=${id}`
    );
    dispatch({ type: LOADED });
    
    return response.data.warehouse[0]
  } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

export const collectAmountFromWarehouse = (formValues,id, img) => async (dispatch) => {
  
  if(img){
    if(formValues.payment_method === 'Mobile Banking'){
      dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append('warehouse_id', id);
        data.append('manager_id', formValues.manager_id);
        data.append('date', formValues.date.toISOString());
        data.append('amount', formValues.amount);
        data.append('note', formValues.note);
        data.append('payment_method', formValues.payment_method);
        data.append('mobile_bank', formValues.mobile_bank);
        data.append('mobile_banking_account', formValues.mobile_banking_account);
        data.append('tx_id', formValues.tx_id);
        data.append('doc_img', img);
        
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/finance-management/collect-warehouse-collection", data);
  
        if (response.status === 201) {
          dispatch({ type: SUCCESS_MESSAGE, payload: 'Succeessfully Collected' });
          dispatch({ type: LOADED });
          history.push("/all-warehouse");
        } else {
          dispatch({ type: LOADED });
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  
    }
    if(formValues.payment_method === 'Cash'){
      dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append('warehouse_id', id);
        data.append('manager_id', formValues.manager_id);
        data.append('date', formValues.date.toISOString());
        data.append('amount', formValues.amount);
        data.append('note', formValues.note);
        data.append('payment_method', formValues.payment_method);
        data.append('doc_img', img);
        
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/finance-management/collect-warehouse-collection", data);
  
        if (response.status === 201) {
          dispatch({ type: SUCCESS_MESSAGE, payload: 'Succeessfully Collected' });
          dispatch({ type: LOADED });
          history.push("/all-warehouse");
        } else {
          dispatch({ type: LOADED });
        }
      } catch (err) {
            /* =============Start========== */
              if(err?.response?.status === 422){
                err.response.data.errors.map(item => {
                    dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
                })
              }
              if(err?.response?.status === 401 || err?.response?.status === 403 ){
                  dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                  localStorage.clear();
                  history.push('/login')
              }
              dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
              dispatch({type: LOADED});
            /* ===========End============*/
          }
  
    }
    if(formValues.payment_method === 'Bank Account'){
      if(formValues.bank_payment_option === 'Check Voucher'){
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append('warehouse_id', id);
        data.append('manager_id', formValues.manager_id);
        data.append('date', formValues.date.toISOString());
        data.append('amount', formValues.amount);
        data.append('note', formValues.note);
        data.append('payment_method', formValues.payment_method);
        data.append('bank_payment_option', formValues.bank_payment_option);
        data.append('bank_name', formValues.bank_name);
        data.append('branch_name', formValues.branch_name);
        data.append('check_holder', formValues.check_holder);
        data.append('issue_name', formValues.issue_name);
        data.append('check_number', formValues.check_number);
        data.append('doc_img', img);
        // data.append('doc_img', img);
        
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/collect-warehouse-collection", data);
  
          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: 'Succeessfully Collected' });
            dispatch({ type: LOADED });
            history.push("/all-warehouse");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
              /* =============Start========== */
                if(err?.response?.status === 422){
                  err.response.data.errors.map(item => {
                      dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
                  })
                }
                if(err?.response?.status === 401 || err?.response?.status === 403 ){
                    dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                    localStorage.clear();
                    history.push('/login')
                }
                dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
                dispatch({type: LOADED});
              /* ===========End============*/
            }
      }
      else{
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append('warehouse_id', id);
        data.append('manager_id', formValues.manager_id);
        data.append('date', formValues.date.toISOString());
        data.append('amount', formValues.amount);
        data.append('note', formValues.note);
        data.append('payment_method', formValues.payment_method);
        data.append('bank_payment_option', formValues.bank_payment_option);
        data.append('bank_name', formValues.bank_name);
        data.append('branch_name', formValues.branch_name);
        data.append('doc_img', img);
        
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/collect-warehouse-collection", data);
  
          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: 'Succeessfully Collected' });
            dispatch({ type: LOADED });
            history.push("/all-warehouse");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
              /* =============Start========== */
                if(err?.response?.status === 422){
                  err.response.data.errors.map(item => {
                      dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
                  })
                }
                if(err?.response?.status === 401 || err?.response?.status === 403 ){
                    dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                    localStorage.clear();
                    history.push('/login')
                }
                dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
                dispatch({type: LOADED});
              /* ===========End============*/
            }
      }
        
  
    }
    
  }else{
      if(formValues.payment_method === 'Mobile Banking'){
        dispatch({ type: LOADING });
          const data = new FormData();
    
          data.append('warehouse_id', id);
          data.append('manager_id', formValues.manager_id);
          data.append('date', formValues.date.toISOString());
          data.append('amount', formValues.amount);
          data.append('note', formValues.note);
          data.append('payment_method', formValues.payment_method);
          data.append('mobile_bank', formValues.mobile_bank);
          data.append('mobile_banking_account', formValues.mobile_banking_account);
          data.append('tx_id', formValues.tx_id);
          
          
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/collect-warehouse-collection", data);
    
          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: 'Succeessfully Collected' });
            dispatch({ type: LOADED });
            history.push("/all-warehouse");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
              /* =============Start========== */
                if(err?.response?.status === 422){
                  err.response.data.errors.map(item => {
                      dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
                  })
                }
                if(err?.response?.status === 401 || err?.response?.status === 403 ){
                    dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                    localStorage.clear();
                    history.push('/login')
                }
                dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
                dispatch({type: LOADED});
              /* ===========End============*/
            }
    
      }
      if(formValues.payment_method === 'Cash'){
        dispatch({ type: LOADING });
          const data = new FormData();
    
          data.append('warehouse_id', id);
          data.append('manager_id', formValues.manager_id);
          data.append('date', formValues.date.toISOString());
          data.append('amount', formValues.amount);
          data.append('note', formValues.note);
          data.append('payment_method', formValues.payment_method);
          
          
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/collect-warehouse-collection", data);
    
          if (response.status === 201) {
            dispatch({ type: SUCCESS_MESSAGE, payload: 'Succeessfully Collected' });
            dispatch({ type: LOADED });
            history.push("/all-warehouse");
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
              /* =============Start========== */
                if(err?.response?.status === 422){
                  err.response.data.errors.map(item => {
                      dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
                  })
                }
                if(err?.response?.status === 401 || err?.response?.status === 403 ){
                    dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                    localStorage.clear();
                    history.push('/login')
                }
                dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
                dispatch({type: LOADED});
              /* ===========End============*/
            }
    
      }
      if(formValues.payment_method === 'Bank Account'){
        if(formValues.bank_payment_option === 'Check Voucher'){
          dispatch({ type: LOADING });
          const data = new FormData();
    
          data.append('warehouse_id', id);
          data.append('manager_id', formValues.manager_id);
          data.append('date', formValues.date.toISOString());
          data.append('amount', formValues.amount);
          data.append('note', formValues.note);
          data.append('payment_method', formValues.payment_method);
          data.append('bank_payment_option', formValues.bank_payment_option);
          data.append('bank_name', formValues.bank_name);
          data.append('branch_name', formValues.branch_name);
          data.append('check_holder', formValues.check_holder);
          data.append('issue_name', formValues.issue_name);
          data.append('check_number', formValues.check_number);
          
          // data.append('doc_img', img);
          
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/collect-warehouse-collection", data);
    
            if (response.status === 201) {
              dispatch({ type: SUCCESS_MESSAGE, payload: 'Succeessfully Collected' });
              dispatch({ type: LOADED });
              history.push("/all-warehouse");
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
              if(err?.response?.status === 422){
                err.response.data.errors.map(item => {
                    dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
                })
              }
              if(err?.response?.status === 401 || err?.response?.status === 403 ){
                  dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                  localStorage.clear();
                  history.push('/login')
              }
              dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
              dispatch({type: LOADED});
            /* ===========End============*/
          }
        }
        else{
          dispatch({ type: LOADING });
          const data = new FormData();
    
          data.append('warehouse_id', id);
          data.append('manager_id', formValues.manager_id);
          data.append('date', formValues.date.toISOString());
          data.append('amount', formValues.amount);
          data.append('note', formValues.note);
          data.append('payment_method', formValues.payment_method);
          data.append('bank_payment_option', formValues.bank_payment_option);
          data.append('bank_name', formValues.bank_name);
          data.append('branch_name', formValues.branch_name);
         
          
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/collect-warehouse-collection", data);
    
            if (response.status === 201) {
              dispatch({ type: SUCCESS_MESSAGE, payload: 'Succeessfully Collected' });
              dispatch({ type: LOADED });
              history.push("/all-warehouse");
              } else {
                dispatch({ type: LOADED });
              }
            } catch (err) {
              /* =============Start========== */
                if(err?.response?.status === 422){
                  err.response.data.errors.map(item => {
                      dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
                  })
                }
                if(err?.response?.status === 401 || err?.response?.status === 403 ){
                    dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                    localStorage.clear();
                    history.push('/login')
                }
                dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
                dispatch({type: LOADED});
              /* ===========End============*/
            }
        }
          
    
      }
    }

};

export const ForCreateLoan = (formValues, img) => async (dispatch) => {
 
  if (img) {
  
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("state", formValues.state);
        data.append("type", formValues.type);
        data.append("description", formValues.description);
        data.append("name", formValues.name);
        data.append("mobile_number", formValues.mobile_number);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).post(
            "/finance-management/loan/create",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
           return response.status
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("state", formValues.state);
        data.append("type", formValues.type);
        data.append("description", formValues.description);
        data.append("name", formValues.name);
        data.append("mobile_number", formValues.mobile_number);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).post(
            "/finance-management/loan/create",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
           return response.status
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("state", formValues.state);
        data.append("type", formValues.type);
        data.append("description", formValues.description);
        data.append("name", formValues.name);
        data.append("mobile_number", formValues.mobile_number);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
          data.append("doc_img", img);
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).post(
              "/finance-management/loan/create",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
             return response.status
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("state", formValues.state);
        data.append("type", formValues.type);
        data.append("description", formValues.description);
        data.append("name", formValues.name);
        data.append("mobile_number", formValues.mobile_number);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("doc_img", img);
  
          try {
            const response = await api(localStorage.getItem("token")).post(
              "/finance-management/loan/create",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
             return response.status
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
   
    
  } else {
   
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("state", formValues.state);
        data.append("type", formValues.type);
        data.append("description", formValues.description);
        data.append("name", formValues.name);
        data.append("mobile_number", formValues.mobile_number);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
  
        try {
          const response = await api(localStorage.getItem("token")).post(
            "/finance-management/loan/create",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
           return response.status
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("state", formValues.state);
        data.append("type", formValues.type);
        data.append("description", formValues.description);
        data.append("name", formValues.name);
        data.append("mobile_number", formValues.mobile_number);
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
  
        try {
          const response = await api(localStorage.getItem("token")).post(
            "/finance-management/loan/create",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
           return response.status
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("state", formValues.state);
        data.append("type", formValues.type);
        data.append("description", formValues.description);
        data.append("name", formValues.name);
        data.append("mobile_number", formValues.mobile_number);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
          
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).post(
              "/finance-management/loan/create",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
             return response.status
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("state", formValues.state);
        data.append("type", formValues.type);
        data.append("description", formValues.description);
        data.append("name", formValues.name);
        data.append("mobile_number", formValues.mobile_number);
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
         
  
          try {
            const response = await api(localStorage.getItem("token")).post(
              "/finance-management/loan/create",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
             return response.status
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
      
  


    
  }
};

export const getBahonLoanDetails = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    
   
    const response = await api(localStorage.getItem("token")).get(`finance-management/loan/amount`);
    
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}
export const getLoanList = (page,transaction_id,loan_state,state,loan_id,startDate,endDate) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    
   
    const response = await api(localStorage.getItem("token")).get(`finance-management/loan?p=${page ? page : 1}&t=${transaction_id ? transaction_id : ''}&ls=${loan_state ? loan_state : ''}&s=${state ? state : ''}&l=${loan_id ? loan_id : ''}&ds=${startDate?startDate:''}&de=${endDate?endDate:''}`);
    
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const getLoanDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `finance-management/loan/detail?loan=${id}`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.detail;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const ForPayLoan = (formValues, img) => async (dispatch) => {
 
  if (img) {
  
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("loan", formValues.loan);
        
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/loan/pay",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
            return response.status
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("loan", formValues.loan);
        
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("doc_img", img);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/loan/pay",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
           return response.status
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("loan", formValues.loan);
        
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
          data.append("doc_img", img);
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/loan/pay",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
             return response.status
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("loan", formValues.loan);
        
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("doc_img", img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/loan/pay",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
             return response.status
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
   
    
  } else {
   
      if (formValues.payment_method === "Mobile Banking") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("loan", formValues.loan);
        
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
        data.append("mobile_bank", formValues.mobile_bank);
        data.append("mobile_banking_account", formValues.mobile_banking_account);
        data.append("tx_id", formValues.tx_id);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/loan/pay",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
           return response.status
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Cash") {
        dispatch({ type: LOADING });
        const data = new FormData();
  
        data.append("date", formValues.date.toISOString());
        data.append("amount", formValues.amount);
        data.append("loan", formValues.loan);
       
        data.append("note", formValues.note);
        data.append("payment_method", formValues.payment_method);
  
        try {
          const response = await api(localStorage.getItem("token")).patch(
            "/finance-management/loan/pay",
            data
          );
  
          if (response.status === 201) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Operation Successfull..!",
            });
            dispatch({ type: LOADED });
           return response.status
          } else {
            dispatch({ type: LOADED });
          }
        } catch (err) {
          /* =============Start========== */
          if (err?.response?.status === 422) {
            err.response.data.errors.map((item) => {
              dispatch({
                type: VALIDATION_ERROR,
                payload: [{ message: item.message, path: item.path[0] }],
              });
            });
          }
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
            localStorage.clear();
            history.push("/login");
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response
              ? err.response.data.message
              : history.push("/notFound"),
          });
          dispatch({ type: LOADED });
          /* ===========End============*/
        }
      }
      if (formValues.payment_method === "Bank Account") {
        if (formValues.bank_payment_option === "Check Voucher") {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("loan", formValues.loan);
        
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
          data.append("check_holder", formValues.check_holder);
          data.append("issue_name", formValues.issue_name);
          data.append("check_number", formValues.check_number);
          
          // data.append('doc_img', img);
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/loan/pay",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
             return response.status
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        } else {
          dispatch({ type: LOADING });
          const data = new FormData();
  
          data.append("date", formValues.date.toISOString());
          data.append("amount", formValues.amount);
          data.append("loan", formValues.loan);
        
          data.append("note", formValues.note);
          data.append("payment_method", formValues.payment_method);
          data.append("bank_payment_option", formValues.bank_payment_option);
          data.append("bank_name", formValues.bank_name);
          data.append("branch_name", formValues.branch_name);
         
  
          try {
            const response = await api(localStorage.getItem("token")).patch(
              "/finance-management/loan/pay",
              data
            );
  
            if (response.status === 201) {
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Operation Successfull..!",
              });
              dispatch({ type: LOADED });
             return response.status
            } else {
              dispatch({ type: LOADED });
            }
          } catch (err) {
            /* =============Start========== */
            if (err?.response?.status === 422) {
              err.response.data.errors.map((item) => {
                dispatch({
                  type: VALIDATION_ERROR,
                  payload: [{ message: item.message, path: item.path[0] }],
                });
              });
            }
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              dispatch({
                type: ERROR_MESSAGE,
                payload: err.response.data.message,
              });
              localStorage.clear();
              history.push("/login");
            }
            if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response
                ? err.response.data.message
                : history.push("/notFound"),
            });
            dispatch({ type: LOADED });
            /* ===========End============*/
          }
        }
      }
      
  


    
  }
};