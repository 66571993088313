import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Table, Tag, Card, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ParcelStatistics } from "../../../actions/parcel";
import { Column, Line, Pie, getCanvasPattern } from "@ant-design/plots";

const ParcelDashboardS = ({ ParcelStatistics }) => {
  const [data, setData] = useState([]);
  const [lastmonthStatistic, setLastMonthStatistic] = useState([]);
  const [totalStatistic, setTotalStatistic] = useState([]);
  const [data3, setData3] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0)
    const parcel_statistic = await ParcelStatistics();

    const piechartData = [];

    piechartData.push(
      {
        type: "Return",
        value: parcel_statistic?.statistic[0]?.totalOrderStatus[0].return,
      },
      {
        type: "Delivered",
        value: parcel_statistic?.statistic[0]?.totalOrderStatus[0].delivered,
      },
      {
        type: "Cancel",
        value: parcel_statistic?.statistic[0]?.totalOrderStatus[0].cancel,
      },
      {
        type: "Pending",
        value: parcel_statistic?.statistic[0]?.totalOrderStatus[0].pending,
      }
    );
    setData(piechartData);

    setLastMonthStatistic(parcel_statistic?.statistic[0]?.lastMonthStatus[0]);
    setTotalStatistic(parcel_statistic?.statistic[0]?.totalOrderStatus[0]);

    let lintChartData = [];

    for (
      let i = 0;
      i < parcel_statistic?.statistic[0]?.lastYearReport?.length;
      i++
    ) {
      lintChartData.push({
        name: "parcel",
        count: parcel_statistic?.statistic[0]?.lastYearReport[i].order,
        date: parcel_statistic?.statistic[0]?.lastYearReport[i].month,
      });
    }

    setData3(lintChartData);
  }, []);

 

  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
    },
    // 自定义状态样式
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.65,
        },
      },
    },
    // 添加 element 选中和激活交互
    interactions: [
      {
        type: "element-single-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  const Total_statistic = <Pie {...config} />;

 


 

  return (
    <Content>
     
      {Total_statistic}
      
    </Content>
  );
};

export default connect(null, {
  ParcelStatistics,
})(ParcelDashboardS);
