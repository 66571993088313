import { DownloadOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  PageHeader,
  Table,
  Col,
  Row,
  Tooltip,
  Select,
  DatePicker,
  Pagination,
  Form,
} from "antd";

import { Content } from "antd/lib/layout/layout";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getReport,reportDownloader } from "../../../actions/report";
const { RangePicker } = DatePicker;
const MerchantTransactionReport = ({ getReport,reportDownloader }) => {
  const [data, setData] = useState([]);
  const [page, setpage] = useState([1]);
  const [id, setId] = useState([""]);
  const [transaction_id, setTransactionID] = useState([null]);
  const [transaction, setTransaction] = useState([null]);
  const [merchant, setMerchant] = useState([null]);
  const [shop, setShop] = useState([null]);
  const [type, setType] = useState(["read"]);
  const [startDate, setStartDate] = useState([null]);
  const [endDate, setEndDate] = useState([null]);
  const [c, setC] = useState([1]);

  const [form] = Form.useForm();
  useEffect(async () => {
    window.scrollTo(0, 0);
    // const res = await getReport(
    //   setTransactionID(),
    //   setTransaction(),
    //   setMerchant(),
    //   setShop(),
    //   setType('read'),
    //   setStartDate(),
    //   setEndDate()
    // );
    
    form.setFieldsValue({
      type: "read",
    });
    // setData(res);
    // console.log(res);
  }, []);

  const handleApiSearch = async () => {
    const res = await getReport(
      page,
      transaction_id,
      transaction,
      merchant,
      shop,
     'read',
      startDate,
      endDate
    )
  
    
    setData(res);
  };
  const handleApiDownload = async () => {
    const res = await reportDownloader(
      page,
      transaction_id,
      transaction,
      merchant,
      shop,
      'download',
      startDate,
      endDate
    )
    
    setData(res);
  };
  const handlePageChange = async (e) => {
    const res = await getReport(
      e,
      transaction_id,
      transaction,
      merchant,
      shop,
      'read',
      startDate,
      endDate
    );
  
setpage(e)
    setData(res);
  };
  const handleDate = (date) => {
    if (date) {
      let startDate = new Date(date[0]._d);
      let endDate = new Date(date[1]._d);
       let st=startDate?.toISOString()?.split("T")[0]
       let et=endDate?.toISOString()?.split("T")[0]
      setStartDate(st);
      setEndDate(et);
    } else {
      setStartDate(null);
      setEndDate(null);
      return;
    }
  };

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: "20%",
    },
    {
      title: "Merchant Info",
      dataIndex: "merchant_info",
      key: "merchant_info",
      width: "20%",
    },
    {
      title: "Shop Info",
      dataIndex: "shop_info",
      key: "shop_info",
      width: "20%",
    },
    {
      title: "Transfer Amount",
      dataIndex: "transfer_amount",
      key: "transfer_amount",
      width: "15%",
    },
    {
      title: "Delivery Charge",
      dataIndex: "delivery_charge",
      key: "delivery_charge",
      width: "15%",
    },
    {
      title: "Collected Amount",
      dataIndex: "collected_amount",
      key: "collected_amount",
      width: "15%",
    },

    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: "15%",
    },
    {
      title: "Created At",
      dataIndex: "create_at",
      key: "create_at",
      width: "15%",
    },
  ];
  const createData = (
    transaction_id,
    merchant_info,
    shop_info,
    transfer_amount,
    delivery_charge,
    collected_amount,
    state,
    create_at
  ) => {
    return {
      transaction_id,
      merchant_info,
      shop_info,
      transfer_amount,
      delivery_charge,
      collected_amount,
      state,
      create_at,
    };
  };

  const rows = [
    data
      ? data?.transactionList?.map((transaction, id) =>
    //   console.log(transaction['Transaction Id'])
          createData(
            [
              <>
                {transaction['Transaction Id'] }<br></br>
               
              </>,
            ],
            [
              <>
              {transaction['Merchant Info'] }<br></br>
              
                
              </>,
            ],
            [
              <>
                {transaction['Shop Info'] };<br></br>
               
              </>,
            ],
            [
              <>
               {transaction['Transfer Amount'] }<br></br>
              
               
              </>,
            ],
            [
              <>
                 {transaction['Delivery Charge'] }<br></br>
              </>,
            ],
            [
              <>
                 {transaction['Collected Amount'] }<br></br>
              </>,
            ],
            [
              <>
                 {transaction['State'] }<br></br>
              </>,
            ],
            [
              <>
                {moment.utc(transaction['Created At']).format("YYYY-MM-DD hh:mm A")};
              </>,
            ],
            

          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Search List"
      subTitle="Finance Management"
      extra={[
        <Tooltip placement="topLeft">
          <strong
            style={{
              color: "white",
              backgroundColor: "#3AB569",
              padding: "5px",
            }}
          >
            {data?.count}
          </strong>
        </Tooltip>,
      ]}
    ></PageHeader>
  );
  const TABLE = (
    <>
      <Row>
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Merchant Mobile"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setMerchant(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Transaction ID"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setTransactionID(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Shop ID"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setShop(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
       
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Select
            showSearch
            style={{ width: "99%" }}
            placeholder="Transaction type"
            name="transaction"
            optionFilterProp="children"
            onChange={(e) => setTransaction(e)}
            filterOption={(input, option) =>
              option.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
            }
          >
            <Option value="">None</Option>
            <Option value="service">Payment Transaction</Option>
            <Option value="order">Order Transaction</Option>
          </Select>
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={6}>
          <RangePicker
            onChange={(e) => {
              handleDate(e);
            }}
            format="YYYY/MM/DD"
            style={{ width: "80%" }}
          />
        </Col> &nbsp; &nbsp;
        <br /><br></br>
        <Col xs={12} sm={8} md={6} lg={4} xl={4}>
        <Tooltip title="search" onClick={handleApiSearch}>
          <Button type="primary" shape="circle" icon={<SearchOutlined />} />
        </Tooltip> &nbsp; &nbsp;
        <Tooltip title="download" onClick={handleApiDownload}>
          <Button type="primary" shape="circle" icon={<DownloadOutlined />} />
        </Tooltip>
        <br />
        </Col>
        &nbsp; &nbsp;
      </Row>
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
        pagination={false}
      />
      <Pagination
        size="small"
        current={page}
        total={data?.count}
        pageSize="20"
        onChange={handlePageChange}
        pageSizeOptions={[20]}
      />
    </>
  );
  return (
    <Content>
      {Pageheader}<br></br>
      {TABLE}
    </Content>
  );
};

export default connect(null, { getReport,reportDownloader })(MerchantTransactionReport);
