import React, { useEffect } from "react";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  Row,
} from "antd";
import {
  PageHeader,
  Button,
} from "antd";
import { useState } from "react";
import { connect } from "react-redux";

import { Content } from "antd/lib/layout/layout";

import { searchDriver,payToDriver } from "../../../actions/account";

import {
  
  renderInput,

  renderSelect,
 
} from "../../../components/form";

import SelectBox from "../../../components/select";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

const banks = [
    { value: "AB Bank LTD", label: "AB Bank LTD" },
    { value: "Agrani Bank LTD", label: "Agrani Bank LTD" },
    { value: "Al-Arafah Islami Bank LTD", label: "Al-Arafah Islami Bank LTD" },
    { value: "BASIC Bank LTD", label: "BASIC Bank LTD" },
    {
      value: "BDBL (Bangladesh Development Bank LTD)",
      label: "BDBL (Bangladesh Development Bank LTD)",
    },
    { value: "BRAC Bank LTD", label: "BRAC Bank LTD" },
    {
      value: "Bangladesh Commerce Bank LTD",
      label: "Bangladesh Commerce Bank LTD",
    },
    { value: "Bank Al-Falah", label: "Bank Al-Falah" },
    { value: "Bank Asia LTD", label: "Bank Asia LTD" },
    { value: "Citibank NA", label: "Citibank NA" },
    { value: "Commercial Bank of Ceylon", label: "Commercial Bank of Ceylon" },
    { value: "Dhaka Bank LTD", label: "Dhaka Bank LTD" },
    { value: "Dutch Bangla Bank LTD", label: "Dutch Bangla Bank LTD" },
    { value: "Eastern Bank LTD", label: "Eastern Bank LTD" },
    {
      value: "Export Import Bank of Bangladesh LTD",
      label: "Export Import Bank of Bangladesh LTD",
    },
    {
      value: "First Security Islami Bank LTD",
      label: "First Security Islami Bank LTD",
    },
    { value: "HSBC", label: "HSBC" },
    { value: "Habib Bank LTD", label: "Habib Bank LTD" },
    { value: "ICB Islamic Bank LTD", label: "ICB Islamic Bank LTD" },
    { value: "IFIC Bank LTD", label: "IFIC Bank LTD" },
    {
      value: "Islami Bank Bangladesh LTD",
      label: "Islami Bank Bangladesh LTD",
    },
    { value: "Jamuna Bank LTD", label: "Jamuna Bank LTD" },
    { value: "Janata Bank LTD", label: "Janata Bank LTD" },
    { value: "Meghna Bank LTD", label: "Meghna Bank LTD" },
    { value: "Mercantile Bank LTD", label: "Mercantile Bank LTD" },
    { value: "Midland Bank LTD", label: "Midland Bank LTD" },
    { value: "Modhumoti Bank LTD", label: "Modhumoti Bank LTD" },
    { value: "Mutual Trust Bank LTD", label: "Mutual Trust Bank LTD" },
    { value: "NCC Bank LTD", label: "NCC Bank LTD" },
    { value: "NRB Bank LTD", label: "NRB Bank LTD" },
    { value: "NRB Commercial Bank LTD", label: "NRB Commercial Bank LTD" },
    { value: "NRB Global Bank LTD", label: "NRB Global Bank LTD" },
    { value: "National Bank LTD", label: "National Bank LTD" },
    { value: "National Bank of Pakistan", label: "National Bank of Pakistan" },
    { value: "One Bank LTD", label: "One Bank LTD" },
    { value: "Prime Bank LTD", label: "Prime Bank LTD" },
    { value: "Pubali Bank LTD", label: "Pubali Bank LTD" },
    { value: "Rupali Bank LTD", label: "Rupali Bank LTD" },
    { value: "Shahjalal islami Bank LTD", label: "Shahjalal islami Bank LTD" },
    { value: "Simanto Bank LTD", label: "Simanto Bank LTD" },
    { value: "Social Islami Bank LTD", label: "Social Islami Bank LTD" },
    { value: "Sonali Bank LTD", label: "Sonali Bank LTD" },
    {
      value: "South Bangla Agriculture and Commerce Bank LTD",
      label: "South Bangla Agriculture and Commerce Bank LTD",
    },
    { value: "Southeast Bank LTD", label: "Southeast Bank LTD" },
    { value: "Standard Bank LTD", label: "Standard Bank LTD" },
    { value: "Standard Chartered Bank", label: "Standard Chartered Bank" },
    { value: "State Bank of India", label: "State Bank of India" },
    { value: "The City Bank LTD", label: "The City Bank LTD" },
    { value: "The Farmers Bank LTD", label: "The Farmers Bank LTD" },
    { value: "The Premier Bank LTD", label: "The Premier Bank LTD" },
    { value: "Trust Bank LTD", label: "Trust Bank LTD" },
    { value: "Union Bank LTD", label: "Union Bank LTD" },
    {
      value: "United Commercial Bank LTD",
      label: "United Commercial Bank LTD",
    },
    { value: "Uttara Bank LTD", label: "Uttara Bank LTD" },
    { value: "Woori Bank", label: "Woori Bank" },
  ];
 
  const payment_method = [
    { value: "Mobile Banking", label: "Mobile Banking" },
    { value: "Bank Account", label: "Bank Account" },
    {value:'Cash', label:'Cash'},
  ];
  const mobile_type = [
    { value: "bKash", label: "bKash" },
    { value: "Rocket", label: "Rocket" },
    { value: "Nagad",label: "Nagad" },
  ];
  const bank_payment_option = [
    { value: "Check Voucher", label: "Check Voucher" },
    { value: "Voucher", label: "Voucher" },
  ];



const PabaleDriverDetails = ({ location, searchDriver,payToDriver }) => {
  const [data, setData] = useState([]);
  
  const [visibleC, setVisibleC] = useState(false);
  const [visibleedit, setVisibleEdit] = useState(false);
  const [remark, setRemark] = useState(false);
  const [id, setId] = useState("");
  const [visibleP, setVisibleP] = useState(false);
  const [auth, setAuth] = useState(false);
  const [Paymoney, setPayMoney] = useState(false);
  const [form] = Form.useForm();
  const [pt, setPaymentType] = useState("");
  const [bpo, setBPO] = useState("");
  const [docimg, setDocImg] = useState(false);
  const [visible, setVisible] = useState(false);
 
  const driver_ID = new URLSearchParams(location.search).get("driver");
  async function fetchData() {
    const res = await searchDriver(driver_ID);

    setData(res);
    form.setFieldsValue({
    amount:res?.account_balance
    })
   

    // console.log('1st',res);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  

  const onFinish =async (values) => {
     
    const code = await payToDriver(values,driver_ID, docimg);
    

    if (code === 201) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const handleRefresh = async () => {
    const res = await searchDriver(driver_ID);
    setData(res);
  };
  const handleOk = () => {
    setVisibleC(false);
  };
  const handleOkEdit = () => {
    setVisibleEdit(false);
  };
  const handleCancel = () => {
    setVisibleC(false);
  };
  const handleCancelEdit = () => {
    setVisibleEdit(false);
  };
  const FROM=(
      
    <Modal
    title={`Collection amount`}
    centered
    visible={Paymoney}
    width={600}
    onCancel={() => setPayMoney(false)}
    cancelText="Close"
    okButtonProps={{ style: { display: 'none' } }}
    >
       
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Divider style={{ color: "#3ab569" }}>Collecting From {data?.name}</Divider>
          {/* {renderDate("date", "Collection Date", [
            { required: true, message: "Field is required" },
          ])} */}
           <Form.Item
            label='Collection Date'
            name='date'
            rules={[{ required: true, message: "Field type is required" }]}
            
            >
            <DatePicker style={{width: '100%'}} size="large" name='date'  />
        </Form.Item>
          {renderInput("amount", "Amount to Collect", [
            { required: true, message: "field is required" },
          ])}
          {renderInput("note", "Note", [
            { required: true, message: "Field is required" },
          ])}
          <SelectBox
            name="payment_method"
            label="Payment Method"
            rules={[{ required: true, message: "payment type is required" }]}
            options={payment_method}
            placeholder="Select One"
            onChange={(e) => {
              setPaymentType(e);
            }}
          />
          {pt === "Mobile Banking" ? (
            <>
              {renderSelect(
                "mobile_bank",
                "Mobile Banking Name*",
                [{ required: true }],
                "Select One",
                mobile_type,
                "none"
              )}
              {renderInput("mobile_banking_account", "Mobile Number ", [
                { required: true, message: "Field is required" },
              ])}
              {renderInput("tx_id", "Transaction ID", [
                { required: true, message: "Field is required" },
              ])}
  
              <br></br>
            </>
          ) : null}
          {pt === "Bank Account" ? (
            <>
              <SelectBox
                name="bank_payment_option"
                label="Bank payment option"
                rules={[{ required: true, message: "Field is required" }]}
                options={bank_payment_option}
                placeholder="Select One"
                onChange={(e) => {
                  setBPO(e);
                }}
              />
  
              {bpo === "Check Voucher" ? (
                <>
                  {renderSelect(
                    "bank_name",
                    "Bank Name*",
                    [{ required: true }],
                    "Select One",
                    banks,
                    "none"
                  )}
  
                  {renderInput("branch_name", "Brance Name", [
                    { required: true, message: "Field is required" },
                  ])}
                  {renderInput("check_holder", "Check holder", [
                    { required: true, message: "field is required" },
                  ])}
                  {renderInput("issue_name", "Issuer Name ", [
                    { required: true, message: "field is required" },
                  ])}
                  {renderInput("check_number", "Check Number", [
                    { required: true, message: "field is required" },
                  ])}
                </>
              ) : null}
              {bpo === "Voucher" ? (
                <>
                  {renderSelect(
                    "bank_name",
                    "Bank Name*",
                    [{ required: true }],
                    "Select One",
                    banks,
                    "none"
                  )}
  
                  {renderInput("branch_name", "Brance Name", [
                    { required: true, message: "Field is required" },
                  ])}
                  
                </>
              ) : null}
            </>
          ) : null}
          <Form.Item label="Image of Documnet">
          <input type="file"
         id="avatar" name="doc_img"
         accept="image/png, image/jpeg" onChange={(e) => {setDocImg(e.target.files[0])}} ></input>
        </Form.Item>
  
  
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        
    </Modal>
  
   )
  const pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Driver Details"
        subTitle="Finance Management"
      ></PageHeader>
      <br></br>
    </>
  );
  const DriverDetails = (
    <Row
      gutter={16}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Col
        xs={24}
        sm={12}
        md={10}
        lg={8}
        xl={6}
        style={{ textAlign: "center" }}
      >
        <Card
          title={"Personal Details"}
          style={{
            textAlign: "center",
            backgroundColor: "#3AB569",
            color: "white",
            maxHeight:300,minHeight:300,
          }}
          bordered={false}
        >
          Name: {data?.name}
          <br></br>
          Phone No: {data?.mobile_number} <br></br>
          Permanent Address: {data?.permanent_address} <br></br>
          Present Address: {data?.present_address} <br></br>
        </Card>
      </Col><br></br>
      <Col
        xs={24}
        sm={12}
        md={10}
        lg={8}
        xl={6}
        style={{ textAlign: "center" }}
      >
        <Card
          title={"Rider Details"}
          style={{
            textAlign: "center",
            backgroundColor: "#3AB569",
            color: "white",
            display: "inlineBlock",
            maxHeight:300,minHeight:300,
          }}
          bordered={false}
        >
          <Row
            gutter={16}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {data?.authenticated ? (
                <strong style={{ marginLeft: "0px" }}>Authanticated</strong>
              ) : (
                <strong>Unauthanticated</strong>
              )}
              <br></br>
              ID: {data?.user_id}
              <br></br>
              Salary: {data?.salary}tk
              <br></br>
              Penalty: {data?.penalty}
              <br></br>
            </Col>
          </Row>
        </Card>
      </Col>
      <br></br>
      <Col
        xs={24}
        sm={12}
        md={10}
        lg={8}
        xl={6}
        style={{ textAlign: "center" }}
      >
        <Card
          title={"Rider Details"}
          style={{
            textAlign: "center",
            backgroundColor: "#3AB569",
            color: "white",
            display: "inlineBlock",
            maxHeight:300,minHeight:300,
          }}
          bordered={false}
        ><br></br>
          <Row
            gutter={16}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            
          
      }}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
             
            >
                <Button onClick={()=>{setPayMoney(true)} }>PAY</Button><br></br>
               
              Account Balance: {data?.account_balance}
              <br></br>
              {FROM}
             
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
 
  return (
    <Content>
      {pageheader}
      <br></br>
      {DriverDetails}
     
    </Content>
  );
};

export default connect(null, {
  searchDriver,payToDriver
})(PabaleDriverDetails);
