import React, { Component } from 'react';
import CheckBox from './check';
import InputBox from './input';
import SelectBox from './select';
import TextFieldBox from './textfield';
import DateBox from './date';
import FileBox from './file';

import moment from 'moment';
import PasswordBox from './PasswordBox';
import TimeBox from './Time';
import File from './file';

class CommonForm extends Component {
    state = {
        data: {},
      };
      handleChnage = (e) => {
      }
    renderInput(name,label,rules,){
        const { data } = this.state;
        return(
            <InputBox
            name={name}
            value={data[name]}
            label={label}
            rules={rules}
            onChange = {(e) => {this.handleChnage(e)} }
            />
        )
    }
    renderCheckbox(name,label,rules,){
        const { data } = this.state;
        return(
            <CheckBox
            name={name}
            value={data[name]}
            label={label}
            rules={rules}
            onChange = {(e) => {this.handleChnage(e)} }
            />
        )
    }
    renderTextFeild(name,label,rules){
        const { data } = this.state;
        return(
            <TextFieldBox
            name={name}
            value={data[name]}
            label={label}
            rules={rules}
            onChange = {(e) => {this.handleChnage(e)} }
            />
        )
    }
    renderSelect(name,label,rules,placeholder, options,mode){
        const { data } = this.state;
        return(
            <SelectBox
            name={name}
            value={data[name]}
            label={label}
            rules={rules}
            options={options}
            mode={mode}
            placeholder={placeholder}
            onChange = {(e) => {this.handleChnage(e)} }
            />
        )
    }
}
 
export default CommonForm;

export const renderInput = (name,label,rules,placeholder) => {
    return(
        <InputBox
        name={name}
        label={label}
        rules={rules}
        placeholder={placeholder}
        />
    )
}
export const renderSelect = (name,label,rules,placeholder, options,mode) => {
    return(
        <SelectBox
        name={name}
        label={label}
        rules={rules}
        options={options}
        mode={mode}
        placeholder={placeholder}
        />
    )
}

export const renderCheckbox = (name,label,rules,) => {
    return(
        <CheckBox
        name={name}
        label={label}
        rules={rules}
        />
    )
}

export const renderTextField = (name,label,rules) => {
    return(
        <TextFieldBox
        name={name}
        label={label}
        rules={rules}
        />
    )
}
export const renderPasswordField = (name,label,rules) => {
    return(
        <PasswordBox
        name={name}
        label={label}
        rules={rules}
        />
    )
}

export const renderDate = (name,label,rules, prevDate = '' )  => {

    const date= {
        name: name,
        label:label,
        rules:rules,
    }

    

    if(prevDate){
        date.defaultValue = moment(prevDate, "YYYY-MM-DD");
    }

   
    return(
        <DateBox
        {...date}
       
        />
    )
}
export const renderTime = (name,label,rules,  )  => {

    const date= {
        name: name,
        label:label,
        rules:rules,
    }


    return(
        <TimeBox
        {...date}
       
        />
    )
}
export const renderFile = (name,label,rules) => {
    return(
        <File
            name={name}
            label={label}
            rules={rules}
        />
    )
}