import {
  CheckCircleOutlined,
  EyeOutlined,DeleteOutlined,
  SearchOutlined,
  StopOutlined,PlusCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Input,
  PageHeader,
  Table,
  Col,
  Row,
  Tooltip,
  Select,
  DatePicker,
  Pagination,
  Tag,
  Popconfirm,
  Form,
  Modal,
} from "antd";

import { Content } from "antd/lib/layout/layout";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getnotice, changeNoticeAuth,RemoveNotice } from "../../actions/message";
import { renderSelect, renderTextField } from "../../components/form";
import history from "../../history";
const { RangePicker } = DatePicker;
const NoticeList = ({ changeNoticeAuth, getnotice ,RemoveNotice}) => {
  const [data, setData] = useState([]);
  const [page, setpage] = useState([1]);
  const [ID, setId] = useState([""]);
  const [title, setTitle] = useState([null]);
  const [active, setActive] = useState("");
  const [user, setUser] = useState([null]);
  const [ds, setDs] = useState([null]);
  const [de, setDe] = useState([null]);
  const [c, setC] = useState([1]);
  const [active_status, setActiveStatus] = useState([1]);
  const [form] = Form.useForm();
  const [passVisible, setPassVisible] = useState(false);
  const [remove, setRemove] = useState(false);

  const status = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];
  const tailLayout = {
    wrapperCol: { offset: 6, span: 14 },
  };
  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getnotice(
      setTitle(),
      setActive(),
      setUser(),
      setDs(),
      setDe()
    );
    
    
    form.setFieldsValue({ })

    setData(res);
    
  }, []);
  const handleOk = () => {
    setPassVisible(false);
  };
  const handleOkRemove = () => {
    setRemove(false);
  };

  const handleCancel = () => {
    setPassVisible(false);
  };
  const handleCancelRemove = () => {
    setRemove(false);
  };
  const onFinish = async (state,remark) => {
  
    const code = await changeNoticeAuth( ID,state,remark);

    if (code === 201) {
      const res = await getnotice(
       
        setTitle(),
        setActive(),
        setUser(),
        setDs(),
        setDe()
      );
      form.resetFields();
      setPassVisible(false);
      // setData(res);
    }
  };
  const onFinishRemove = async (remark) => {
  
    const code = await RemoveNotice( ID,remark);

    if (code === 201) {
      const res = await getnotice(
       
        setTitle(),
        setActive(),
        setUser(),
        setDs(),
        setDe()
      );
      form.resetFields();
      setRemove(false);
      setData(res);
    }
  };

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const handleApiSearch = async () => {
    const res = await getnotice(ID, title, active, user, ds, de);
    setC(page);
    setData(res);
  };
  const handlePageChange = async (page) => {
    const res = await getnotice(page, ID, title, active, user, ds, de);

    setData(res);
  };
  const handleDate = (date) => {
    if (date) {
      let ds = new Date(date[0]._d);
      let de = new Date(date[1]._d);
      setDs(ds.toISOString());
      setDe(de.toISOString());
    } else {
      setDs(null);
      setDe(null);
      return;
    }
  };
  const handleVisible = async (id) => {
    history.push(`/notice-details?notice=${id}`);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const onFinishFailedRemove = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "10%",
      ...searchColumns("title"),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: "15%",
      ...searchColumns("user"),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: "20%",
      ...searchColumns("message"),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      width: "10%",
    },
    {
      title: "Create At",
      dataIndex: "created_at",
      key: "created_at",
      width: "15%",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "10%",
    },
  ];
  const createData = (
    title,
    user,
    message,
    active,
    created_at,

    action
  ) => {
    return {
      title,
      user,
      message,
      active,
      created_at,
      action,
    };
  };
  const Status_Update = (
    <>
      <Modal
        title="Update"
        centered
        visible={passVisible}
        cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
        height={500}
      >
        
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            {renderSelect(
              "active",
              "Active",
              [{ required: true }],
              "Select One",
              status,
              "none"
            )}

            {renderTextField("remark", "Remark", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        
      </Modal>
    </>
  );
  const Status_Remove = (
    <>
    
      <Modal
        title="Update"
        centered
        visible={remove}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancelRemove}
        height={500}
      >
        
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinishRemove}
            onFinishFailed={onFinishFailedRemove}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            

            {renderTextField("remark", "Remark", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        
      </Modal>
    </>
  );

  const rows = [
    data
      ? data?.notice?.map((notice, id) =>
          createData(
            
            notice?.title,
            notice?.user,
            notice?.message,
            notice?.active ? (
              <Button
                size="small"
                type="danger"
                icon={<CheckCircleOutlined />}
                onClick={() => {
                  setPassVisible(true);
                  setId(notice.notice_id)
                }}
              >
                Active
              </Button>
            ) : (
              <>
                <Button
                  size="small"
                  type="primary"
                  icon={<StopOutlined />}
                  onClick={() => {
                    setPassVisible(true);
                  setId(notice.notice_id)
                  }}
                >
                  Inactive
                </Button>
              </>
            ),

            moment.utc(notice.created_at).format("YYYY-MM-DD hh:mm A"),
            <>
            <Button
              size="small"
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => {
                handleVisible(notice?.notice_id);
              }}
            >
              View
            </Button>
            <p></p>
            <Button
              size="small"
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => {
                setRemove(true);
                setId(notice.notice_id)
              }}
            >
              Remove
            </Button>
            </>
            
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Notice List"
      subTitle="Support Management"
      
      extra={[
      <Button
        key="1"
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => history.push("/create-notice")}
      >
        Notice Create
      </Button>,
        <Tooltip placement="topLeft">
          <strong
            style={{
              color: "white",
              backgroundColor: "#3AB569",
              padding: "5px",
            }}
          >
            {data?.count}
          </strong>
        </Tooltip>,
      ]}
    ></PageHeader>
  );

  const TABLE = (
    <>
      {/* <Row>
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Title"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setTitle(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="User"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => user(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Select
            showSearch
            style={{ width: "99%" }}
            placeholder="Status"
            name="user"
            optionFilterProp="children"
            onChange={(e) => active(e)}
            filterOption={(input, option) =>
              option.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
            }
          >
            <Option value="">None</Option>
            <Option value="active">Active</Option>
            <Option value="inactive">inactive</Option>
          </Select>
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={6}>
          <RangePicker
            onChange={(e) => {
              handleDate(e);
            }}
            format="YYYY/MM/DD"
            style={{ width: "80%" }}
          />
        </Col>
        <Tooltip title="search" onClick={handleApiSearch}>
          <Button type="primary" shape="circle" icon={<SearchOutlined />} />
        </Tooltip>
        <br />
        &nbsp; &nbsp;
      </Row> */}

      <Table
        hoverable={false}
        // rowClassName={(record) => record?.type == 'NO' ? 'table-row-light' :  'red'}
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
        // pagination={false}
      />
      {/* <Pagination
        size="small"
        current={c}
        total={data?.count}
        pageSize="20"
        onChange={handlePageChange}
        pageSizeOptions={[20]}
      /> */}
    </>
  );
  return (
    <Content>
      {Pageheader}
      <br></br>

      {TABLE}
      {Status_Update}
      {Status_Remove}
    </Content>
  );
};

export default connect(null, { changeNoticeAuth, getnotice,RemoveNotice })(NoticeList);
