import { Button, Form, Modal, Divider } from "antd";
import { Content } from "antd/lib/layout/layout";
import { getRoles } from "../../../actions/role";
import { getUsersById } from "../../../actions/user";
import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import {
  renderInput,
  renderPasswordField,
  renderSelect,
  renderDate,
  renderTextField,
} from "../../../components/form";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];
const status = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const EditUser = ({
  title,
  visible,
  cancel,
  ok,
  api,
  user_id,
  refresh,
  getRoles,
  getUsersById,
}) => {
  const [roles, setRoles] = useState(null);
  const [prevDate, setPrevDate] = useState("");
  const [data, setData] = useState(null);
  const [form] = Form.useForm();
 
  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getRoles();
    setRoles(res);
    if (res) {
      const result = await getUsersById(user_id);
     
      setPrevDate(result?.personal_info?.date_of_birth);
      setData(result);
      form.setFieldsValue({
        name: result?.name,
        authenticated: result?.authenticated,
        email: result?.email,
        mobile_number: result?.mobile_number,
        user_id: result?.user_id,
        //date_of_birth : ,
        emergency_number: result?.personal_info?.emergency_number,
        gender: result?.personal_info?.gender,
        permanent_address: result?.personal_info?.permanent_address,
        present_address: result?.personal_info?.present_address,
        role: result?.roles?.role_id,
      });
    }
  }, []);

  const onFinish = async (values) => {
    const code = await api(values);

    if (code === 201) {
      ok();
      form.resetFields();
      refresh();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const createRoles = (label, value) => {
    return { label, value };
  };

  let option = [];
  option = roles
    ? roles.map((item) => createRoles(item.name, item.role_id))
    : null;
  const MODAL = (
    <Modal
      title={title}
      centered
      visible={visible}
      width={800}
      onCancel={cancel}
      cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
    >
      {data ? (
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          form={form}
          autoComplete={false}
          requiredMark={false}
        >
          <Form.Item label="User ID" hidden={true} name="user_id">
            {/* <Input size="large" type="hidden"  /> */}
          </Form.Item>
          {renderInput("name", "Full Name", [
            { required: true, message: "This field is required!" },
          ])}
          {renderInput("email", "Email", [
            { required: true, message: "This field is required!" },
          ])}
          {renderInput("mobile_number", "Mobile", [
            { required: true, message: "This field is required!" },
            { pattern: /^(01)[0-9][0-9]{8}$/, message: "Please valid number!" },
          ])}

          {renderSelect(
            "authenticated",
            "Status",
            [{ required: true }],
            "Select a option",
            status,
            "none"
          )}
          {roles
            ? renderSelect(
                "role",
                "Role",
                [{ required: true }],
                "Select a option",
                option,
                "none"
              )
            : null}
          <Divider orientation="center">Personal Information</Divider>

          {renderDate(
            "date_of_birth",
            "Date of Birth",
            [{ required: true, message: "Please input your address" }],
            prevDate
          )}
          {renderSelect(
            "gender",
            "Gender",
            [{ required: true }],
            "Select Gender",
            gender,
            "none"
          )}
          {renderTextField("permanent_address", "Permanent Address", [
            { required: true, message: "This field is required!" },
          ])}
          {renderTextField("present_address", "Present Address", [
            { required: true, message: "This field is required!" },
          ])}

          {renderInput("emergency_number", "Emergency Number", [
            { required: true, message: "This field is required!" },
            {
              pattern: /^(01)[0-9][0-9]{8}$/,
              message: "Please valid phone number!",
            },
          ])}
          {renderTextField("remark", "Remark", [
            { required: true, message: "This field is required!" },
          ])}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      ) : null}
    </Modal>
  );

  return <Content>{MODAL}</Content>;
};

export default connect(null, { getRoles, getUsersById })(EditUser);
