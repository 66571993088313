import React, { useEffect } from "react";
import {
  Button,
  Descriptions,
  Breadcrumb,
  PageHeader,
  Tag,
  Empty,
  Timeline,
} from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { getContactDetails, responseDone } from "../../../actions/contact";

import {  Title, Wrapper } from "./detailStyle";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import {
  CheckOutlined,
} from "@ant-design/icons";

import Remarks from '../../../root/remark';

const ContactResponseDetails = ({
  location,
  getContactDetails,
  responseDone,
}) => {
  const [data, setData] = useState([]);
  const [review, setReview] = useState(false);
  useEffect(async () => {
    window.scrollTo(0, 0);
    const contact = new URLSearchParams(location.search).get("contact");
    const res = await getContactDetails(contact);
    setData(res);
  }, []);

  const handleRefresh = async () => {
    const res = await getContactDetails(data?.contact_id);
    setData(res);
  };

  const makeLocalTime = (date) => {
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      date.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };

  return (
    <Content>
      <Breadcrumb style={{ padding: "10px 0px" }}>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Response Details</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Response Details"
        subTitle="Support Management"
      ></PageHeader>
      <Wrapper>
        <h2>Subject : {data?.subject}</h2>
        
        <Tag color="#2db7f5">Response ID: {data?.contact_id}</Tag>
        {data?.done === true ? (
          <Tag color="#00b894">Reviewed</Tag>
        ) : (
          <Tag color="#ff7675">Not Reviewed</Tag>
        )}

        <Tag color="#cd2df5">
          {data?.created_at ? makeLocalTime(data?.created_at) : null}
        </Tag>

        {data?.done !== true ? (
          <Button
            type="ghost"
            icon={<CheckOutlined />}
            size="small"
            style={{
              float: "right",
              backgroundColor: "#26de81",
              color: "#fff",
            }}
            onClick={() => setReview(true)}
          >
            Make Done
          </Button>
        ) : null}
        <br />
        <br />
        {data ? (
          <>
            <Title>Reviewer Information</Title>
         
            <Descriptions>
              <Descriptions.Item label="NAME">
                <strong>{data?.name?.toUpperCase()}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="MOBILE">
                <strong>{data?.mobile_number}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="EMAIL">
                <strong>{data?.email}</strong>
              </Descriptions.Item>
            </Descriptions>
          
            <br />
            <br />
            <Title>Message</Title>
           
            <p>{data?.message}</p>
          
          </>
        ) : (
          <Empty />
        )}
        <br />
        <br />
        <Title>Logs</Title>
        <br />
        {data?.logs ? (
          <Timeline mode="left">
            {data.logs.map((item, index) => (
              <Timeline.Item
                label={makeLocalTime(item?.created_at)}
                keys={index}
              >
                {item?.message}
              </Timeline.Item>
            ))}
          </Timeline>
        ) : (
          <Empty />
        )}
      </Wrapper>
      <Remarks
        title=" "
        btn="Submit"
        placement="bottom"
        visible={review}
        fnc={() => setReview(false)}
        id={data?.contact_id}
        delFnc={responseDone}
        refresh={handleRefresh}
      />
    </Content>
  );
};

export default connect(null, { getContactDetails, responseDone })(
  ContactResponseDetails
);
