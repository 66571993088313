import { EyeOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Table, Tag, Card, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getShops } from "../../../actions/shop";
import history from "../../../history";
const GetShopList = ({ getShops }) => {
  const [data, setData] = useState([]);
  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getShops();
    setData(res);
   
  }, []);

  const handleVisible = async (mobile_number, shop_id) => {
   
    history.push(`/shop-details?merchant=${mobile_number}&shop_id=${shop_id}`);
    
  };

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...searchColumns("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
      ...searchColumns("email"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "15%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "MerChant",
      dataIndex: "merchant",
      key: "merchant",
      width: "15%",
      ...searchColumns("merchant"),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "20%",
      ...searchColumns("address"),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "15%",
    },
  ];
  const createData = (
    id,
    name,
    email,
    mobile_number,
    merchant,
    address,
    action
  ) => {
    return { id, name, email, mobile_number, merchant, address, action };
  };

  const rows = [
    data
      ? data?.map((Shop, id) =>
          createData(
            id + 1,
            Shop?.name,
            Shop?.email,
            Shop?.mobile_number,
            [
              <>
                {Shop?.merchant?.name},<br />
                {Shop?.merchant?.email},<br />
                {Shop?.merchant?.mobile_number},<br />
                {Shop?.merchant?.company_name},
              </>,
            ],
            Shop?.address,
            <Button
              size="small"
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => { handleVisible(Shop?.merchant?.mobile_number, Shop?.shop_id);}}
            >
              View
            </Button>
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Shop List"
      subTitle="Merchant Management"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push("/create-shop")}
        >
          Create Shop
        </Button>,
      ]}
    ></PageHeader>
  );

  return (
    <Content>
      {Pageheader}
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
    </Content>
  );
};

export default connect(null, { getShops })(GetShopList);
