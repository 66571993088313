import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Table, Tag, Card, Col, Row, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UnAuthShops } from "../../../actions/shop";
import history from "../../../history";
const UnAuthShopList = ({ UnAuthShops }) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await UnAuthShops();
    setData(res);
    // console.log(res);
  }, []);

  const handleVisible=async(mobile_number,shop_id)=>{
    //   console.log(mobile_number)
    //   console.log(shop_id)
    history.push(`/shop-details?merchant=${mobile_number}&shop_id=${shop_id}`);
  }

  

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  

  const columns = [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...searchColumns("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
      ...searchColumns("email"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "15%",
      ...searchColumns("mobile_number"),
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "20%",
      ...searchColumns("address"),
    },

    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      width: "15%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "15%",
      
    },
  ];
  const createData = (
    id,
    name,
    email,
    mobile_number,

    address,
    owner,
    action
  ) => {
    return { id, name, email, mobile_number, address, owner,action };
  };

  const rows = [
    data
      ? data?.map((unauthShop, id) =>
          createData(
            id + 1,
            unauthShop?.name,
            unauthShop?.email,
            unauthShop?.mobile_number,
            unauthShop?.address,
            [
              <>
                {unauthShop?.merchant?.name},<br />
                {unauthShop?.merchant?.mobile_number},<br />
                {unauthShop?.merchant?.company_name},<br />
                {unauthShop?.merchant?.address},
              </>,
            ],
            <Button
              size="small"
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => {handleVisible(unauthShop?.merchant?.mobile_number,unauthShop?.shop_id)}}
            >
              View
            </Button>
          )
        )
      : "",
  ];
 
  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="UnAuthShop List"
      subTitle="Merchant Management"
    ></PageHeader>


  );
  
  return (
    <Content>
      {Pageheader}
      
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
    </Content>
  );
};

export default connect(null, { UnAuthShops })(UnAuthShopList);
