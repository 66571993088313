import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import { getPayableShop } from "../../../actions/account";
import {
  Table,
  Input,
  Button,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
  Modal,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import PayableshopDetailsNew from "./PayableshopDetailsNew";

const PayableShop = ({ getPayableShop }) => {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [MerchantD, setMerchantD] = useState('');
  const [ShopD, setShopD] = useState('');

  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getPayableShop();
    setData(res);
   
  }, []);
  const handleVisible=async(mobile,id)=>{
    setMerchantD(mobile)
    setShopD(id)
    // history.push(`/payable-shop-details?merchant=${mobile}&shop_id=${id}`);
    setIsModalVisible(true)
  }

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "Shop Id",
      dataIndex: "shop_id",
      key: "shop_id",
      width: "20%",
      ...searchColumns("shop_id"),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...searchColumns("name"),
    },
    {
      title: "Merchant",
      dataIndex: "merchant",
      key: "merchant",
      width: "20%",
      ...searchColumns("merchant"),
    },
    {
      title: "Phone(Shop)",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "20%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "20%",
      ...searchColumns("address"),
    },
    {
      title: "Payable",
      dataIndex: "ac",
      key: "ac",
      width: "20%",
      ...searchColumns("ac"),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "25%",
    },
  ];
  const createData = (
    shop_id,
    name,
    merchant,
    mobile_number,
    address,
    ac,

    action
  ) => {
    return {
      shop_id,
      name,
      merchant,
      mobile_number,
      address,
      ac,

      action,
    };
  };

  const rows = [
    data
      ? data?.map((Shop) =>
          createData(
            Shop?.shop_id,
            Shop?.name,

            Shop?.merchant?.name+"\n"+
              Shop?.merchant?.mobile_number
            ,
            Shop?.mobile_number,
            Shop?.address,
            Shop?.account_balance +"TK",

            <Button
              size="small"
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => {handleVisible( Shop?.merchant?.mobile_number,Shop?.shop_id)}}
            >
              View
            </Button>
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Payable Shop List"
      subTitle="Finance Management"
    ></PageHeader>
  );

  return (
    <Content>
      {Pageheader}
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
      {isModalVisible ?
      <Modal
        style={{ top: 20 }} width={1000} 
        okButtonProps={{ style: {display: 'none'} }} 
        title="Payabale Details" visible={isModalVisible} 
        onCancel={() => setIsModalVisible(false)}
      >
        
      
        <PayableshopDetailsNew Dshopid={ShopD} Dmerchant={MerchantD}   />
      </Modal>
      :null}
    </Content>
  );
};

export default connect(null, {
  getPayableShop,
})(PayableShop);
