import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  GET_AREAS,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";

export const getAreas = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/public/admin-get-area"
    );
    if (response.status === 200) {
      dispatch({ type: GET_AREAS, payload: response.data });
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
export const getAreasForShop = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/public/area/dhaka"
    );
    // console.log(response)
    if (response.status === 200) {
      dispatch({ type: GET_AREAS, payload: response.data });
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getPrice =
  (shop_id, amount_to_collect, weight, areaCode, delivery_time, merchant) =>
  async (dispatch) => {
    let amt = amount_to_collect ? amount_to_collect : 0;
    let wt = weight ? weight : 1000;
    let dt = delivery_time ? delivery_time : "ND";
    try {
      const response = await api(localStorage.getItem("token")).get(
        `order-management/get-pricing/details?shop_id=${shop_id}&areaCode=${areaCode}&amount_to_collect=${amt}&weight=${wt}&delivery_time=${dt}&merchant=${merchant}`
      );
      return response.data;
      // dispatch({ type: FETCH_COST, payload: response.data });
    } catch (err) {
      /* =============Start========== */
      if (err?.response?.status === 422) {
  err.response.data.errors.map((item) => {
    dispatch({
      type: VALIDATION_AUTH_ERROR,
      payload: [{ message: item?.message, path: item?.path[0] } ],
    });
  });
}
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if(err.message == 'Network Error')
      {
          history.push('/networkError')
          return
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
      /* ===========End============*/
    }
  };
  export const getOngingAreaModel = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        "/area-pricing-management/ongoing-area-model"
      );
      if (response.status === 200) {
        dispatch({ type: LOADED });
        return response.data.ongoing;
      }
    } catch (err) {
      if (err?.response?.status === 422) {
  err.response.data.errors.map((item) => {
    dispatch({
      type: VALIDATION_AUTH_ERROR,
      payload: [{ message: item?.message, path: item?.path[0] } ],
    });
  });
}
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if(err.message == 'Network Error')
      {
          history.push('/networkError')
          return
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
    }
  };
  
  export const addArea = (data, name) => async (dispatch) => {
    dispatch({ type: LOADING });
    // console.log(name)
    
    // =======================================================city
    if (name === "city") {
      const obj = {
        insert: name,
        i_city: data?.city,
      };
  
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/area-pricing-management/insert-area",
          { ...obj }
        );
       
        if (response.status === 201) {
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "City successfully added..!",
          });
          dispatch({ type: LOADED });
          return response.status;
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
  
      dispatch({ type: LOADED });
    }
  
    // =======================================================Sub city
    else if (name === "sub_city") {
     
      const obj = {
        insert: 'sub_city',
        city_name: data?.city?.split('-')[1],
        i_sub_city: data?.sub_city,
      };
      // console.log(data)
 
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/area-pricing-management/insert-area",
          { ...obj }
        );
        if (response.status === 201) {
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "Sub City successfully added..!",
          });
          dispatch({ type: LOADED });
          return response.status;
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
  
      dispatch({ type: LOADED });
    }
  
    // =======================================================Thana
    else if (name === "thana") {
      const obj = {
        insert: "thana",
        city_name:data?.city?.split('-')[1],
        sub_city_name:data?.subCity?.split('-')[1],
        i_post_code: data?.post_code,
        i_thana: data?.thana,
      };
     
  
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/area-pricing-management/insert-area",
          { ...obj }
        );
        if (response.status === 201) {
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "Thana successfully added..!",
          });
          dispatch({ type: LOADED });
          return response.status;
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
    }
  
    // =======================================================Area
    else if (name === "area") {
      const obj = {
        insert: "area",
        city_name: data?.city?.split('-')[1],
        sub_city_name: data?.subCity?.split('-')[1],
        thana_name: data?.thana?.split('-')[1],
        i_delivery_type: data.OptionType,
        i_area: data?.area,
        ii_post_code: data?.ii_post_code,
      };
      
  
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/area-pricing-management/insert-area",
          { ...obj }
        );
        if (response.status === 201) {
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "Area successfully added..!",
          });
          dispatch({ type: LOADED });
          return response.status;
        }
      } catch (err) {
        /* =============Start========== */
        if (err?.response?.status === 422) {
          err.response.data.errors.map((item) => {
            dispatch({
              type: VALIDATION_ERROR,
              payload: [{ message: item.message, path: item.path[0] }],
            });
          });
        }
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
          localStorage.clear();
          history.push("/login");
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response
            ? err.response.data.message
            : history.push("/notFound"),
        });
        dispatch({ type: LOADED });
        /* ===========End============*/
      }
    }
  
    dispatch({ type: LOADED });
  };
  
  export const getAreaModel = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).get(
        "/area-pricing-management/get-area-model"
      );
      if (response.status === 200) {
        dispatch({ type: LOADED });
        return response.data.areaModel[0].city[0];
      }
     
    } catch (err) {
      if (err?.response?.status === 422) {
  err.response.data.errors.map((item) => {
    dispatch({
      type: VALIDATION_AUTH_ERROR,
      payload: [{ message: item?.message, path: item?.path[0] } ],
    });
  });
}
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if(err.message == 'Network Error')
      {
          history.push('/networkError')
          return
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
    }
  };