import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import { getRoles, updateRoleAuth, deleteRole } from "../../../actions/role";
import {
  Table,
  Input,
  Button,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import Remarks from "../../../components/remark";

const RoleList = ({ getRoles, updateRoleAuth, deleteRole }) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [palacement, setPlacement] = useState("bottom");
  const [id, setId] = useState("");
  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getRoles();

    setData(res);
  }, []);

  const showDrawer = (id) => {
    {
      setId(id)
      setVisible(true);
      
    }
  };
  const onClose = () => {
    setVisible(false);
  };

  const handleRefresh = async () => {
    const res = await getRoles();
    setData(res);
  };
  const handleStatus = async (role, active) => {
    const code = await updateRoleAuth(role, active);
    if (code === 201) {
      const res = await getRoles();
      setData(res);
    }
  };

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      ...searchColumns("name"),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      width: "20%",
      ...searchColumns("active"),
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      width: "25%",
      ...searchColumns("created_at"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const createData = (name, active, super_admin, created_at, action) => {
    return { name, active, super_admin, created_at, action };
  };
  const makeLocalTime = (date) => {
    let mainDate = date.split("T")[0];
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      mainDate + " , " + hours + ":" + minutes + " " + (am ? "a.m" : "p.m")
    );
  };
  const rows = [
    data
      ? data?.map((role) =>
          createData(
            role?.name,
            <>
              {role?.active ? (
                <>
                  <Tag color="#87d068">Active</Tag>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleStatus(role?.role_id, false)}
                  >
                    <Button size="small" type="danger" icon={<StopOutlined />}>
                      Disable
                    </Button>
                  </Popconfirm>
                </>
              ) : (
                <>
                  <Tag color="#f50">Inactive</Tag>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleStatus(role?.role_id, true)}
                  >
                    <Button
                      size="small"
                      type="primary"
                      icon={<CheckCircleOutlined />}
                    >
                      Activate
                    </Button>
                  </Popconfirm>
                </>
              )}
            </>,
            role?.super_admin,
            makeLocalTime(role?.created_at),
            <>
              <Button
                size="small"
                type="primary"
                icon={<EditOutlined />}
                onClick={() => history.push(`/edit-role?id=${role?.role_id}`)}
              >
                Edit
              </Button>{" "}
              &nbsp;
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => showDrawer(role?.role_id)}
              >
                <Button size="small" type="danger" icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
            </>
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Role List"
      subTitle="Admin Management"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<PlusCircleFilled />}
          onClick={() => history.push("/create-role")}
        >
          Create New
        </Button>,
      ]}
    ></PageHeader>
  );
  const RemarksDelete=(
    
    <Remarks
    placement={palacement}
    visible={visible}
    fnc={onClose}
    id={id}
    delFnc={deleteRole}
    data={data}
    refresh={handleRefresh}
  />
  )
  

  return (
    <Content>
      {Pageheader}
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
      {RemarksDelete}
      
     
      
    </Content>
  );
};

export default connect(null, {
  getRoles,
  updateRoleAuth,
  deleteRole,
})(RoleList);
