import {
  EyeOutlined,
  SearchOutlined,
  EditOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CheckCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  Button,
  Input,
  PageHeader,
  Table,
  Tag,
  Card,
  Col,
  Row,
  Statistic,
  Divider,
  Popconfirm,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOngingAreaModel } from "../../../actions/area";
import history from "../../../history";
const AreaModel = ({ getOngingAreaModel }) => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getOngingAreaModel();

    setData(res);
    // console.log(res);
  }, []);

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: "15%",
    },
    {
      title: "Sub City",
      dataIndex: "sub_city",
      key: "sub_city",
      width: "15%",
    },
    {
      title: "Thana",
      dataIndex: "thana",
      key: "thana",
      width: "15%",
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      width: "15%",
    },
  ];

  let rows = [];
  if (data.length != 0) {
    for (let city = 0; city < data.city.length; city++) {
      for (let sub = 0; sub < data.city[city].sub_city.length; sub++) {
        for (
          let thana = 0;
          thana < data.city[city].sub_city[sub].thana.length;
          thana++
        ) {
          for (
            let area = 0;
            area < data.city[city].sub_city[sub].thana[thana].area.length;
            area++
          ) {
            rows.push({
              city: data.city[city].name,
              sub_city: data.city[city].sub_city[sub].name,
              thana: data.city[city].sub_city[sub].thana[thana].name,
              area: data.city[city].sub_city[sub].thana[thana].area[area].name,
            });
          }
        }
      }
    }
  }

  const Pageheader = (
    <PageHeader
      autoFocus
      ghost={false}
      onBack={() => window.history.back()}
      title="Area List"
      subTitle="Area Management"
    ></PageHeader>
  );

  return (
    <Content>
      {Pageheader}
      <Divider>Current {data?.name}</Divider>

      <Table columns={columns} dataSource={rows} scroll={{ x: 1000 }} sticky />
    </Content>
  );
};

export default connect(null, {
  getOngingAreaModel,
})(AreaModel);
