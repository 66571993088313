import { Button, Form, Modal, Divider, Descriptions, Select, PageHeader } from "antd";
import { Content } from "antd/lib/layout/layout";

import { getWarehouses } from "../../../actions/warehouse";
import { createDriver } from "../../../actions/Driver";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  renderInput,
  renderSelect,
  renderDate,
  renderTextField,
  renderPasswordField,
} from "../../../components/form";
import SelectBox from "../../../components/select";
import history from "../../../history";
import { TabletFilled } from "@ant-design/icons";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const authenticate = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

const DriverCreate = ({
  createDriver,

  getWarehouses,
}) => {
  const [warehouses, setWarehouses] = useState([]);

  const [form] = Form.useForm();

  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getWarehouses();
    setWarehouses(res);
    
  }, []);

  const onFinish = async (values) => {
    const code = await createDriver(values);

    if (code === 201) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const getareasv = (label, value) => {
    return { label, value };
  };
  const createOption = (val, label) => {
    return { val, label };
  };

  let WAREHOUSES = [];
  WAREHOUSES = warehouses
    ? warehouses?.map((item) => getareasv(item.name, item.warehouse_id))
    : null;

  // let option = [];
  // option = area ? area.map((item) => getareasv(item.name, item.id)) : null;

  const FORM = (
    <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      form={form}
      autoComplete={false}
      requiredMark={false}
    >
      <Divider orientation="center">Driver Information </Divider>

      {renderInput("user_id", "Driver Id", [
        { required: true, message: "Field id required!" },
        { pattern: /^(D)[0-9]{6}$/, message: " Ex D220001 D220002" },
      ])}
      {renderInput("name", "Full Name", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("email", "Email", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("mobile_number", "Mobile Number", [
        { required: true, message: "This field is required!" },
        { pattern: /^(01)[0-9][0-9]{8}$/, message: "Please valid number!" },
      ])}
      {renderPasswordField("password", "Password", [
        { required: true, message: "This field is required!" },
        { pattern: /^[a-zA-Z\d]{8}$/, message: "Please valid password!" },
      ])}
      {renderTextField("permanent_address", "Permanent Address", [
        { required: true, message: "This field is required!" },
      ])}
      {renderTextField("present_address", "Present Address", [
        { required: true, message: "This field is required!" },
      ])}

      {renderInput("pickup_commission", "Pickup Commision", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("delivery_commission", "Delivery Commision", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("odd_commission", "ODD Commision", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("reference", "Reference", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("salary", "Salary", [
        { required: true, message: "This field is required!" },
      ])}

      {WAREHOUSES ? (
        <>
          <SelectBox
            name="warehouse_id"
            label="Working Warehouse"
            //   rules={[{ required: true, message: "This Field is required" }]}
            options={WAREHOUSES}
            placeholder="Select One"
          />
          
        </>
      ) : null}

      {renderSelect(
        "authenticated",
        "Authenticated",
        [{ required: true }],
        "Select One",
        authenticate,
        "none"
      )}

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Driver Create"
      subTitle="Delivery Management"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<TabletFilled />}
          onClick={() => history.push("/driver-list")}
        >
          Driver List
        </Button>,
      ]}
    ></PageHeader>
  );

  return <Content>{Pageheader}{FORM}</Content>;
};

export default connect(null, {
  getWarehouses,
  createDriver,
})(DriverCreate);
