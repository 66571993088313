import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";

export const sendMessage =
  ({ number, message }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    const obj = {
      number: number,
      message: message,
    };

    try {
      const response = await api(localStorage.getItem("token")).post(
        "message-management/send-message",
        { ...obj }
      );
      if (response.status === 201) {
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Message is sent succesfully",
        });
        dispatch({ type: LOADED });
        return response.status;
      }
    } catch (err) {
      /* =============Start========== */
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_AUTH_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
    }
  };
export const sendMerchantNotice =
  ({ number, message }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });
    const obj = {
      number: number,
      message: message,
    };

    try {
      const response = await api(localStorage.getItem("token")).post(
        "message-management/notification/send",
        { ...obj }
      );
      if (response.status === 201) {
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Notice is sent succesfully",
        });
        dispatch({ type: LOADED });
        return response.status;
      }
    } catch (err) {
      /* =============Start========== */
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_AUTH_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
    }
  };

export const Noticecreate = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const response = await api(localStorage.getItem("token")).post(
      "message-management/notice/create",
      { ...data }
    );
    
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Message is Create succesfully",
      });
      dispatch({ type: LOADED });
      history.push('/notice-list')
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_AUTH_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getnotice = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/message-management/notice?p&title=&active=&user=&ds=&de=`
    );
 
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_AUTH_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }

    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });

    dispatch({ type: LOADED });
  }
};
export const getnoticedetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/message-management/notice/detail?notice=${id}`
    );

    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_AUTH_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });

    dispatch({ type: LOADED });
  }
};

export const changeNoticeAuth = (notice,{active,remark} ) => async (dispatch) => {
  dispatch({ type: LOADING });
  
  try {
    const obj = {
      notice: notice,
      active: active,
      remark: remark,
    };
  

    const response = await api(localStorage.getItem("token")).post(
      `/message-management/notice/active`,
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Notice Active Succeessfully Updated",
      });
      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
export const RemoveNotice = (notice,{remark} ) => async (dispatch) => {
  dispatch({ type: LOADING });
  
  try {
    const obj = {
      notice: notice,
    
      remark: remark,
    };
    

    const response = await api(localStorage.getItem("token")).post(
      `/message-management/notice/remove`,
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Notice Remove Succeessfully",
      });
      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};


