import React,{  useEffect, useState } from "react";
import { connect } from "react-redux";
import {Route } from "react-router-dom";
import Spinner from "../components/spinner";
import history from "../history";
import CreateRole from "../pages/Admin_Management/Role/CreateRole";
import EditRole from "../pages/Admin_Management/Role/EditRole";
import RoleList from "../pages/Admin_Management/Role/RoleList";
import UserList from "../pages/Admin_Management/User/UserList";
import CreateUser from "../pages/Admin_Management/User/CreateUser";
import EditUser from "../pages/Admin_Management/User/EditModalForm";
import MessageSend from "../pages/Mesage/MessageSend";
import MerchantNoticeMBL from "../pages/Mesage/MerchantNoticeMBL";
import Dashboard from "./dashboard";
import notFound from "./notFound";
import UserDetails from "../pages/Admin_Management/User/UserDetails";
import Zone from "../pages/Zone/Zone";
import MerchantStatistic from "../pages/MerChant_Management/MerChant_Statistic/MerchantStatistic";
import GetMerchantList from "../pages/MerChant_Management/MerChant_list/MerchantList";
import GetShopList from "../pages/MerChant_Management/Shop_list/ShopList";
import UnAuthShopList from "../pages/MerChant_Management/UnauthShop_list/UnAuthShop";
import CreateMerChant from "../pages/MerChant_Management/Create_Merchant/CreateMerChant";
import MerchantDetails from "../pages/MerChant_Management/MerChant_list/MerchantDetails";
import ShopDetails from "../pages/MerChant_Management/MerChant_list/ShopDetails";
import ParcelDashboard from "../pages/Parcel/Parcel_Dashboard/ParcelDashboard";
import SearchParcel from "../pages/Parcel/Search_Parcel/SearchParcel";
import ParcelCreate from "../pages/Parcel/Parcel_Create/ParcelCreate";
import ParcelDeliver from "../pages/Parcel/OutForDelivery/ParcelDeliver";
import PaybaleDriver from "../pages/Finance/Paybale_Driver/PaybaleDriver";
import PayableShop from "../pages/Finance/PayableShops/PayableShop";
import ReceivableShop from "../pages/Finance/Receivable_Shop/ReceivableShop";
import WareHouse from "../pages/Finance/WareHouseAll/WareHouse";
import Pricing from "../pages/Area_Pricing_Management/Pricing/Pricing";
import AddPricing from "../pages/Area_Pricing_Management/Add_Price/AddPricing";
import AreaModel from "../pages/Area_Pricing_Management/Area_Model/AreaModel";
import AddModel from "../pages/Area_Pricing_Management/Add_New_Model/AddModel";
import DriverStatistic from "../pages/Delivery_Management/Driver_Statistic/DriverStatistic";
import DriverList from "../pages/Delivery_Management/Driver_List/DriverList";
import WarehouseStatistic from "../pages/Warehouse_Management/Warehouse_statistic/WarehouseStatistic";
import WarehouseList from "../pages/Warehouse_Management/Warehouse_list/WarehouseList";
import SearchMerchant from "../pages/Finance/Search_Merchant/SearchMerchant";
import SearchDriver from "../pages/Finance/Search_Driver/SearchDriver";
import ShopCreate from "../pages/MerChant_Management/Shop_Create/ShopCreate";
import DriverCreate from "../pages/Delivery_Management/Driver_Create/DriverCreate";
import BahonDetails from "../pages/Finance/bahon_details/BahonDetails";
import TotalTransaction from "../pages/Finance/Total_transaction/TotalTransaction";
import TransactionDetails from "../pages/Finance/Total_transaction/TransactionDetails";
import FailedTransaction from "../pages/Finance/Faild_Transaction/FailedTransaction";
import UnPaidVat from "../pages/Finance/Un_Paid_Vat/UnPaidVat";
import MerchantTransactionReport from "../pages/Finance/Merchant_Transaction_Report/MerchantTransactionReport";
import WarehouseCreate from "../pages/Warehouse_Management/Warehouse_Create/WarehouseCreate";
import WarehouseManagerCreate from "../pages/Warehouse_Management/Warehouse_manager_Create/WarehouseManagerCreate";
import WarehouseManagerList from "../pages/Warehouse_Management/WareHouse_Mangaer_list/WarehouseManagerList";
import WithDrawBalance from "../pages/Finance/WithDraw_Balance/WithDrawBalance";
import DepositBalance from "../pages/Finance/Deposit_Balance/DepositBalance";
import IntraTransfer from "../pages/Finance/Inter_Transfer/IntraTransfer";
import PricingDetails from "../pages/Area_Pricing_Management/Pricing/PricingDetails";
import CreateCustomParcel from "../pages/Parcel/Create_Custom_parcel/CreateCustomParcel";
import ParcelDetails from "../pages/Parcel/Search_Parcel/ParcelDetails";
import PaybaleDriverDetails from "../pages/Finance/Paybale_Driver/PaybaleDriverDetails";
import PayableShopDetails from "../pages/Finance/PayableShops/PayableShopDetails";
import WarehouseManagerDetails from "../pages/Warehouse_Management/WareHouse_Mangaer_list/WarehouseManagerDetails";

import WarehouseDetails from "../pages/Warehouse_Management/Warehouse_list/WarehouseDetails";
import DriverDetails from "../pages/Delivery_Management/Driver_List/DriverDetails";
import Map from "../pages/Delivery_Management/Driver_Map/Map";

import NoticeCreates from "../pages/Mesage/NoticeCreates";
import NoticeList from "../pages/Mesage/NoticeList";
import Noticedetails from "../pages/Mesage/Noticedetails";
// import BulkCreate from "../pages/Parcel/Bulk_Create/BulkCreate";
import BulkOrder from "../pages/Parcel/Bulk_Create/Bulk_Create";
import TicketList from "../pages/Mesage/Response_Ticket/TicketList";
import TicketDetails from "../pages/Mesage/Response_Ticket/TicketDetails";
import contactResponseList from "../pages/Mesage/Response_Contact/contactResponseList";
import contactResponseDetails from "../pages/Mesage/Response_Contact/contactResponseDetails";
import WareHouseDetails from "../pages/Finance/WareHouseAll/WareHouseDetails";
import TopMerchants from "../pages/MerChant_Management/Top_Merchants/TopMerchants";
import Loan from "../pages/Finance/Loan/Loan";
import MerchantDashboard from "../pages/Dashboard/Merchants/Merchant";
import ParcelDashboardS from "../pages/Dashboard/Parcel/ParcelDashboardS";
import WarehouseDashboard from "../pages/Dashboard/WareHouse/WarehouseDashboard";
import ParcelStatisticS from "../pages/Dashboard/Parcel/ParcelStatisticS";
import LoanDetails from "../pages/Finance/Loan/LoanDetails";
import NetworkError from "./NetworkError";


const Switcher = ({loader}) => {
    const [access, setAccess] = useState(null)  
  useEffect(() => {
    window.scrollTo(0, 0)
    if(!localStorage.getItem("token") ||
    localStorage.getItem("token") === null){
      localStorage.clear();
      history.replace("/login");
    }
    setAccess(JSON.parse(localStorage.getItem('access')))
    
  }, [])
  return (
    <>
    {loader ? <Spinner /> : null} 
      <switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/" exact component={Dashboard}/>
        <Route path="/notFound" exact component={notFound}/>
        <Route path="/networkError" exact component={NetworkError}/>
        {/* MessAGE */}
        <>
        <Route path="/createmessage"  component={MessageSend}/>
        <Route path="/merchant-message"  component={MerchantNoticeMBL}/>
        <Route path="/create-notice"  component={NoticeCreates}/>
        <Route path="/notice-list"  component={NoticeList}/>
        <Route path="/notice-details"  component={Noticedetails}/>
        <Route path="/ticket-list"  component={TicketList}/>
        <Route path="/ticket-details"  component={TicketDetails}/>
        <Route path="/response-list"  component={contactResponseList}/>
        <Route path="/response-details"  component={contactResponseDetails}/>
        </>
        {/* Role */}
        <>
        <Route path="/rolelist"  component={RoleList}/>
        <Route path="/create-role"  component={CreateRole}/>
        <Route path="/edit-role" component={EditRole}/>
        </>
        {/* Zone */}
        <>
        <Route path="/zonelist"  component={Zone}/>
        </>
        {/* parcel */}
        <>
        <Route path="/parcel-dashboard"  component={ParcelDashboard}/>
        <Route path="/search-parcel"  component={SearchParcel}/>
        <Route path="/create-parcel"  component={ParcelCreate}/>
        {/* <Route path="/create-bulk"  component={BulkCreate}/> */}
        <Route path="/create-bulk"  component={BulkOrder}/>
        <Route path="/parcel-details"  component={ParcelDetails}/>
        <Route path="/delivery-parcel"  component={ParcelDeliver}/>
        <Route path="/create-custom-parcel"  component={CreateCustomParcel}/>
        </>
        {/* User Management */}
        <>
        <Route path="/userlist"  component={UserList}/>
        <Route path="/user-details"  component={UserDetails}/>
        <Route path="/create-user"  component={CreateUser}/>
        <Route path="/edit-user" component={EditUser}/>
        </>

        {/* Finance Management */}
        <>
        <Route path="/payable-driver"  component={PaybaleDriver}/>
        <Route path="/loan-management"  component={Loan}/>
        <Route path="/loan-details"  component={LoanDetails}/>
        <Route path="/driver-details"  component={PaybaleDriverDetails}/>
        <Route path="/payable-shop"  component={PayableShop}/>
        <Route path="/payable-shop-details"  component={PayableShopDetails}/>
        <Route path="/receivable-shop"  component={ReceivableShop}/>
        <Route path="/all-warehouse"  component={WareHouse}/>
        <Route path="/warehouse-details"  component={WareHouseDetails}/>
        <Route path="/search-merchant"  component={SearchMerchant}/>
        <Route path="/search-driver"  component={SearchDriver}/>
        <Route path="/bahon-details"  component={BahonDetails}/>
        <Route path="/total-transaction"  component={TotalTransaction}/>
        <Route path="/transaction-details"  component={TransactionDetails}/>
        <Route path="/failed-transaction"  component={FailedTransaction}/>
        <Route path="/unpaid-vat"  component={UnPaidVat}/>
        <Route path="/merchant-transaction-report"  component={MerchantTransactionReport}/>
        <Route path="/withdraw-balance"  component={WithDrawBalance}/>
        <Route path="/deposit-balance"  component={DepositBalance}/>
        <Route path="/intra-transfer-balance"  component={IntraTransfer}/>
        
        </>
         {/* Area And Pricing Management */}
         <>
         <Route path="/pricie-model"  component={Pricing}/>
         <Route path="/pricing-details"  component={PricingDetails}/>
         <Route path="/pricie-add"  component={AddPricing}/>
         <Route path="/area-model"  component={AreaModel}/>
         <Route path="/add-area-model"  component={AddModel}/>
         </>
         {/* Driver Management */}
         <>
         <Route path="/driver-dashboard"  component={DriverStatistic}/>
         <Route path="/driver-list"  component={DriverList}/>
         <Route path="/get-driver"  component={DriverDetails}/>
         <Route path="/driver-map"  component={Map}/>
         <Route path="/create-driver"  component={DriverCreate}/>
         
         </>
         {/* Warehouse Management */}
         <>
         <Route path="/warehouse-dashboard"  component={WarehouseStatistic}/>
         <Route path="/warehouse-list"  component={WarehouseList}/>
         <Route path="/get-warehouse-details"  component={WarehouseDetails}/>
         <Route path="/warehouse-create"  component={WarehouseCreate}/>
         <Route path="/warehouse-manager-create"  component={WarehouseManagerCreate}/>
         <Route path="/warehouse-manager-list"  component={WarehouseManagerList}/>
         <Route path="/warehouse-manager-details"  component={WarehouseManagerDetails}/>
         
         </>
        {/* marchent */}
        <>
        <Route path="/merchant-dashboard"  component={MerchantStatistic}/>
        <Route path="/merchant-list"  component={GetMerchantList}/>
        <Route path="/top-merchants"  component={TopMerchants}/>
        <Route path="/merchant-details"  component={MerchantDetails}/>
        <Route path="/shop-list"  component={GetShopList}/>
        <Route path="/shop-details"  component={ShopDetails}/>
        <Route path="/unauthshop-list"  component={UnAuthShopList}/>
        <Route path="/create-shop"  component={ShopCreate}/>
        
        <Route path="/create-merchant"  component={CreateMerChant}/>
        </>
        {/* {Dashboard} */}
        <>
        <Route path="/merchant"  component={MerchantDashboard}/>
        <Route path="/parcel"  component={ParcelDashboardS}/>
        <Route path="/warehouse"  component={WarehouseDashboard}/>
        <Route path="/warehouse-s"  component={ParcelStatisticS}/>
        
        </>
      </switch>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loader.loading,
    success: state.message.success,
    error: state.message.error,
    validationError: state.message.validationError,
  };
};
export default connect(mapStateToProps)(Switcher);
