import { Button, Form, Modal, Divider } from "antd";
import { Content } from "antd/lib/layout/layout";
import { getRoles } from "../../../actions/role";
import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import {
  renderInput,
  renderPasswordField,
  renderSelect,
  renderDate,
  renderTextField,
} from "../../../components/form";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];
const status = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const CreateUser = ({ title, visible, cancel,refresh, ok, api ,getRoles}) => {
  const [roles, setRoles] = useState(null)
  const [form] = Form.useForm();
  useEffect(async() => {
    window.scrollTo(0, 0)
    const res = await getRoles();
   
    setRoles(res)
  }, [])
 
  const onFinish = async (values) => {
    const code = await api(values);
  
    if (code === 200) {
      ok();
      form.resetFields();
      refresh()
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };


  const createRoles = (label,value) => {
    return {label,value}
  }

  let option = [];
      option = (
        roles ? roles?.map(item => 
          createRoles(item.name , item.role_id ))
      : null)
  const MODAL = (
    <Modal
      title={title}
      centered
      visible={visible}
      width={800}
      onCancel={cancel}
      cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Form
        {...layout}
        name="basic"
        
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        form={form}
        autoComplete={false}
        requiredMark={false}
      >
        {renderInput("user_id", "User ID", [
          { required: true, message: "This field is required!" },
        ])}
        {renderInput("name", "Full Name", [
          { required: true, message: "This field is required!" },
        ])}
        {renderInput("email", "Email", [
          { required: true, message: "This field is required!" },
        ])}
        {renderInput("mobile_number", "Mobile", [
          { required: true, message: "This field is required!" },
          { pattern: /^(01)[0-9][0-9]{8}$/, message: "Please valid number!" },
        ])}
        {renderPasswordField("password", "Password", [
          { required: true, message: "This field is required!" },
          {
            pattern: /^[A-Za-z\d@$!%*?&]{8,50}$/,
            message:
              "Minimum 8 character, allowed special characters are @$!%*?&",
          },
        ])}
        {renderSelect(
          "authenticated",
          "Status",
          [{ required: true }],
          "Select a option",
          status,
          "none"
        )}
        {renderSelect(
          "role",
          "Role",
          [{ required: true }],
          "Select a option",
          option,
          "none"
        )}
        <Divider orientation="center">Personal Information</Divider>

        {renderDate("date_of_birth", "Date of Birth", [
          { required: true, message: "Please input your address" },
        ])}
        {renderSelect(
          "gender",
          "Gender",
          [{ required: true }],
          "Select Gender",
          gender,
          "none"
        )}
        {renderTextField("permanent_address", "Permanent Address", [
          { required: true, message: "This field is required!" },
        ])}
        {renderTextField("present_address", "Present Address", [
          { required: true, message: "This field is required!" },
        ])}

        {renderInput("emergency_number", "Emergency Number", [
          { required: true, message: "This field is required!" },
          {
            pattern: /^(01)[0-9][0-9]{8}$/,
            message: "Please valid phone number!",
          },
        ])}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  
  return(
    <Content>
    {MODAL}
    </Content>
  );
};

export default connect(null,{getRoles})(CreateUser);
