import React, { useEffect } from "react";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Row,
} from "antd";
import {
  Descriptions,
  Breadcrumb,
  PageHeader,
  Tag,
  Empty,
  Timeline,
  Button,
} from "antd";
import { useState } from "react";
import { connect } from "react-redux";

import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";

import {
  CheckCircleOutlined,
  CheckSquareFilled,
  EditOutlined,
  EyeOutlined,
  KeyOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { getnoticedetails } from "../../actions/message";

import {
    renderDate,
  renderInput,
  renderPasswordField,
  renderSelect,
  renderTextField,
} from "../../components/form";
import { Title, Wrapper } from "../../Style";
import moment from "moment";
import history from "../../history";
import SelectBox from "../../components/select";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};



  const active = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  const authenticated = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  
const NoticeDetails = ({ location, getnoticedetails,payToDriver }) => {
  const [data, setData] = useState([]);
  
  const notice_id = new URLSearchParams(location.search).get("notice");
  async function fetchData() {
    const res = await getnoticedetails(notice_id);

    setData(res);
  
   

    // console.log('1st',res);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  

  
  const pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Notice Details"
        subTitle="Support Management"
      ></PageHeader>
      <br></br>
    </>
  );
  const DriverDetails = (
      
      <>
      <Wrapper>
        {data?.notice ? (
          <>
            <Row gutter={16}>
              <Col xs={24} lg={24} sm={24} md={24}>
              <Card hoverable>
                <>
                  <Title>Notice Information </Title>
                  <br />
                  <Descriptions>
                    <Descriptions.Item label="ID">
                      <strong>{data?.notice?.notice_id}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Title">
                      <strong>{data?.notice?.title}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="User">
                      <strong>{data?.notice?.user}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                    {data?.notice?.active ? <strong>Active</strong>:<strong>Inactive</strong>}
                    </Descriptions.Item>
                    <Descriptions.Item label="Create At">
                     <strong>{moment.utc(data?.notice?.created_at).format("YYYY-MM-DD hh:mm A")}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Message">
                      <strong>{data?.notice?.message}</strong>
                    </Descriptions.Item>

                    <br />
                  </Descriptions>
                </>
                </Card>
              </Col>
              
                    </Row>
                   
            
          </>
        ) : null}
        <br></br>

        {data?.notice?.logs ? (
          <>
            <Title>Logs </Title>
            <br />
            {data?.notice?.logs ? (
              <Timeline mode="left">
                {data?.notice?.logs.map((item, index) => (
                  <Timeline.Item
                    label={moment
                      .utc(item?.created_at)
                      .format("YYYY-MM-DD hh:mm A")}
                    keys={index}
                  >
                    {item?.message}
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <Empty />
            )}
          </>
        ) : null}
        <br />
      </Wrapper>
    </>
  );
 
  return (
    <Content>
      {pageheader}
      <br></br>
      {DriverDetails}
     
    </Content>
  );
};

export default connect(null, {
  getnoticedetails
})(NoticeDetails);
