import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
  GET_PRICING_MODEL,
} from "../reducers/type";


export const getReport = (page,transaction_id,transaction,merchant,shop,type,startDate,endDate) => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
      
     
      const response = await api(localStorage.getItem("token")).get(`finance-management/merchant/transaction?p=${page ? page : 1}&transaction_id=${transaction_id ? transaction_id : ''}&transaction=${transaction ? transaction : ''}&merchant=${merchant ? merchant : ''}&shop=${shop ? shop : ''}&type=${type ? type: ''}&ds=${startDate?startDate:''}&de=${endDate?endDate:''}`);
      
      if (response.status === 200) {
        dispatch({ type: LOADED });
        return response.data;
      }
    }
    catch( err ){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  }
  
  export const reportDownloader = (page,transaction_id,transaction,merchant,shop,type,startDate,endDate) => async (dispatch) => {
    dispatch({ type: LOADING });
    
    try{
        const response = await api(localStorage.getItem("token")).get(`finance-management/merchant/transaction?p=${page ? page : 1}&transaction_id=${transaction_id ? transaction_id : ''}&transaction=${transaction ? transaction : ''}&merchant=${merchant ? merchant : ''}&shop=${shop ? shop : ''}&type=${type ? type: ''}&ds=${startDate?startDate:''}&de=${endDate?endDate:''}`,
          {
            headers: {
              'authorization': `Bearer ${localStorage.getItem("token")}`
              },
              method: 'GET',
              responseType: 'blob',
            }
          );
          
      if(response.status === 200 ) {
        // FileDown
        dispatch({ type: LOADED });
        const url = window.URL.createObjectURL(new Blob([response.data]),);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `FinanceReport.xlsx`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    }
    catch( err ){
        if(err?.response?.status === 451)
          {
            history.push('/pending-verification')
          }
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          
          if(err.message == 'Network Error')
          {
              history.push('/notFound')
              return
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  }