import { Breadcrumb, Button, Drawer, Form, Input, PageHeader } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { sendMerchantNotice } from "../../actions/message";
const { TextArea } = Input;

const MerchantNoticeMBL = ({ location, sendMerchantNotice }) => {
  const [data, setData] = useState([]);
 

  const [form] = Form.useForm();
  useEffect(async () => {
    window.scrollTo(0, 0)
    const token = new URLSearchParams(location.search).get("token");
    setData(token);
  }, []);

  
  const onFinish = async (values) => {
    const code = await sendMerchantNotice(values);
    
    if (code === 201) {
      form.resetFields();
      
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Merchant Notice"
      subTitle="Support Management"
    ></PageHeader>
  );

  const ContentButton = (
    <>
      
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            label="Merchant Number"
            name="number"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
              {
                pattern: /^(01)[0-9]{1}[0-9]{8}$/,
                message: "Valid Phone number",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Notice"
            name="message"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <TextArea />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 12,
              span: 12,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{ textAlign: "center" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
     

      
    </>
  );

  return (
    <Content>
      {Pageheader}
      <br></br>
      {ContentButton}
    </Content>
  );
};

export default connect(null, { sendMerchantNotice })(MerchantNoticeMBL);
