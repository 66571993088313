import { Button, Form, Modal, Divider, Descriptions, Select, Input, PageHeader, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";
import {
    createWarehouse,
  
} from "../../../actions/warehouse";
import { getAreas, getPrice } from "../../../actions/area";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  renderInput,
  renderSelect,
  renderDate,
  renderTextField,
} from "../../../components/form";
import SelectBox from "../../../components/select";
const { Option } = Select;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const status = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
  
];


const product = [
  { value: "Parcel", label: "Parcel" },
 
];
const weights = [
  { value: ".5", label: "0-0.5" },
  { value: "1", label: "0.5-1" },
  { value: "2", label: "1-2" },
  { value: "3", label: "2-3" },
  { value: "4", label: "3-4" },
  { value: "5", label: "4-5" },
  { value: "6", label: "5-6" },
  { value: "7", label: "6-7" },
  { value: "8", label: "7-8" },
  { value: "9", label: "8-9" },
  { value: "10", label: "9-10" },
  { value: "11", label: "10-11" },
  { value: "12", label: "11-12" },
  { value: "13", label: "12-13" },
  { value: "14", label: "13-14" },
  { value: "15", label: "14-15" },
];
const WarehouseCreate = ({
  location,
  createWarehouse,
  getAreas,
  
  getPrice,
}) => {
  const [parcelorder, setParcelOrder] = useState("");

  const [getmerchant, setGetMerchant] = useState(null);
  const [merchants, setMerchants] = useState(null);
  const [data, setData] = useState(null);
  const [shop_id, setShopId] = useState(null);
  const [amount_to_collect, setammountOfCollect] = useState(null);
  const [areaCode, setAreaCode] = useState(null);
  const [delivery_type, setDeliveryType] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [number, setNumber] = useState(null);
  const [shopsdata, setShopsData] = useState(null);

  const [coverageArea, setCoverageArea] = useState(null);

  const [city, setCity] = useState("");
  const [subCity, setSubCity] = useState("");
  const [thana, setThana] = useState("");
  const [area, setArea] = useState("");
  const [cityArr, setCityArr] = useState(null);
  const [subCityArr, setsubCityArr] = useState(null);
  const [thanaArr, setThanaArr] = useState(null);
  const [areaArr, setAreaArr] = useState(null);

  const [form] = Form.useForm();

  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getAreas();
    setCoverageArea(res)
    
  }, []);



  const city_ = async (event) => {
    const cityCode = event;
    if (cityCode === "-1") {
      return;
    }

    const subCity = [...coverageArea?.city[cityCode - 1]?.sub_city];
    setCity(cityCode);
    setsubCityArr(subCity);
    setSubCity("");
    setThana("");
    setArea("");
    setThanaArr(null);
    setAreaArr(null);
    form.setFieldsValue({
      subCity: '',
      thana: '',
      area: '',
     
  });
    // form.resetFields(
    //   setSubCity(""),
    //   setThana(""),
    //   setArea(""),
    //   setThanaArr(null),
    //   setAreaArr(null)
    // );
  };

  const sub_city = async (event) => {
    const subCode = event;
    if (subCode === "-1") {
      return;
    }
    const thana = [...subCityArr[subCode - 1].thana];

    setSubCity(subCode);
    setThana("");
    setArea("");
    setThanaArr(thana);
    form.setFieldsValue({
    
      thana: '',
      area: '',
     
  });
    // setAreaArr(null);
    // form.resetFields(setThana(""), setArea(""), setAreaArr(null));
    // form.setFieldsValue(setThana(""), setArea(""), setAreaArr(null));
  };

  const thana_ = async (event) => {
    const thanaCode = event;
    if (thanaCode === "-1") {
      return;
    }
    const area = [...thanaArr[thanaCode - 1].area];

    setThana(thanaCode);
    setAreaArr(area);
    setArea("");
    form.setFieldsValue({
      
      area: '',
     
  });
    // form.setFieldsValue( setArea(""));
    // form.resetFields( setArea(""));
  };

  const area_ = async (event) => {
    const areaCode = event;
    if (areaCode === "-1") {
      return;
    }
    setArea(areaCode);
    // form.resetFields();
  };


  // ========== Cities =========
  // console.log(coverageArea);
  const c_o =
    coverageArea != null
      ? coverageArea?.city?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Sub cities ===========
  const s_o =
    subCityArr !== null
      ? subCityArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item?.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Thana ===========
  const t_o =
    thanaArr !== null
      ? thanaArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item?.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;
  // ========== Area ===========
  const a_o =
    areaArr !== null
      ? areaArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item?.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;


  const onFinish = async (values) => {
    const code = await createWarehouse(values);

    if (code === 201) {
      form.resetFields();
    }
  };


  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const getareasv = (label, value) => {
    return { label, value };
  };
 
  const createOption = (val, label) => {
    return { val, label };
  };

  let SHOPS = [];
  SHOPS = merchants
    ? merchants?.map((item) => getareasv(item.name, item.shop_id))
    : null;
    

  // let option = [];
  // option = area ? area.map((item) => getareasv(item.name, item.id)) : null;
 
  const FORM = (
    <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      form={form}
      autoComplete={false}
      requiredMark={false}
    >
        <Divider orientation="center">Warehouse Details </Divider>
        {renderInput("warehouse_id", "Warehouse ID", [
        { required: true, message: "This field is Required", },
        { pattern: /^(WH)[0-9]{5}$/, message: " Ex WH20001 WH20002" },
       
      ],)}
      {renderInput("warehouse_name", "Warehouse Name", [
        { required: true, message: "This field is required!" },
        
      ])}
      {renderTextField("warehouse_address", "Warehouse Addrerss", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("mobile_number", "Mobile Number", [
        { required: true, message: "This field is required!" },
        { pattern: /^(01)[0-9][0-9]{8}$/, message: "Please valid number!" },
      ])}
      
      <Divider orientation="center">Area Details </Divider>
  
{/* ================ City =============== */}

<Form.Item
  name="city"
  label={"Select City"}
  rules={[
    {
      required: true,
      message: "City  is required",
    },
  ]}
>
  <Select showSearch
    placeholder={"Select one city"}
    onChange={(e) => city_(e)}
  >
    {/* {options} */}
    {c_o}
  </Select>
</Form.Item>
{/* ================ Sub City ============ */}
{subCityArr ? (
  <Form.Item
    name="subCity"
    label={"Select Subcity"}
    rules={[
      {
        required: true,
        message: "Subcity is required",
      },
    ]}
  >
    <Select
      placeholder={"Select one subcity"}
      onChange={(e) => sub_city(e)}
    >
      {/* {options} */}
      {s_o}
    </Select>
  </Form.Item>
) : null}

{/* ============== Thana ============== */}
{thanaArr ? (
  <Form.Item
    name="thana"
    label={"Select one thana"}
    rules={[
      {
        required: true,
        message: "Thana is required",
      },
    ]}
  >
    <Select showSearch
      placeholder={"Select one subcity"}
      onChange={(e) => thana_(e)}
    >
      {/* {options} */}
      {t_o}
    </Select>
  </Form.Item>
) : null}
{/* ============== Area ============= */}
{areaArr ? (
  <Form.Item
    name="area"
    label={"Select one area"}
    rules={[
      {
        required: true,
        message: "Area is required",
      },
    ]}
  >
    <Select showSearch
      placeholder={"Select one area"}
      onChange={(e) => area_(e)}
    >
      {/* {options} */}
      {a_o}
    </Select>
  </Form.Item>
) : null}
{renderSelect(
        "status",
        "Status",
        [{ required: true }],
        "Select a option",
        status,
        "none"
      )}

     
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Warehouse Create"
      subTitle="Warehouse Management"
      extra={[
       
      ]}
    ></PageHeader>
  );
  return <Content>{Pageheader}{FORM}</Content>;
};

export default connect(null, {
  createWarehouse,
  getAreas,
  getPrice,
})(WarehouseCreate);
