import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import { getDrivers,updateDriverAuth,getDriverById } from "../../../actions/Driver";
import {
  Table,
  Input,
  Button,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";

const DriverList = ({ getDrivers,updateDriverAuth,getDriverById }) => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getDrivers();
    setData(res);
    
   
    
  }, []);
 
    const handleVisible=async(id)=>{
      history.push(`/get-driver?user_id=${id}`);
    }
  
  const handleDriverAuth = async (id, status, remarks) => {
    const code = await updateDriverAuth(id, status, remarks);
    if (code ===201 ) {
      const res = await getDrivers();
      setData(res);
    }
  };
  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "Driver Id",
      dataIndex: "user_id",
      key: "user_id",
      width: "10%",
      ...searchColumns("user_id"),
    },
    {
      title: "Driver Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...searchColumns("name"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "10%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
      ...searchColumns("email"),
    },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
      width: "10%",
      ...searchColumns("salary"),
    },
    {
      title: "Address",
      dataIndex: "present_address",
      key: "present_address",
      width: "20%",
      ...searchColumns("present_address"),
    },
    {
      title: "Status",
      dataIndex: "authenticated",
      key: "authenticated",
      width: "20%",
      
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "15%",
      
    },
  ];
  const createData = (user_id,name, salary, mobile_number,email, present_address, authenticated,action) => {
    return { user_id,name, salary, mobile_number, present_address,email, authenticated,action };
  };

  const rows = [
    data
      ? data?.map((driver) =>
          createData(
            driver?.user_id,
            driver?.name,
            driver?.salary,
            driver?.mobile_number,
            driver?.email,
            
            driver?.present_address,
            <>
            <strong>
                {driver?.authenticated ? (
                  <>
                    <Popconfirm
                      title="Are you sure？"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() =>
                        handleDriverAuth( driver?.user_id, false,"")
                      }
                    >
                      <Button
                        size="small"
                        type="primary"
                        icon={<CheckCircleOutlined />}
                      >
                        Authenticate
                      </Button>
                    </Popconfirm>
                  </>
                ) : (
                  <>
                    <Popconfirm
                      title="Are you sure？"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() =>
                        handleDriverAuth( driver?.user_id, true,"")
                      }
                    >
                      <Button
                        size="small"
                        type="danger"
                        icon={<StopOutlined />}
                      >
                        Unauthenticate
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </strong>
              </>,
            
            <Button
              size="small"
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => {handleVisible(driver?.user_id) }}
            >
              view
            </Button>

            
            
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Driver List"
      subTitle="Delivery Management"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => history.push("/create-driver")}
        >
          Driver Create
        </Button>,
      ]}
    ></PageHeader>
  );

  return (
    <Content>
      {Pageheader}
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
    </Content>
  );
};

export default connect(null, {
  getDrivers,updateDriverAuth,getDriverById
})(DriverList);
