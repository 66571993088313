import { Button, Form, Modal, Divider, Descriptions, Select, Input, PageHeader, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";
import {
    getWarehouses,
    createWarehouseManager
  
} from "../../../actions/warehouse";
import { getAreas, getPrice } from "../../../actions/area";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  renderInput,
  renderSelect,
  renderDate,
  renderTextField,
  renderPasswordField,
} from "../../../components/form";
import SelectBox from "../../../components/select";
const { Option } = Select;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const status = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
  
];


const product = [
  { value: "Parcel", label: "Parcel" },
 
];
const weights = [
  { value: ".5", label: "0-0.5" },
  { value: "1", label: "0.5-1" },
  { value: "2", label: "1-2" },
  { value: "3", label: "2-3" },
  { value: "4", label: "3-4" },
  { value: "5", label: "4-5" },
  { value: "6", label: "5-6" },
  { value: "7", label: "6-7" },
  { value: "8", label: "7-8" },
  { value: "9", label: "8-9" },
  { value: "10", label: "9-10" },
  { value: "11", label: "10-11" },
  { value: "12", label: "11-12" },
  { value: "13", label: "12-13" },
  { value: "14", label: "13-14" },
  { value: "15", label: "14-15" },
];

const WarehouseManagerCreate = ({
    getWarehouses,
    createWarehouseManager
}) => {
  const [parcelorder, setParcelOrder] = useState("");

  const [getmerchant, setGetMerchant] = useState(null);
  const [merchants, setMerchants] = useState(null);
  const [data, setData] = useState(null);
  const [shop_id, setShopId] = useState(null);
  const [amount_to_collect, setammountOfCollect] = useState(null);
  const [areaCode, setAreaCode] = useState(null);
  const [delivery_type, setDeliveryType] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [number, setNumber] = useState(null);
  const [shopsdata, setShopsData] = useState(null);

  const [coverageArea, setCoverageArea] = useState(null);

  const [city, setCity] = useState("");
  const [subCity, setSubCity] = useState("");
  const [thana, setThana] = useState("");
  const [area, setArea] = useState("");
  const [cityArr, setCityArr] = useState(null);
  const [subCityArr, setsubCityArr] = useState(null);
  const [thanaArr, setThanaArr] = useState(null);
  const [areaArr, setAreaArr] = useState(null);

  const [form] = Form.useForm();

  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getWarehouses();
    setCoverageArea(res)
   
    
  }, []);




  const onFinish = async (values) => {
    const code = await createWarehouseManager(values);

    if (code === 201) {
      form.resetFields();
    }
  };


  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const getareasv = (label, value) => {
    return { label, value };
  };
 
  const createOption = (val, label) => {
    return { val, label };
  };

  let SHOPS = [];
  SHOPS = coverageArea
    ? coverageArea?.map((item) => getareasv(item.name, item.warehouse_id))
    : null;
    

  // let option = [];
  // option = area ? area.map((item) => getareasv(item.name, item.id)) : null;
 
  const FORM = (
    <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      form={form}
      autoComplete={false}
      requiredMark={false}
    >
        <Divider orientation="center">Warehouse Manager Details </Divider>
        {renderInput("user_id", "Warehouse Manager ID", [
        { required: true, message: "this field is Required", },
        { pattern: /^(WHM)[0-9]{5}$/, message: " Ex WHM20001 WHM20002" },
       
      ],)}
      {renderInput("name", "Warehouse Manager Name", [
        { required: true, message: "This field is required!" },
        
      ])}
      {renderInput("email", "Warehouse Manager Email", [
        { required: true, message: "This field is required!" },
        
      ])}
       {renderInput("mobile_number", "Mobile Number", [
        { required: true, message: "This field is required!" },
        { pattern: /^(01)[0-9][0-9]{8}$/, message: "Please valid number!" },
      ])}
      {renderPasswordField("password", "Password", [
        { required: true, message: "This field is required!" },
        { pattern: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' }
      ])}
       {renderTextField("present_address", "Present Addrerss", [
        { required: true, message: "This field is required!" },
      ])}
       {renderTextField("permanent_address", "Permanent Addrerss", [
        { required: true, message: "This field is required!" },
      ])}
     
     {SHOPS ? (
          <>
            <SelectBox
              name="warehouse"
              label="Warehouse Name"
              //   rules={[{ required: true, message: "This Field is required" }]}
              options={SHOPS}
              placeholder="Select One"
            />
           
          </>
        ) : null}

{renderSelect(
        "authenticated",
        "authenticated",
        [{ required: true }],
        "Select a option",
        status,
        "none"
      )}

     
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Warehouse Manager Create"
      subTitle="Warehouse Management"
      extra={[
       
      ]}
    ></PageHeader>
  );
  return <Content>{Pageheader}{FORM}</Content>;
};

export default connect(null, {
    getWarehouses,
    createWarehouseManager
})(WarehouseManagerCreate);
