import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Table, Tag, Card, Col, Row, Divider } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RecentMerchants, MerchatStatistic,TopTenMerchants } from "../../../actions/merchant";
import { RecentShops, ShopStatistics } from "../../../actions/shop";
import { Column, Line } from "@ant-design/plots";


const MerchantDashboard = ({
  RecentMerchants,
  MerchatStatistic,
  RecentShops,
  ShopStatistics,
  TopTenMerchants
}) => {
  const [recentmer, setRecentMer] = useState([]);
  const [datashop, setDataShop] = useState([]);
  const [merchantStatistic, setMerchantStatistic] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [shopStatistic, setShopStatistic] = useState([]);
  const [data3,setData3]=useState([]);
  const [TTM,setTopTenMerChant]=useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0)
    const resmerchantr = await RecentMerchants();
    const resshopp = await RecentShops();
    const resmerstatistic = await MerchatStatistic();
    const resshopstatistic = await ShopStatistics();
    const TTMerchant = await TopTenMerchants();
    setTopTenMerChant(TTMerchant)
    

    setRecentMer(resmerchantr);
    setDataShop(resshopp);
    setData1(resmerstatistic?.lastYearSummery);
    setMerchantStatistic(resmerstatistic?.merchantCount[0]);

    setData2(resshopstatistic?.lastYearSummery);
    setShopStatistic(resshopstatistic?.shopCount[0]);

    let lintChartData = []

    for (let i=0; i < resmerstatistic?.lastYearSummery?.length ; i++ ){

      lintChartData.push({
        name: "Merchant",
        count: resmerstatistic?.lastYearSummery[i]?.total,
        date: resmerstatistic?.lastYearSummery[i]?._id
      })

    }

    for (let i=0; i < resshopstatistic?.lastYearSummery?.length ; i++ ){

      lintChartData.push({
        name: "Shop",
        count: resshopstatistic?.lastYearSummery[i].total,
        date: resshopstatistic?.lastYearSummery[i]._id
      })

    }

    setData3(lintChartData);



  
  }, []);

  

  
  

  

  

  

  

  const config = {
    data:data1,
    xField: "_id",
    yField: "total",
    title:{
      visible:true,
      position:"center",
      text:'Recent Merchant Registration'
    },
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle',

      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      total: {
        alias: "merchant",
      },
      _id: {
        alias: "date",
      },
    },
  };

  const config1 = {
    data:data2,
    title:{
      visible:true,
      position:"center",
      text:'Recent Merchant Registration'
    },
    xField: "_id",
    yField: "total",
    title:{
      visible:true,
      text:'Recent Shop Registration'
    },
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle',

      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      total: {
        alias: "Shop",
      },
      _id: {
        alias: "date",
      },
    },
  };
  const Recentshop = (
    <Row gutter={16}>
      <Col xs={12} lg={12} md={24} sm={24} style={{ textAlign: "center" }}>
       <Divider>Merchant</Divider>
        <Column {...config} />
      </Col>
      <Col xs={12} lg={12} md={24} sm={24} style={{ textAlign: "center" }}>
      <Divider>Shop</Divider>
        <Column {...config1} />
      </Col>
    </Row>
  );

  
  const config2 = {
    data : data3,
    xField: 'date',
    yField: 'count',
    seriesField: 'name',
    yAxis: {
      label: {
        formatter: (v) => `${v} `,
      },
    },
    legend: {
      position: 'top',
    },
    smooth: false,
   
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };
  const Main_chart=(
    <>
    
    <Line {...config2} /><br></br> 
    </>
  )
 

  return (
    <Content>
  
      
     
     
      {Main_chart}
      
   
     
      
    </Content>
  );
};

export default connect(null, {
  RecentMerchants,
  RecentShops,
  MerchatStatistic,
  ShopStatistics,
  TopTenMerchants
})(MerchantDashboard);
