import { Breadcrumb, Button, Drawer, Form, Input, PageHeader } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { sendMessage } from "../../actions/message";
const { TextArea } = Input;

const MessageSend = ({ location, sendMessage }) => {
  const [data, setData] = useState([]);
  const [visiblephone, setVisiblePhone] = useState(false);
  const [visiblemarchant, setVisibleMarchant] = useState(false);

  const [form] = Form.useForm();
  useEffect(async () => {
    window.scrollTo(0, 0)
    const token = new URLSearchParams(location.search).get("token");
    setData(token);
  }, []);

  const onClose = () => {
    setVisiblePhone(false);
    setVisibleMarchant(false);
  };
  const onFinish = async (values) => {
    const code = await sendMessage(values);
    
    if (code === 201) {
      form.resetFields();
      setVisiblePhone(false);
      setVisibleMarchant(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Message Create"
      subTitle="Support Management"
    ></PageHeader>
  );

  const ContentButton = (
    <>
      <Button
        type="dashed"
        onClick={() => setVisiblePhone(true)}
        size="large"
        style={{ margin: "20px" }}
      >
        Individual Number
      </Button>

      <Button
        type="dashed"
        size="large"
        onClick={() => setVisibleMarchant(true)}
        style={{ margin: "20px", position: "absolute", right: " 15px" }}
      >
        Every Marchant Number
      </Button>

      <Drawer
        title="SEND TO PHONE"
        placement="bottom"
        width={500}
        onClose={onClose}
        visible={visiblephone}
      >
        <Form
          name="basic"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            label="Phone Number"
            name="number"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
              {
                pattern: /^(01)[0-9]{1}[0-9]{8}$/,
                message: "Valid Phone number",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Message"
            name="message"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <TextArea />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 12,
              span: 12,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{ textAlign: "center" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>

      <Drawer
        title="SEND TO ALL MARCHANT"
        placement="bottom"
        width={500}
        onClose={onClose}
        visible={visiblemarchant}
      >
        <Form
          name="basic"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          {/* <Form.Item
        label="Phone Number"
        name="number"
        rules={[
          {
            required: true,
            message: "This field is required!",
          },
        ]}
      >
        <Input type="string" />
      </Form.Item> */}

          <Form.Item
            label="Message"
            name="message"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <TextArea />
          </Form.Item>
          
          <Form.Item
            wrapperCol={{
              offset: 12,
              span: 12,
            }}
          >
           
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
           
          </Form.Item>
       
        </Form>
      </Drawer>
    </>
  );

  return (
    <Content>
      {Pageheader}
      {ContentButton}
    </Content>
  );
};

export default connect(null, { sendMessage })(MessageSend);
