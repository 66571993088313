import React, { Component } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Input,
  Button,
  Space,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
  DatePicker,
  Select,
  Row,
  Col,
  Tooltip,
  Modal,
  Form,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  InboxOutlined,
  MailOutlined,
  MessageOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { getSupport, SendNotification } from "../../../actions/support";
import { Link } from "react-router-dom";
import history from "../../../history";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { renderInput, renderTextField } from "../../../components/form";
const { RangePicker } = DatePicker;
const { Option } = Select;

class TicketList extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    data: [],
    serviceDetails: null,
    detail: false,
    visible: false,
    passVisible: false,
    modalVisible: false,
    editModalVisible: false,
    placement: "bottom",
    id: "",
    t: null,
    u_s: null,
    s: null,
    m: null,
    p: 1,
    current: 1,
    isModalVisible: false,
  };
  componentDidMount = async (page) => {
    window.scrollTo(0, 0);
    const res = await this.props.getSupport(
      this.state.t,
     
      this.state.s,
      this.state.u_s,
      this.state.m,
      page
    );
    this.setState({ data: res });
  };
  handleStatus = async (user, active) => {
    const code = await this.props.updateAuth(user, active);
    if (code === 201) {
      const res = await this.props.getUsers();
      this.setState({ data: res });
    }
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  createData = (
    sl,
    ticket_id,
    title,
    status,
    created_at,
    admin_seen,
    action
  ) => {
    return { sl, ticket_id, title, status, created_at, admin_seen, action };
  };

  showDrawer = (id) => {
    this.setState({
      visible: true,
      id: id,
    });
  };
  handleRefresh = async () => {
    const res = await this.props.getUsers();
    this.setState({ data: res });
  };

  handlePassVisible = (id) => {
    this.setState({ passVisible: true, id: id });
  };
  handleModalVisible = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };
  handleVisible = async (ticket) => {
    history.push(`/ticket-details?ticket=${ticket}`);
  };
  handleEditModalVisible = (user_id) => {
    this.setState({
      editModalVisible: !this.state.editModalVisible,
      id: user_id,
    });
  };
  onClosePass = () => {
    this.setState({
      passVisible: false,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  makeLocalTime = (date) => {
    let mainDate = date.split("T")[0];
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      mainDate + " , " + hours + ":" + minutes + " " + (am ? "a.m" : "p.m")
    );
  };
  handleDate = (date) => {
    if (date) {
      const ds = new Date(date[0]._d);
      const de = new Date(date[1]._d);
      this.setState({ ds: ds.toISOString(), de: de.toISOString() });
    } else {
      this.setState({ ds: null, de: null });
      return;
    }
  };
  handlePageChange = async (page) => {
    const res = await this.props.getSupport(
      page,
      this.state.t,
      this.state.s,
      this.state.u_s,
      this.state.m,
      
    );
    this.setState({ current: page, data: res });
  };
  handleApiSearch = async () => {
    const res = await this.props.getSupport(
      this.state.p,
      this.state.t,
      this.state.s,
      this.state.u_s,
      this.state.m,
      
    );
    this.setState({ data: res });
  };
  isStyledDifferently = (rowObject, index) => {
    return rowObject.isActive ? true : false;
  };
  onFinish = async (values) => {
    const code = await this.props.SendNotification(values);
    if (code === 201) {
      this.setState({ isModalVisible: false });
    }
  };

  onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  render() {
    const columns = [
      {
        title: "SL",
        dataIndex: "sl",
        key: "sl",
        width: "10%",
      },
      {
        title: "Ticket ID",
        dataIndex: "ticket_id",
        key: "ticket_id",
        width: "15%",
        ...this.getColumnSearchProps("ticket_id"),
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        width: "20%",
        ...this.getColumnSearchProps("title"),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "15%",
        ...this.getColumnSearchProps("status"),
      },
      {
        title: "Order Date",
        dataIndex: "created_at",
        key: "created_at",
        width: "20%",
        ...this.getColumnSearchProps("created_at"),
      },
      {
        title: "View Status",
        dataIndex: "admin_seen",
        key: "admin_seen",
        width: "15%",
        ...this.getColumnSearchProps("admin_seen"),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "25%",
      },
    ];
    const { data } = this.state;
    const rows = [
      data?.tickets
        ? data?.tickets.map((item, id) =>
            this.createData(
              id + 1,
              item.ticket_id,
              item.title,

              item.status === "Active" ? (
                <Tag color="#ff7675">&nbsp; {item.status} &nbsp;</Tag>
              ) : (
                <Tag color="#04836e">{item.status} &nbsp;&nbsp;</Tag>
              ),
              this.makeLocalTime(item.created_at),
              item.admin_seen ? (
                <Tag
                  icon={<CheckOutlined />}
                  style={{ backgroundColor: "#04836e", color: "#fff" }}
                >
                  Replied &nbsp;&nbsp;
                </Tag>
              ) : (
                <Tag
                  icon={<MailOutlined />}
                  style={{ backgroundColor: "#ff7675", color: "#fff" }}
                >
                  Not-Replied
                </Tag>
              ),
              item.admin_seen ? (
                <Button
                  size="small"
                  type="primary"
                  icon={<EyeOutlined />}
                  onClick={() => this.handleVisible(item.ticket_id)}
                >
                  Details
                </Button>
              ) : (
                <Button
                  size="small"
                  style={{ backgroundColor: "#ff7675", color: "#fff" }}
                  icon={<EyeOutlined />}
                  onClick={() => this.handleVisible(item.ticket_id)}
                >
                  View &nbsp;&nbsp;
                </Button>
              )
            )
          )
        : "",
    ];
    return (
      <Content>
       
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Ticket List"
          subTitle="Support Management"
          extra={
            <>
              {/* <Button
                type="primary"
                icon={<MailOutlined />}
                size="middle"
                onClick={() => this.setState({ isModalVisible: true })}
              >
                {" "}
                Send Notification
              </Button> */}
              <Tag style={{ fontSize: "14px", padding: "4px" }} color="#2db7f5">
                {" "}
                {data?.count} tickets found
              </Tag>
            </>
          }
        ></PageHeader>
        <Modal
          title="Send Notification"
          visible={this.state.isModalVisible}
          okText={false}
          cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
          onCancel={() => this.setState({ isModalVisible: false })}
          width={900}
        >
          <Form
            name="Send Notification"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
          >
            {renderInput("email", "Email", [
              { required: true, message: "Remark is required" },
            ])}
            {renderTextField("message", "Message", [
              { required: true, message: "Remark is required" },
            ])}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Send
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <br />

        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={5}>
            <Input
              placeholder="Ticket Id"
              style={{ width: "99%" }}
              allowClear
              onChange={(e) => this.setState({ t: e.target.value })}
            />
          </Col>
          <br />
          <br />
          <Col xs={12} sm={12} md={6} lg={6} xl={5}>
            <Input
              placeholder="Mobile No"
              style={{ width: "99%" }}
              allowClear
              onChange={(e) => this.setState({ m: e.target.value })}
            />
          </Col>
          <br />
          <br />
          
          <Col xs={12} sm={12} md={6} lg={6} xl={5}>
            <Select
              showSearch
              style={{ width: "99%" }}
              placeholder="Select a status"
              name="status"
              optionFilterProp="children"
              onChange={(e) => this.setState({ s: e })}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">None</Option>
              <Option value="Active">Active</Option>
              <Option value="Closed">Closed</Option>
            </Select>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={5}>
            <Select
              showSearch
              style={{ width: "99%" }}
              placeholder="View status"
              name="u_s "
              optionFilterProp="children"
              onChange={(e) => this.setState({ u_s: e })}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">None</Option>
              <Option value="true">Seen</Option>
              <Option value="false">Unseen</Option>
            </Select>
          </Col>
          
          <Col xs={12} sm={12} md={6} lg={6} xl={2}>
            &nbsp; &nbsp;
            <Tooltip title="search" onClick={this.handleApiSearch}>
              <Button type="primary" shape="circle" icon={<SearchOutlined />} />
            </Tooltip>
          </Col>
        </Row>
        <br></br>
        <Table
          columns={columns}
          dataSource={rows[0]}
          scroll={{ x: 1000 }}
          sticky
          pagination={false}
        />
        <br></br>
        <Pagination
          current={this.state.current}
          total={data?.count}
          pageSize="20"
          onChange={this.handlePageChange}
          pageSizeOptions={[20]}
        />
        {/* <Remarks placement={placement} visible={visible} fnc={this.onClose} id={id} delFnc = {this.props.deleteUser} data = {this.state.data} refresh = {this.handleRefresh} />
            <ChangePass placement={placement} visible={passVisible} fnc={this.onClosePass} id={id} passFnc = {this.props.getSupport} data = {this.state.data} refresh = {this.handleRefresh} />
            
            {this.state.editModalVisible ? <EditModalForm visible={this.state.editModalVisible} setVisible={this.handleEditModalVisible} user_id={this.state.id} refresh = {this.handleRefresh} /> : null}
            <ModalForm visible={this.state.modalVisible} setVisible={this.handleModalVisible} refresh = {this.handleRefresh}/> */}
      </Content>
    );
  }
}

export default connect(null, { getSupport, SendNotification })(TicketList);
