import {Form, Drawer,Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';

import { renderInput, renderPasswordField, renderTextField } from '../../../components/form';

const tailLayout = {
    wrapperCol: { offset: 8, span: 12 },
  };
const ChangePass = ({placement,visible,fnc,id, passFnc,refresh}) => {

  const [form] = Form.useForm();
    const onFinish = async (values) => {
     
        const code = await passFnc(id,values);
        if(code === 201 ){
          form.resetFields();
            fnc()
            refresh()

          }
};
const onFinishFailed = (errorInfo) => {
  // console.log('Failed:', errorInfo);
  };

const passwordchange=(
  <>
<Drawer
  title="Change Password"
  placement={placement}
  closable={false}
  onClose={fnc}
  visible={visible}
  key={placement}
  width={500}
>
  <Form
    name="basic"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    initialValues={{ remember: true }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    style={{alignSelf: 'center'}}
    requiredMark={false}
    
    form={form}
    autoComplete={false}
    
  >
  {renderPasswordField('password', 'New Password',
    [
      { required: true, message: 'This field is required' },
      { pattern: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' }
    ]
  )}
  {renderPasswordField('confirm_password', 'Confirm Password',
    [
      { required: true, message: 'This field is required' },
      { pattern: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' }
    ]
  )}
   {renderTextField('remark', 'Remark', [
      { required: true, message: 'This field is required!' },
    ])
  }
  <Form.Item {...tailLayout}> 
    <Button type="primary" htmlType="submit">
      Update
    </Button>
  </Form.Item>
  </Form>
</Drawer>
</>
)


    return ( 
      <Content>
      {passwordchange}
      </Content>
     );
}

export default ChangePass;