
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
 
  ERROR_MESSAGE,
 
  LOADED,
  LOADING,
 
  VALIDATION_ERROR,
} from "../reducers/type";

export const getZone = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "area-pricing-management/zone/all"
    );

    dispatch({ type: LOADED });

    return response.data.zones;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
