import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Table, Tag, Card, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ParcelStatistics } from "../../../actions/parcel";
import { Column, Line, Pie, getCanvasPattern } from "@ant-design/plots";

const ParcelStatisticS = ({ ParcelStatistics }) => {
  const [data, setData] = useState([]);
  const [lastmonthStatistic, setLastMonthStatistic] = useState([]);
  const [totalStatistic, setTotalStatistic] = useState([]);
  const [data3, setData3] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0)
    const parcel_statistic = await ParcelStatistics();

    const piechartData = [];

    piechartData.push(
      {
        type: "Return",
        value: parcel_statistic?.statistic[0]?.totalOrderStatus[0].return,
      },
      {
        type: "Delivered",
        value: parcel_statistic?.statistic[0]?.totalOrderStatus[0].delivered,
      },
      {
        type: "Cancel",
        value: parcel_statistic?.statistic[0]?.totalOrderStatus[0].cancel,
      },
      {
        type: "Pending",
        value: parcel_statistic?.statistic[0]?.totalOrderStatus[0].pending,
      }
    );
    setData(piechartData);

    setLastMonthStatistic(parcel_statistic?.statistic[0]?.lastMonthStatus[0]);
    setTotalStatistic(parcel_statistic?.statistic[0]?.totalOrderStatus[0]);

    let lintChartData = [];

    for (
      let i = 0;
      i < parcel_statistic?.statistic[0]?.lastYearReport?.length;
      i++
    ) {
      lintChartData.push({
        name: "parcel",
        count: parcel_statistic?.statistic[0]?.lastYearReport[i].order,
        date: parcel_statistic?.statistic[0]?.lastYearReport[i].month,
      });
    }

    setData3(lintChartData);
  }, []);

  

  const config2 = {
    data: data3,
    xField: "date",
    yField: "count",

    seriesField: "name",
    color: ({ name }) => {
      return name === "name" ? "#30BF78" : "#30BF78";
    },

    yAxis: {
      label: {
        formatter: (v) => `${v} `,
      },
    },
    legend: {
      position: "top",
    },

    smooth: false,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  const Main_chart = <Line {...config2} />;

 



  return (
    <Content>
     
      {Main_chart}
    </Content>
  );
};

export default connect(null, {
  ParcelStatistics,
})(ParcelStatisticS);
