import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";

export const RecentMerchants = () => async (dispatch) => {
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/merchant-management/get-recent-merchant`
    );
    
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.merchant;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const MerchatStatistic = () => async (dispatch) => {
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/merchant-management/merchant-statistic`
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.merchantStatistic[0];
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getMerchantList = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/merchant-management/get-all-merchant"
    );
  
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.merchant;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
export const createMerchant = (formValues,doc_image_front,doc_image_back) => async (dispatch) => {
  dispatch({ type: LOADING });
  const data = new FormData();
 
  data.append("name", formValues.name);
  data.append("email", formValues.email);
  data.append("address", formValues.address);
  data.append("mobile_number", formValues.mobile_number); 
  data.append("password", formValues.password); 
  data.append("confirm_password", formValues.confirm_password);
  data.append("country_code", 88);
  data.append("merchant_type", formValues.merchant_type);
  data.append("doc_type", formValues.doc_type);
  if(formValues.merchant_type === 'Company'){
    data.append("company_name", formValues.company_name);
  }
  if(formValues.doc_type !== 'NONE'){
    data.append("doc_no", formValues.doc_no);
    data.append("doc_image_front", doc_image_front);
    data.append("doc_image_back", doc_image_back);
  }
  try {
    const response = await api(localStorage.getItem("token")).post(
      "/merchant-management/create-merchant", data);

    if (response.status === 201) {
      dispatch({ type: SUCCESS_MESSAGE, payload: 'Merchant Succeessfully Created' });
      dispatch({ type: LOADED });
      history.push("/merchant-list");
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      if(err.message == 'Network Error')
      {
          history.push('/networkError')
          return
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/
  }
};



export const setMerchantDetails = (mobile_number) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/merchant-management/get-merchant-details?merchant=${mobile_number}`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.merchant[0];
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const changeMerchantAuth = (merchant, auth) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const obj = {
      merchant: merchant,
      authenticated: auth,
      remark: "",
    };

    const response = await api(localStorage.getItem("token")).patch(
      `/merchant-management/auth-merchant`,
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Merchant Auth Succeessfully Updated",
      });
      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
export const TopTenMerchants = () => async (dispatch) => {
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/merchant-management/top-merchant`
    );
   
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
export const authnticateShop= (id,mobile_number) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
   
    const response = await api(localStorage.getItem("token")).patch(`/merchant-management/auth-shop?merchant=${mobile_number}&shop_id=${id}`);
  
    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: 'Shop authication successfull..!' });
      return response.status;
    }
  }
  catch( err ){
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      if(err.message == 'Network Error')
      {
          history.push('/networkError')
          return
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/
  }
}

export const TopMerchantsNumber = (m,num,ds,de) => async (dispatch) => {
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/merchant-management/top/merchant?m=${m?m:""}&num=${num?num:10}&ds=${ds?ds:''}&de=${de?de:''}`
    );
   
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};


export const searchMerchantShop = (merchant) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `merchant-management/get-merchant?merchant=${merchant}`
    );
   
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const changeMerchantPass = (merchant, data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const obj = {
      merchant: merchant,
      password: data.password,
      remark: data.remark,
    };

    const response = await api(localStorage.getItem("token")).patch(
      `/merchant-management/update-password`,
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Merchant Password Succeessfully Updated",
      });
      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const editMerchantData = (merchant) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const response = await api(localStorage.getItem("token")).get(
      `/merchant-management/get-edit-merchant?merchant=${merchant}`
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.merchant;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const updateMerchant = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const obj = {
      merchant: data.mobile_number,
      address: data.address,
      name: data.name,
      email: data.email,
      merchant_type: data.merchant_type,
      company_name: data.company_name,
      remark: data.remark,
    };
    const response = await api(localStorage.getItem("token")).patch(
      "/merchant-management/update-merchant",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Merchant Succeessfully Updated",
      });
      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};
