import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import { getWarehouses, getWarehouseManager ,updateWarehouseManagerAuth} from "../../../actions/warehouse";
import {
  Table,
  Input,
  Button,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
  Row,
  Col,
  Card,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";

const WarehouseManagerList = ({ getWarehouses, getWarehouseManager,updateWarehouseManagerAuth }) => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getWarehouseManager();
    setData(res);
    
  }, []);
  const handleVisible=async(id)=>{
    history.push(`/warehouse-manager-details?user_id=${id}`);
  }
  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const handleStatus = async (id, active) => {
    const code = await updateWarehouseManagerAuth(id, active,'');
    if (code === 201) {
      const res = await getWarehouseManager();
      setData(res);
    }
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      width: "20%",
      ...searchColumns("user_id"),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...searchColumns("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "20%",
      ...searchColumns("email"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "20%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Status",
      dataIndex: "authenticated",
      key: "authenticated",
      width: "25%",
    },
    {
      title: "WareHouse",
      dataIndex: "warehouse",
      key: "warehouse",
      width: "20%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "25%",
    },
  ];
  const createData = (
    user_id,
    name,
    email,

    mobile_number,
    authenticated,
    warehouse,

    action
  ) => {
    return {
      user_id,
      name,
      email,
      mobile_number,
      authenticated,
      warehouse,

      action,
    };
  };

  const rows = [
    data
      ? data?.map((users) =>
          createData(
            users?.user_id,
            users?.name,
            users?.email,
            users?.mobile_number,
            users?.authenticated ? (
              <>
                
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleStatus(users?.user_id, false)}
                >
                  <Button size="small" type="danger" icon={<CheckCircleOutlined />}>
                  
                  Authanticated
                  </Button>
                </Popconfirm>
              </>
            ) : (
              <>
                
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleStatus(users?.user_id, true)}
                >
                  <Button
                    size="small"
                    type="primary"
                    icon={< StopOutlined />}
                  >
                    Unauthanticated
                  </Button>
                </Popconfirm>
              </>
            ),
            <>
              {users?.warehouse?.name};<br></br>
              {users?.warehouse?.address};<br></br>
            </>,
            

            <Button
              size="small"
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => {handleVisible( users?.user_id)}}
            >
              View
            </Button>
           
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Warehouse Manager List"
      subTitle="Warehouse Management"
    ></PageHeader>
  );

  return (
    <Content>
      {Pageheader}
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
    </Content>
  );
};

export default connect(null, {
  getWarehouses,
  getWarehouseManager,updateWarehouseManagerAuth
})(WarehouseManagerList);
