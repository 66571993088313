import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Table, Tag, Card, Col, Row, Popconfirm } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getOutForDeliveryParcel,
  deliverParcel,
} from "../../../actions/parcel";
import history from "../../../history";
const ParcelDeliver = ({ getOutForDeliveryParcel, deliverParcel }) => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getOutForDeliveryParcel();

    setData(res);
    //  console.log(res)
  }, []);
  const handleVisible = async (mobile) => {
    history.push(`/merchant-details?merchant=${mobile}`);
  };
  const handleDeliver = async (order_id) => {
    let res = await deliverParcel(order_id);

    if (res === 201) {
      const data = await getOutForDeliveryParcel();
      setData(data);
    }
  };

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      width: "15%",
      ...searchColumns("order_id"),
    },
    {
      title: "Customer Info",
      dataIndex: "recipient_mobile_number",
      key: "recipient_mobile_number",
      width: "15%",
      ...searchColumns("recipient_mobile_number"),
    },
    {
      title: "Payment Info",
      dataIndex: "item_actual_price",
      key: "item_actual_price",
      width: "16%",
      ...searchColumns("item_actual_price"),
    },
    {
      title: "Merchant Info",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "15%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Store Info",
      dataIndex: "shop_id",
      key: "shop_id",
      width: "15%",
      ...searchColumns("shop_id"),
    },
    {
      title: "Driver Info",
      dataIndex: "user_id",
      key: "user_id",
      width: "15%",
      ...searchColumns("user_id"),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "12%",
    },
  ];
  const createData = (
    order_id,
    recipient_mobile_number,
    item_actual_price,
    mobile_number,
    shop_id,
    user_id,

    action
  ) => {
    return {
      order_id,
      recipient_mobile_number,
      item_actual_price,
      mobile_number,
      shop_id,
      user_id,
      action,
    };
  };

  const rows = [
    data
      ? data?.orders?.map((Shop, id) =>
          createData(
            Shop?.order_id,
            Shop?.recipient_name +
              "\n" +
              Shop?.recipient_address +
              "\n" +
              Shop?.recipient_mobile_number,

            `Delivery Charge:${Shop?.charges?.delivery_charge}` +
              "\n" +
              `Paybale Amount:${Shop?.charges?.payable_amount}` +
              "\n" +
              `Return Charge:${Shop?.charges?.return_charge}` +
              "\n" +
              `Ammount of Collect:${Shop?.charges?.amount_to_collect}` +
              "\n" +
              `Actual Price:${Shop?.charges?.item_actual_price}` +
              "\n" +
              `Additional Cost:${Shop?.charges?.additional_cost}` +
              "\n" +
              `Cash on Delivery:${Shop?.charges?.cod}`,

            Shop?.merchant?.name + "\n" + Shop?.merchant?.mobile_number,

            Shop?.shop?.name +
              "\n" +
              Shop?.shop?.mobile_number +
              "\n" +
              Shop?.shop?.shop_id,

            Shop?.delivery_driver?.name + "\n" + Shop?.delivery_driver?.user_id,

            [
              <Popconfirm
                title="Are you want to Deliver!"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleDeliver(Shop?.order_id)}
              >
                <Button size="small" type="primary" onClick={() => {}}>
                   Deliver
                </Button>
              </Popconfirm>,
            ]
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Parcel Deliver"
      subTitle="Parcel Management"
    ></PageHeader>
  );

  return (
    <Content>
      {Pageheader}
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
    </Content>
  );
};

export default connect(null, { getOutForDeliveryParcel, deliverParcel })(
  ParcelDeliver
);
