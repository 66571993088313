
import history from "../history";
import api from "./apis/index";
import {
  
  ERROR_MESSAGE,
 
  GET_WAREHOUSES,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,

  VALIDATION_ERROR,
} from "../reducers/type";

export const getAllWarehouses = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "finance-management/get-all-warehouse"
    );
    dispatch({ type: LOADED });
    return response.data.warehouses;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
export const getAllWarehousesManager = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/warehouse-management/total-manager`
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
export const getAllWarehousesTotal = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/warehouse-management/total-warehouse`
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const warehouseMainStatistics = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/warehouse-management/warehouse-management-statistic`
    );

    dispatch({ type: LOADED });

    return response?.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });

    dispatch({ type: LOADED });
  }
};

export const getWarehouses = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "warehouse-management/list-warehouse"
    );
    
    dispatch({ type: LOADED });
    dispatch({ type: GET_WAREHOUSES, payload: response.data });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });

    dispatch({ type: LOADED });
  }
};

export const createWarehouse = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  const obj = {
    warehouse_id: data.warehouse_id,
    name: data.warehouse_name,
    address: data.warehouse_address,
    area_code:
      data.city + "-" + data.subCity + "-" + data.thana + "-" + data.area,
    mobile_number: data.mobile_number,
    active: data.status,
  };
  try {
    const response = await api(localStorage.getItem("token")).post(
      "/warehouse-management/create-warehouse",
      { ...obj }
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Warehouse is created successfully",
      });
      history.push("/warehouse-list");
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
  }
};

export const createWarehouseManager = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).post(
      "/warehouse-management/create-manager",
      { ...data }
    );
    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Warehouse Manager is created successfully",
      });
      history.push("/warehouse-manager-list");
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });

    dispatch({ type: LOADED });
  }
};

export const getWarehouseManager = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/warehouse-management/get-all-manager"
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });

    dispatch({ type: LOADED });
  }
};

export const updateWarehouseManagerAuth =
  (id, auth, remark) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const obj = {
        user_id: id,
        authenticated: auth,
        remark: "",
      };
      const response = await api(localStorage.getItem("token")).patch(
        "/warehouse-management/auth-manager",
        { ...obj }
      );
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Warehouse Manager auth updated successfully",
        });

        return response.status;
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });

      dispatch({ type: LOADED });
    }
  };

export const getManager = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/warehouse-management/get-manager-details?user_id=${id}`
    );
    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });

    dispatch({ type: LOADED });
  }
};

// ============================================================================Change Pass
export const changeManagerPass = (values, id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const obj = {
      user_id: id,
      password: values.password,
      remark: values.remark,
    };
    const response = await api(localStorage.getItem("token")).patch(
      "/warehouse-management/update-manager-password",
      { ...obj }
    );
    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Warehouse Manager password updated successfully",
      });
      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });

    dispatch({ type: LOADED });
  }
};

export const updateWarehouseStatus =
  (id, status, remark) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      const obj = {
        warehouse_id: id,
        active: status,
        remark: "",
      };
      const response = await api(localStorage.getItem("token")).patch(
        "/warehouse-management/active-warehouse",
        { ...obj }
      );
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Warehouse status updated successfully",
        });
        const response = await api(localStorage.getItem("token")).get(
          "warehouse-management/list-warehouse"
        );
        dispatch({ type: LOADED });
        return response.data;
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });

      dispatch({ type: LOADED });
    }
  };

// ================== warehouse Details=====
export const getAlWarehouseDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/warehouse-management/get-warehouse-details?warehouse_id=${id}`
    );
    dispatch({ type: LOADED });

    return response.data;
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

// ============================================================================Get single Warehouse Statistics
export const warehouseStatistics = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/warehouse-management/warehouse-statistic?warehouse_id=${id}`
    );
    dispatch({ type: LOADED });
    return response.data.statistics;
    // dispatch({ type: GET_WAREHOUSE, payload: response.data });
    // return response.data.warehouse
    // history.push('/warehouse')
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });

    dispatch({ type: LOADED });
  }
};

// ============================================================================Get Single Warehouse
export const getWarehouseEditData = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/warehouse-management/get-edit-warehouse?warehouse_id=${id}`
    );
    dispatch({ type: LOADED });

    return response.data.warehouse;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });

    dispatch({ type: LOADED });
  }
};
export const updateWarehouse = (data,warehouse_id) => async (
  dispatch
) => {
  dispatch({ type: LOADING });
  const obj = {
    warehouse_id: warehouse_id,
    name: data.warehouse_name,
    address: data.warehouse_address,
    area_code: data.city +'-'+ data.subCity +'-'+ data.thana +'-'+ data.area,
    mobile_number: data.mobile_number,
    remark: data.remark,
  };
  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/warehouse-management/update-warehouse",{ ...obj },
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Warehouse is updated successfully",
      });
      history.push("/warehouse-list");
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
            {
                history.push('/networkError')
                return
            }
  
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
  }
};

export const getManagerEditData = (id) => async (dispatch) => {
    
  dispatch({ type: LOADING });
try {
  const response = await api(localStorage.getItem("token")).get(
    `/warehouse-management/get-edit-manager?user_id=${id}`
  );


  dispatch({ type: LOADED });

    return response.data.manager;
  
  // history.push('/warehouse')
} catch (err) {
  if (err?.response?.status === 422) {
    err.response.data.errors.map((item) => {
      dispatch({
        type: VALIDATION_ERROR,
        payload: [{ message: item.message, path: item.path[0] }],
      });
    });
  }
  if (err?.response?.status === 401 || err?.response?.status === 403) {
    dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
    localStorage.clear();
    history.push("/login");
  }
  if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
  
  dispatch({
    type: ERROR_MESSAGE,
    payload: err.response
      ? err.response.data.message
      : history.push("/notFound"),
  });

  dispatch({ type: LOADED });
}
};
export const updateWarehouseManager = (data) => async (dispatch) => {
    
  dispatch({ type: LOADING });
try {
  const response = await api(localStorage.getItem("token")).patch(
    "/warehouse-management/update-manager",
    { ...data }
  );
  if (response.status === 201) {
    dispatch({ type: LOADED });
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: "Warehouse Manager is updated successfully",
    });
    history.push("/warehouse-manager-list");
  }
} catch (err) {
  if (err?.response?.status === 422) {
    err.response.data.errors.map((item) => {
      dispatch({
        type: VALIDATION_ERROR,
        payload: [{ message: item.message, path: item.path[0] }],
      });
    });
  }
  if (err?.response?.status === 401 || err?.response?.status === 403) {
    dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
    localStorage.clear();
    history.push("/login");
  }
  if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
  
  dispatch({
    type: ERROR_MESSAGE,
    payload: err.response
      ? err.response.data.message
      : history.push("/notFound"),
  });

  dispatch({ type: LOADED });
}
};