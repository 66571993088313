import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";


export const getContacts = (p,m,d,ds,de) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    let response = null;
    if(ds && ds){
      response = await api(localStorage.getItem("token")).get(`/message-management/contact-us?p=${p?p:1}&d=${d?d:""}&ds=${ds?ds:""}&de=${de?de:""}&m=${m?m:""}`);
    }
    else{
      response = await api(localStorage.getItem("token")).get(`/message-management/contact-us?p=${p?p:1}&d=${d?d:""}&m=${m?m:""}`);
    }
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const getContactDetails = (contact) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    
    const response = await api(localStorage.getItem("token")).get(`/message-management/contact-us/detail?contact=${contact}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data.contact;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const responseDone = (contact,remark) => async (dispatch) => {
  dispatch({ type: LOADING });
   
  try{
  
    const obj = {
        contact : contact,
        remark : remark.remark,
    }
    const response = await api(localStorage.getItem("token")).patch('/message-management/contact-us/done', {...obj});
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Operation Successful' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};
