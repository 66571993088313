import { EyeOutlined, PlusCircleOutlined,SearchOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Table, Tag, Card, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMerchantList } from "../../../actions/merchant";
import history from "../../../history";
const GetMerchantList = ({ getMerchantList }) => {
    const [data, setData] = useState([]);
  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getMerchantList();
   setData(res);
  //  console.log(res)
    
  }, []);
  const handleVisible=async(mobile)=>{
    history.push(`/merchant-details?merchant=${mobile}`);
  }

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      width: "10%",
     
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...searchColumns("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
      ...searchColumns("email"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "15%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      width: "15%",
      ...searchColumns("company_name"),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "20%",
      ...searchColumns("address"),
    },
    {
      title: "Total Shop",
      dataIndex: "shop_count",
      key: "shop_count",
      width: "10%",
      ...searchColumns("shop_count"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "15%",
     
    },
  ];
  const createData = (id,name, email, mobile_number, company_name, address,shop_count,action) => {
    return { id,name, email, mobile_number, company_name, address,shop_count,action };
  };

  const rows = [
    data
      ? data?.map((merchant,id) =>
          createData(
            id + 1,
            merchant?.name,
            merchant?.email,
            merchant?.mobile_number,
            merchant?.company_name,
           
            merchant?.address,
            merchant?.shop_count,
            <Button
                  size="small"
                  type="primary"
                  icon={<EyeOutlined />}
                  onClick={() => {handleVisible(merchant.mobile_number)}}
                >
                  View
                </Button>
            
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Merchant List"
      subTitle="Merchant Management"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => history.push("/create-merchant")}
        >
          Merchant Create
        </Button>,]}
    ></PageHeader>
  );

  return (
    <Content>
      {Pageheader}
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
    </Content>
  );
};


export default connect(null, { getMerchantList })(GetMerchantList);
