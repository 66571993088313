import React, { Component } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Input,
  Button,
  Space,
  PageHeader,
  Breadcrumb,
  Tag,
  DatePicker,
  Select,
  Row,
  Col,
  Tooltip,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { getContacts } from "../../../actions/contact";
import { Link } from "react-router-dom";
import history from "../../../history";
import { connect } from "react-redux";
import { Pagination } from "antd";
const { RangePicker } = DatePicker;
const { Option } = Select;

class ContactResponseList extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    data: [],
    serviceDetails: null,
    detail: false,
    visible: false,
    passVisible: false,
    modalVisible: false,
    editModalVisible: false,
    placement: "bottom",
    id: "",
    ds: null,
    de: null,
    d: null,
    m: null,
    p: 1,
    current: 1,
  };
  componentDidMount = async (page) => {
    const res = await this.props.getContacts(
      
      this.state.m,
      this.state.d,
      this.state.ds,
      this.state.de,
      page,
    );
    window.scrollTo(0, 0);
    this.setState({ data: res });
  };
  handleStatus = async (user, active) => {
    const code = await this.props.updateAuth(user, active);
    if (code === 201) {
      const res = await this.props.getUsers();
      this.setState({ data: res });
    }
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  createData = (
    sl,
    contact_id,
    subject,
    mobile_number,
    email,
    done,
    created_at,
    action
  ) => {
    return {
      sl,
      contact_id,
      subject,
      mobile_number,
      email,
      done,
      created_at,
      action,
    };
  };

  showDrawer = (id) => {
    this.setState({
      visible: true,
      id: id,
    });
  };
  handleRefresh = async () => {
    const res = await this.props.getUsers();
    this.setState({ data: res });
  };

  handlePassVisible = (id) => {
    this.setState({ passVisible: true, id: id });
  };
  handleModalVisible = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };
  handleVisible = async (contact) => {
    history.push(`/response-details?contact=${contact}`);
  };
  handleEditModalVisible = (user_id) => {
    this.setState({
      editModalVisible: !this.state.editModalVisible,
      id: user_id,
    });
  };
  onClosePass = () => {
    this.setState({
      passVisible: false,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  makeLocalTime = (date) => {
    let mainDate = date.split("T")[0];
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      mainDate + " , " + hours + ":" + minutes + " " + (am ? "a.m" : "p.m")
    );
  };
  handleDate = (date) => {
    if (date) {
      const ds = new Date(date[0]._d);
      const de = new Date(date[1]._d);
      this.setState({ ds: ds.toISOString(), de: de.toISOString() });
    } else {
      this.setState({ ds: null, de: null });
      return;
    }
  };
  handlePageChange = async (page) => {
    const res = await this.props.getContacts(
      page,
      this.state.m,
      this.state.d,
      this.state.ds,
      this.state.de
    );
    this.setState({ current: page, data: res });
  };
  handleApiSearch = async () => {
    const res = await this.props.getContacts(
      this.state.p,
      this.state.m,
      this.state.d,
      this.state.ds,
      this.state.de
    );
    this.setState({ data: res });
  };
  isStyledDifferently = (rowObject, index) => {
    return rowObject.isActive ? true : false;
  };
  render() {
    const columns = [
      {
        title: "SL",
        dataIndex: "sl",
        key: "sl",
        width: "10%",
      },
      {
        title: "Contact ID",
        dataIndex: "contact_id",
        key: "contact_id",
        width: "15%",
        ...this.getColumnSearchProps("contact_id"),
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        width: "20%",
        ...this.getColumnSearchProps("subject"),
      },
      {
        title: "Mobile Number",
        dataIndex: "mobile_number",
        key: "mobile_number",
        width: "20%",
        ...this.getColumnSearchProps("mobile_number"),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: "20%",
        ...this.getColumnSearchProps("email"),
      },
      {
        title: "Status",
        dataIndex: "done",
        key: "done",
        width: "15%",
        ...this.getColumnSearchProps("done"),
      },
      {
        title: "Response Date",
        dataIndex: "created_at",
        key: "created_at",
        width: "20%",
        ...this.getColumnSearchProps("created_at"),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "20%",
      },
    ];
    const { data } = this.state;
    const rows = [
      data?.contacts
        ? data?.contacts.map((item, id) =>
            this.createData(
              id + 1,
              item.contact_id,
              item.subject,
              item.mobile_number,
              item.email,

              item.done !== true ? (
                <Tag color="#ff7675">Not Reviewed</Tag>
              ) : (
                <Tag color="#04836e">&nbsp; Reviewed &nbsp;&nbsp;</Tag>
              ),
              this.makeLocalTime(item.created_at),
              <Button
                size="small"
                type="primary"
                icon={<EyeOutlined />}
                onClick={() => this.handleVisible(item.contact_id)}
              >
                Details
              </Button>
            )
          )
        : "",
    ];
    return (
      <Content>
        
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Response List"
          subTitle="Support Management"
          extra={<Tag color="#2db7f5" style={{fontSize:'15px',padding:'4px'}}> {data?.count} responses found</Tag>}
        ></PageHeader>
        <br></br>
        <Row  style={{display:'flex',justifyContent:'center',textAlign:'center'}}>
          <Col xs={6} sm={24} md={6} lg={6} xl={6}>
            <Input
              placeholder="Mobile No"
              style={{ width: "99%" }}
              allowClear
              onChange={(e) => this.setState({ m: e.target.value })}
            />
          </Col>
          &nbsp;
          <Col xs={6} sm={24} md={6} lg={6} xl={6}>
            <Select
              showSearch
              style={{ width: "99%" }}
              placeholder="Status"
              name="u_s "
              optionFilterProp="children"
              onChange={(e) => this.setState({ d: e })}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">None</Option>
              <Option value="true">Reviewed</Option>
              <Option value="false">Not Reviewed</Option>
            </Select>
          </Col>&nbsp;
          <Col xs={6} sm={24} md={6} lg={6} xl={6}>
            <RangePicker
              onChange={this.handleDate}
              format="YYYY/MM/DD"
              style={{ width: "80%" }}
            />
            &nbsp; &nbsp;
            <Tooltip title="search" onClick={this.handleApiSearch}>
              <Button type="primary" shape="circle" icon={<SearchOutlined />} />
            </Tooltip>
          </Col>
          &nbsp;
        </Row>
        <br></br>
        <Table
          columns={columns}
          dataSource={rows[0]}
          scroll={{ x: 1000 }}
          sticky
          pagination={false}
        />
        <Pagination
          current={this.state.current}
          total={data?.count}
          pageSize="20"
          onChange={this.handlePageChange}
          pageSizeOptions={[20]}
        />
       
      </Content>
    );
  }
}

export default connect(null, { getContacts })(ContactResponseList);
