import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";


export const login = ({userID, password}) => async (dispatch) => {
    
  dispatch({type: LOADING});
  try{
      const response =  await api(localStorage.getItem("token")).post('/access/signin', {
          user_id: userID, password: password},{
              headers: { 'content-type': 'application/json'}
          });
          
          if(response.status === 201 ){
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem("name", response.data.data.name);
              localStorage.setItem("role", response.data.data.roles.name);
              localStorage.setItem("access", JSON.stringify(response.data.data.roles.access));
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: "Login succesfully",
              });
              dispatch({type: LOADED})
              history.push('/dashboard')
          }
      
  }catch(err){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{'message': item.message,'path':item.path[0] , }]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: AUTH_ERROR, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({ type: AUTH_ERROR, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }

}

export const logOut = () => async (dispatch) => {
  dispatch({ type: LOADING});
  try{
      const response = await api(localStorage.getItem("token")).patch("/access/signout");
      if(response.status === 201)
      {
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: AUTH_SUCCESS, payload: 'Successfully logout' });
      dispatch({ type: LOADED });
  }catch (err){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: AUTH_ERROR, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
        dispatch({ type: AUTH_ERROR, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const updateOwnPassword = ({confirm_password, old_password, password}) => async (dispatch) => {
  dispatch({type: LOADING});

  const object = {
    
    old_password:old_password,
    password:password,
    confirm_password:confirm_password,
  };
  dispatch({type: LOADED});

  try {
    const response = await api(localStorage.getItem("token")).patch("/user-management/update-password",{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "Your password is updated succesfully"});
    localStorage.setItem('token', response.data.token)
    return response.status;
    // history.push('/userList')
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: AUTH_ERROR, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      if(err.message == 'Network Error')
        {
            history.push('/networkError')
            return
        }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};