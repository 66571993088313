import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";

export const RecentShops = () => async (dispatch) => {
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/merchant-management/get-recent-shop`
    );
    
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.shop;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const ShopStatistics = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/merchant-management/shop-statistic"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.shopStatistic[0];
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getShops = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/merchant-management/get-all-shop"
    );
    

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const UnAuthShops = () => async (dispatch) => {
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/merchant-management/get-unauth-shop`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.unauthorizedShop;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const setShopDetails = (mobile_number, shop_id) => async (dispatch) => {

  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/merchant-management/get-shop-details?merchant=${mobile_number}&shop_id=${shop_id}`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const shopAuthUpdate = (mobile_number, shop_id,auth) => async (dispatch) => {

  dispatch({ type: LOADING });
  try {
    const obj = {
      merchant: mobile_number,
      shop_id: shop_id,
     
    };
    const response = await api(localStorage.getItem("token")).patch(
      `/merchant-management/auth-shop`,

      { ...obj }
    );
    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Merchant Shop Auth Succeessfully Updated",
      });
      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const verifyMerchant = (merchant) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/merchant-management/get-merchant?merchant=${merchant}`
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.valid;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const createShop = (data,number) => async (
  dispatch
) => {
  
  dispatch({ type: LOADING });
  try {
    //  ========================Not custom and mobile===
      if(data.is_custom === false && data.payment_method === 'Mobile Banking'){
        const obj = {
          "shop_id" : data.shop_id,
          "merchant" : number,
          "name" : data.name,
          "remark" : data.remark,
          "email": data.email,
          "authenticated": data.authenticated,
          "address": data.address,
          "pickup_area_code": data.city +'-'+ data.subCity +'-'+ data.thana +'-'+ data.area,
          "pickup_address": data.pickup_address,
          "mobile_number" : data.mobile_number,
          "pickup_type": data.pickup_type,
          
          "trade_license": data.trade_license,
          "payment_method" : data.payment_method,
          "mobile_bank" : data.mobile_bank,
          "mobile_banking_account" : data.mobile_banking_account,

          "is_custom": data.is_custom,
        };
          const response = await api(localStorage.getItem("token")).post(
            "/merchant-management/create-shop",
            { ...obj }
          );
          if (response.status === 201) {
            dispatch({ type: LOADED });
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Shop is created successfully",
            });
            
            history.push("/shop-list");
          }
      }
    //  ========================Not custom and Bank===
      if(data.is_custom === false && data.payment_method === 'Bank Account' ){
        const obj = {
          "merchant" : number,
          "name" : data.name,
          "email": data.email,
          "authenticated": data.authenticated,
          "address": data.address,
          "pickup_area_code": data.city +'-'+ data.subCity +'-'+ data.thana +'-'+ data.area,
          "pickup_address": data.pickup_address,
          "mobile_number" : data.mobile_number,
          "pickup_type": data.pickup_type,
          
          "trade_license": data.trade_license,
          "payment_method" : data.payment_method,
          "bank_name" : data.bank_name,
          "branch_name" : data.branch_name,
          "account_type" : data.account_type,
          "account_name" : data.account_name,
          "account_number" : data.account_number,
          "routing_number" : data.routing_number,

          "is_custom": data.is_custom,
        };
      const response = await api(localStorage.getItem("token")).post(
        "/merchant-management/create-shop",
        { ...obj }
      );
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Shop is created successfully",
        });
        
        history.push("/shop-list");
      }
    
    
    
    
      }
    //  ======================== custom and Mobile===
      if(data.is_custom === true && data.payment_method === 'Mobile Banking' ){
        const obj = {
          "merchant" : number,
          "name" : data.name,
          "email": data.email,
          "authenticated": data.authenticated,
          "address": data.address,
          "pickup_area_code": data.city +'-'+ data.subCity +'-'+ data.thana +'-'+ data.area,
          "pickup_address": data.pickup_address,
          "mobile_number" : data.mobile_number,
          "pickup_type": data.pickup_type,
          
          "trade_license": data.trade_license,
          "payment_method" : data.payment_method,
          "mobile_bank" : data.mobile_bank,
          "mobile_banking_account" : data.mobile_banking_account,
          
          "is_custom": data.is_custom,
          "delivery_charge": { 
            "in_city" : data.d_in_city,
            "in_sub_city" : data.d_in_sub_city,
            "outer_city" : data.d_outer_city,
            "ODD" : data.d_ODD,
          },
          "return_charge": {
            "in_city" : data.r_in_city,
            "in_sub_city" : data.r_in_sub_city,
            "outer_city" : data.r_outer_city,
            "ODD" : data.r_ODD,
          },
          "base_weight": {
            "in_city" : data.base_in_city,
            "in_sub_city" : data.base_in_sub_city,
            "outer_city" : data.base_outer_city,
            "ODD" : data.base_ODD,
          },
          "ECPK": {
            "in_city" : data.ecpk_in_city,
            "in_sub_city" : data.ecpk_in_sub_city,
            "outer_city" : data.ecpk_outer_city,
            "ODD" : data.ecpk_ODD,
          },
          "cod": {
            "in_city" : data.cod_in_city,
            "in_sub_city" : data.cod_in_sub_city,
            "outer_city" : data.cod_outer_city,
            "ODD" : data.cod_ODD,
          },
        };
        
        const response = await api(localStorage.getItem("token")).post(
          "/merchant-management/create-shop",
          { ...obj }
        );
        if (response.status === 201) {
          dispatch({ type: LOADED });
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "Shop is created successfully",
          });
          
          history.push("/shop-list");
        }
      }
    //  ======================== custom and Bank===
    if(data.is_custom === true && data.payment_method === 'Bank Account' ){
      const obj = {
        "merchant" : number,
        "name" : data.name,
        "email": data.email,
        "authenticated": data.authenticated,
        "address": data.address,
        "pickup_area_code": data.city +'-'+ data.subcity +'-'+ data.thana +'-'+ data.area,
        "pickup_address": data.pickup_address,
        "mobile_number" : data.mobile_number,
        "pickup_type": data.pickup_type,
        
        "trade_license": data.trade_license,
        "payment_method" : data.payment_method,
        "bank_name" : data.bank_name,
        "branch_name" : data.branch_name,
        "account_type" : data.account_type,
        "account_name" : data.account_name,
        "account_number" : data.account_number,
        "routing_number" : data.routing_number,
        
        "is_custom": data.is_custom,
        "delivery_charge": { 
          "in_city" : data.d_in_city,
          "in_sub_city" : data.d_in_sub_city,
          "outer_city" : data.d_outer_city,
          "ODD" : data.d_ODD,
        },
        "return_charge": {
          "in_city" : data.r_in_city,
          "in_sub_city" : data.r_in_sub_city,
          "outer_city" : data.r_outer_city,
          "ODD" : data.r_ODD,
        },
        "base_weight": {
          "in_city" : data.base_in_city,
          "in_sub_city" : data.base_in_sub_city,
          "outer_city" : data.base_outer_city,
          "ODD" : data.base_ODD,
        },
        "ECPK": {
          "in_city" : data.ecpk_in_city,
          "in_sub_city" : data.ecpk_in_sub_city,
          "outer_city" : data.ecpk_outer_city,
          "ODD" : data.ecpk_ODD,
        },
        "cod": {
          "in_city" : data.cod_in_city,
          "in_sub_city" : data.cod_in_sub_city,
          "outer_city" : data.cod_outer_city,
          "ODD" : data.cod_ODD,
        },
      };
      
      const response = await api(localStorage.getItem("token")).post(
        "/merchant-management/create-shop",
        { ...obj }
      );
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Shop is created successfully",
        });
        
        history.push("/shop-list");
      }
    }
   
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const fetchShopEditData = (mobile_number,shop_id) => async (dispatch) => {
  dispatch({ type: LOADING  });
  try{
   
    const response = await api(localStorage.getItem("token")).get(`/merchant-management/get-edit-shop?merchant=${mobile_number}&shop_id=${shop_id}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data.shop;
      
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/}
}
export const geteditShopUpdate = (data,custom) => async (
  dispatch
) => {
  dispatch({ type: LOADING });
  //  console.log(data)
  try {
    
    
    //  ========================Not custom and mobile===
   
      if(custom === false ){
      
          const obj = {
            "shop_id" : data.shop_id,
            "merchant" : data.merchant,
            "name" : data.name,
            "remark" : data.remark,
            "email": data.email,
            "authenticated": data.authenticated,
            "address": data.address,
            "pickup_area_code": data.city +'-'+ data.subCity +'-'+ data.thana +'-'+ data.area,
            "pickup_address": data.pickup_address,
            "mobile_number" : data.mobile_number,
            "pickup_type": data.pickup_type,
           
            "trade_license": data.trade_license,
            
            "is_custom": data.is_custom,
          };
       
       
          const response = await api(localStorage.getItem("token")).patch(
            "/merchant-management/update-shop",
            { ...obj }
          );
          if (response.status === 201) {
            dispatch({ type: LOADED });
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Shop is updated successfully",
            });
            
            history.push("/shop-list");
          }
        
      
      }
      if(custom === true){
          
        const obj = {
          "shop_id" : data.shop_id,
          "merchant" : data.merchant,
          "name" : data.name,
          "remark" : data.remark,
          "email": data.email,
          "authenticated": data.authenticated,
          "address": data.address,
          "pickup_area_code": data.city +'-'+ data.subCity +'-'+ data.thana +'-'+ data.area,
          "pickup_address": data.pickup_address,
          "mobile_number" : data.mobile_number,
          "pickup_type": data.pickup_type,
          
          "trade_license": data.trade_license,

          "is_custom": data.is_custom,
          
          "delivery_charge": { 
            "in_city" : data.d_in_city,
            "in_sub_city" : data.d_in_sub_city,
            "outer_city" : data.d_outer_city,
            "ODD" : data.d_ODD,
          },
          "return_charge": {
            "in_city" : data.r_in_city,
            "in_sub_city" : data.r_in_sub_city,
            "outer_city" : data.r_outer_city,
            "ODD" : data.r_ODD,
          },
          "base_weight": {
            "in_city" : data.base_in_city,
            "in_sub_city" : data.base_in_sub_city,
            "outer_city" : data.base_outer_city,
            "ODD" : data.base_ODD,
          },
          "ECPK": {
            "in_city" : data.ecpk_in_city,
            "in_sub_city" : data.ecpk_in_sub_city,
            "outer_city" : data.ecpk_outer_city,
            "ODD" : data.ecpk_ODD,
          },
          "cod": {
            "in_city" : data.cod_in_city,
            "in_sub_city" : data.cod_in_sub_city,
            "outer_city" : data.cod_outer_city,
            "ODD" : data.cod_ODD,
          },
        };
        
        const response = await api(localStorage.getItem("token")).patch(
          "/merchant-management/update-shop",
          { ...obj }
        );
        if (response.status === 201) {
          dispatch({ type: LOADED });
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: "Shop is updated successfully",
          });
          
          history.push("/shop-list");
        }
      
    }
      
   
   
   
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateShopPayment = (merchant_mobile,shop_id,data) => async (
  dispatch
) => {
  dispatch({ type: LOADING });
  try {
    
    //  ======================== mobile===
      if(data.payment_method === 'Mobile Banking'){
        const obj = {
          "shop_id" : shop_id,
          "merchant" : merchant_mobile,
          "remark" : data.remark,
        
          "payment_method" : data.payment_method,
          "mobile_bank" : data.mobile_bank,
          "mobile_banking_account" : data.mobile_banking_account,

        };
          const response = await api(localStorage.getItem("token")).patch(
            "/merchant-management/update-shop-payment-method",
            { ...obj }
          );
          if (response.status === 201) {
            dispatch({ type: LOADED });
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: "Shop payment method is updated successfully",
            });
            
            history.push("/shop-list");
          }
      }
    //  ========================Not custom and Bank===
      if(data.payment_method === 'Bank Account' ){
        const obj = {
          "shop_id" : shop_id,
          "merchant" : merchant_mobile,
          "remark" : data.remark,

          "payment_method" : data.payment_method,
          "bank_name" : data.bank_name,
          "branch_name" : data.branch_name,
          "account_type" : data.account_type,
          "account_name" : data.account_name,
          "account_number" : data.account_number,
          "routing_number" : data.routing_number,

        };
      const response = await api(localStorage.getItem("token")).patch(
        "/merchant-management/update-shop-payment-method",
        { ...obj }
      );
      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Shop payment method is updated successfully",
        });
        
        history.push("/shop-list");
      }
    
    
    
    
      }
   
   
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};