import { Button, Form, Modal, Divider, Descriptions,TimePicker, PageHeader  } from "antd";
import { Content } from "antd/lib/layout/layout";
import { addPrice } from "../../../actions/price";
import { getAreas } from "../../../actions/area";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  renderInput,
  renderSelect,
  renderDate,
  renderTextField,
  renderTime,
} from "../../../components/form";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 12, span: 16 },
};

const is_odd = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];

const AddPricing = ({ addPrice }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const code = await addPrice(values);

    if (code === 201) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const FORM = (
    <Form
      {...layout}
      name="basic"
     
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      initialValues={{ remember: true }}
      form={form}
      autoComplete={false}
      requiredMark={false}
    >
      
      <Divider>Add Price Model</Divider>

      {renderInput("name", "Model Name", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("vat", "Value Added Tax (VAT)", [
        { required: true, message: "This field is required!" },
      ])}

      <Divider orientation="center">In City</Divider>

      {renderInput("inCity_delivery_charge", "Delivery Charge", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("inCity_return_charge", "Return Charge", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("inCity_cod", "Cash On Delivery (COD)", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("inCity_odd_delivery_charge", "ODD Delivery Charge", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("inCity_odd_return_charge", "ODD Return Charge", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("inCity_odd_cod", "ODD Cash On Delivery (COD)", [
        { required: true, message: "This field is required!" },
      ])}

      {renderInput("inCity_base_weight", "Base Weight(KG)", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("inCity_max_weight", "Max Weight(KG)", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("inCity_ECPK", "ECPK", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("inCity_odd_base_weight", "ODD Base Weight(KG)", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("inCity_odd_max_weight", "ODD Max Weight(KG)", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("inCity_odd_ECPK", "ODD ECPK", [
        { required: true, message: "This field is required!" },
      ])}


      <Divider orientation="center">In Sub City</Divider>

      {renderInput("subCity_delivery_charge", "Delivery Charge", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("subCity_return_charge", "Return Charge", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("subCity_cod", "Cash On Delivery (COD)", [
        { required: true, message: "This field is required!" },
      ])}

      {renderInput("subCity_base_weight", "Base Weight(KG)", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("subCity_max_weight", "Max Weight(KG)", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("subCity_ECPK", "ECPK", [
        { required: true, message: "This field is required!" },
      ])}

   

      <Divider orientation="center">Outer City</Divider>

      {renderInput("outerCity_delivery_charge", "Delivery Charge", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("outerCity_return_charge", "Return Charge", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("outerCity_cod", "Cash On Delivery (COD)", [
        { required: true, message: "This field is required!" },
      ])}

      {renderInput("outerCity_base_weight", "Base Weight(KG)", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("outerCity_max_weight", "Max Weight(KG)", [
        { required: true, message: "This field is required!" },
      ])}
      {renderInput("outerCity_ECPK", "EXtra Charge Per KG", [
        { required: true, message: "This field is required!" },
      ])}

      <Divider orientation="center">ODD Time</Divider>

      {renderSelect(
        "odd",
        "Status",
        [{ required: true }],
        "Select a option",
        is_odd,
        "none"
      )}
      
      {renderTime("start_time", "Start Time", [
        { required: true, message: "Please input your Time" },
      ])}
      {renderTime("end_time", "End Time", [
        { required: true, message: "Please input your Time" },
      ])}

      <br />

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Add Price"
      subTitle="Price Management"
    ></PageHeader>
  );

  return <Content>
    {Pageheader}
    {FORM}</Content>;
};

export default connect(null, {
  addPrice,
})(AddPricing);
