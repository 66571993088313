import React from 'react'
import {  Form, TimePicker } from 'antd';
import moment from 'moment'

const TimeBox = ({label,name, value, rules, ...rest}) => {

    
    return ( 
       


        <Form.Item
            label={label}
            name={name}
            rules={rules}
            
            >
            <TimePicker    style={{width: '100%'}} size="large" name={name} value={value} {...rest} format={'HH:mm a'} />
        </Form.Item>
     );
}
 
export default TimeBox;