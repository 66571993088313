import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Statistic, Row, Col } from "antd";
import {
  LikeOutlined,
 
} from "@ant-design/icons";
import history from "../history";
import MerchantStatistic from "../pages/MerChant_Management/MerChant_Statistic/MerchantStatistic";
import MerchantDashboard from "../pages/Dashboard/Merchants/Merchant";
import ParcelDashboard from "../pages/Parcel/Parcel_Dashboard/ParcelDashboard";
import ParcelDashboardS from "../pages/Dashboard/Parcel/ParcelDashboardS";
import Pricing from "../pages/Area_Pricing_Management/Pricing/Pricing";
import DriverStatistic from "../pages/Delivery_Management/Driver_Statistic/DriverStatistic";
import WarehouseStatistic from "../pages/Warehouse_Management/Warehouse_statistic/WarehouseStatistic";
import WarehouseDashboard from "../pages/Dashboard/WareHouse/WarehouseDashboard";
import ParcelStatisticS from "../pages/Dashboard/Parcel/ParcelStatisticS";
import MerchantB from "../pages/Dashboard/Merchants/MerchantB";

const Dashboard = () => {
  const [access, setAccess] = useState('');
  
  useEffect(() => {
    window.scrollTo(0, 0)
    if(!localStorage.getItem("token") ||
    localStorage.getItem("token") === null){
      localStorage.clear();
      history.replace("/login");
    }
    setAccess(JSON.parse(localStorage.getItem('access')))
    // if(localStorage.getItem('token')){
      
    // }
    // else{
    //   localStorage.clear();
    //   history.push('/login')
    //   setAccess(JSON.parse(localStorage.getItem('access')))
    // }
  }, []);
  // console.log(access)
  return (
    <Content>
      {access.order_management ?
      <>
       {/* <ParcelDashboard/> */}
       <ParcelDashboardS/>
       <br></br>
      </>
      
       :null
    }
    <Row>
    <Col xs={12}sm={12}md={12}lg ={12}>{access.merchant_management ?
      <>
      {/* <MerchantStatistic/> */}
      <MerchantDashboard/>
      
      </>
       :null
    }</Col>
      <Col xs={12} sm={12}md={12}lg ={12}>{access.order_management ?
      <>
       {/* <ParcelDashboard/> */}
      
       <ParcelStatisticS/>
      </>
      
       :null
    }</Col>

    </Row>
    <br>
    </br>
    {access.merchant_management ?
      <>
      {/* <MerchantStatistic/> */}
     
      <MerchantB/>
      </>
       :null
    }
      
     {access.warehouse_management ?
     <>
     {/* <WarehouseStatistic/> */}
     <WarehouseDashboard/>
     </>
       :null
    }
      
      {access.area_pricing_management ?
       <Pricing/>:null
    }
      {access.driver_management ?
       <DriverStatistic/>:null
    }
     
      {/* <ParcelDashboard/>
      <MerchantStatistic/> */}
      
    </Content>
  );
};

export default Dashboard;
