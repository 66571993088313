import {
  CheckCircleOutlined,
  EyeOutlined,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  PageHeader,
  Table,
  Tag,
  Card,
  Col,
  Row,
  Divider,
  Popconfirm,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getdriverStatistic } from "../../../actions/Driver";
import { Column, Line, Pie, getCanvasPattern } from "@ant-design/plots";

const DriverStatistic = ({ getdriverStatistic }) => {
  const [data, setData] = useState([]);
  const [lastmonthStatistic, setLastMonthStatistic] = useState([]);
  const [totalStatistic, setTotalStatistic] = useState([]);
  const [datatoptendriver, setDataTopTenDriver] = useState([]);
  const [datalastmonthtoptendriver, setDataLastMonthTopTenDriver] = useState(
    []
  );
  const [datalastmonthtoptendriverpick, setDataLastMonthTopTenDriverPick] =
    useState([]);
  const [datatoptendriverpick, setDataTopTenDriverPick] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0);

    const res = await getdriverStatistic();
    setData(res);
  }, []);

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "Driver Id",
      dataIndex: "user_id",
      key: "user_id",
      width: "10%",
      ...searchColumns("user_id"),
    },
    {
      title: "Driver Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...searchColumns("name"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "20%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Delivered",
      dataIndex: "delivered",
      key: "delivered",
      width: "15%",
      ...searchColumns("delivered"),
    },
  ];
  const columnspick = [
    {
      title: "Driver Id",
      dataIndex: "user_id",
      key: "user_id",
      width: "10%",
      ...searchColumns("user_id"),
    },
    {
      title: "Driver Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      ...searchColumns("name"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "20%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Picked",
      dataIndex: "delivered",
      key: "delivered",
      width: "15%",
      ...searchColumns("delivered"),
    },
  ];
  const createData = (user_id, name, mobile_number, delivered) => {
    return { user_id, name, mobile_number, delivered };
  };

  const rows = [
    data
      ? data?.thisTopTenDel?.map((datas) =>
          createData(
            datas?.driver.user_id,
            datas?.driver.name,
            datas?.driver.mobile_number,
            datas?.delivered
          )
        )
      : "",
  ];
  const rows1 = [
    data
      ? data?.topTenDel?.map((datas) =>
          createData(
            datas?.driver.user_id,
            datas?.driver.name,
            datas?.driver.mobile_number,
            datas?.delivered
          )
        )
      : "",
  ];
  const rows2 = [
    data
      ? data?.thisTopTenPick?.map((datas) =>
          createData(
            datas?.driver.user_id,
            datas?.driver.name,
            datas?.driver.mobile_number,
            datas?.picked
          )
        )
      : "",
  ];
  const rows3 = [
    data
      ? data?.topPick?.map((datas) =>
          createData(
            datas?.driver.user_id,
            datas?.driver.name,
            datas?.driver.mobile_number,
            datas?.picked
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Driver Statistic"
      subTitle="Delivery Management"
    ></PageHeader>
  );

  return (
    <Content>
      {Pageheader}
      <br></br>
      <Row gutter={16} >
      <Col xs={12} md={24} sm={24} lg={12}>
      <Divider>Top Ten driver (Delivered)</Divider>
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
        pagination={false}
      />
      </Col><br></br>
      <Col xs={12} md={24} sm={24} lg={12}>
      <Divider>Last Moth Top Ten driver (Delivered)</Divider>
      <Table
        columns={columns}
        dataSource={rows1[0]}
        scroll={{ x: 1000 }}
        sticky
        pagination={false}
      />
      </Col><br></br>
      </Row>
      <br></br>
      <Row gutter={16}>
      <Col xs={12} md={24} sm={24} lg={12}>
      <Divider>Top Ten driver (Picked)</Divider>
      <Table
        columns={columnspick}
        dataSource={rows2[0]}
        scroll={{ x: 1000 }}
        sticky
        pagination={false}
      />
      </Col><br></br>
      <Col xs={12} md={24} sm={24} lg={12}>
      <Divider>Last Moth Top Ten driver (Picked)</Divider>
      <Table
        columns={columnspick}
        dataSource={rows3[0]}
        scroll={{ x: 1000 }}
        sticky
        pagination={false}
      />
      </Col><br></br>
      </Row>
    </Content>
  );
};

export default connect(null, {
  getdriverStatistic,
})(DriverStatistic);
